import React, { useEffect, useState, useContext } from 'react'
import {
    Form,
    Input,
    Button,
    Card,
    Row,
    Col,
    Select,
    DatePicker,
    Checkbox,
    message,
    Image,
    Modal,
    Space,
    Tooltip
} from 'antd'
import {
    SaveOutlined,
} from '@ant-design/icons'
import { useForm } from 'antd/lib/form/Form'
import './index.less'
import { useHistory, useParams } from 'react-router-dom'
import { OUT_TRANSACTION_TYPES_IDS, SEARCH_QUERY_TOOLTIP } from '../../constants'
import arrow_back from '../../assets/arrow_back.svg'
import propTypes from 'prop-types'
import moment from 'moment'
import {
    STEPS
} from './steps'
import {
    getOutVehicleTypes,
    getInVehicleTypes,
    outUpdateVehicleDetails,
    inUpdateVehicleDetails,
    getVehicleDetails,
    saveOutSeller,
    getDistrictList,
    isAccountExist,
    create1MIDAccount,
    saveOutBuyer
} from '../../services/api'
import { links } from '../../config'
import {
    disabledYear,
    breakdownIc
} from '../../util'
import { SessionContext } from '../../App'
import InventoryListingPage from '../../components/Inventory'

function VehicleInformation(props) {

    const history = useHistory()
    const [vehicleTypes, setVehicleTypes] = useState([])
    const [vehicleLoading, setVehicleLoading] = useState(false)
    const params = useParams()
    const { company, branch, user } = useContext(SessionContext)
    const [vehicleInfoForm] = useForm()
    const [disableVehicleInfoField, setDisableVehicleInfoField] = useState(false)
    const [saveBuyerLoading, setSaveBuyerLoading] = useState(false)
    const [isOnsaveModalVisible, setIsOnsaveModalVisible] = useState(false)
	const [searchTerm, setSearchTerm] = useState("");
    const [saveSellerLoading, setSaveSellerLoading] = useState(false)

    useEffect(() => {

        setVehicleLoading(true)

        getOutVehicleTypes()
            .then(res => {
                setVehicleLoading(false)
                if (!Array.isArray(res)) throw res.message || `Unable to get vehicle types`
                setVehicleTypes(res)
            })
            .catch(err => err && message.error(err))

    }, [history.location.state])

    useEffect(() => {

        if (history.location.state?.subStep != STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO) {

            vehicleInfoForm.setFieldsValue({
                vehicleRegistrationNo: props.vehicle?.vehicleRegistrationNo
            })

            return
        }

        vehicleInfoForm.setFieldsValue({
            ...props.vehicle,
            vehicleType: getVehicleType(),
            manufactureYear: moment(props.vehicle?.manufactureYear || undefined)
        })

    }, [props.vehicle])

    // useEffect(() => {

    //     if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

    //         if (vehicleTypes.length > 0)
    //             vehicleInfoForm.setFieldsValue({
    //                 vehicleType: getVehicleType(),
    //             })
    //     }


    // }, [vehicleTypes])

    const getVehicleType = () => {

        let vehicleType

        if (props?.vehicle?.vehicleType) vehicleType = props?.vehicle?.vehicleType

        if (props?.vehicle?.vehicleType == 1) vehicleType = 3
        else if (props?.vehicle?.vehicleType == 2) vehicleType = 4

        return vehicleType

    }

    useEffect(() => {

        if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I && props.vehicle?.vehicleRegistrationNo) {

            // getVehicleDetails(branch.id ?? 0, company.id, props.vehicle?.vehicleRegistrationNo)
            getVehicleDetails("", company.id, props.vehicle?.vehicleRegistrationNo)
                .then(res => {

                    if (res.message == 'Success') setDisableVehicleInfoField(true)

                })

        }

    }, [vehicleTypes])

    const saveBuyer = async (vehicle) => {

        try {

            let seller = {}

            if (props.transactionType === OUT_TRANSACTION_TYPES_IDS.D2I || props.transactionType === OUT_TRANSACTION_TYPES_IDS.D2C) {

                seller = {
                    "address1": "",
                    "address2": "",
                    "address3": "",
                    "correspondentAddress": "",
                    "correspondentEmail": "",
                    "correspondentMobileNo": "",
                    "dateOfBirth": "",
                    "district": "",
                    "email": "",
                    "gender": "",
                    "identificationNo": "",
                    "name": "",
                    "phoneNo": "",
                    "postcode": "",
                    "repIc": "",
                    "repIc2": "",
                    "repName": "",
                    "state": ""
                }

            }

            const saveOutSellerRes = await saveOutSeller(
                branch.id,
                user.username,
                company.coRegNo,
                true,
                seller,
                props.transactionType,
                vehicle,
            )

            if (saveOutSellerRes.saveSellerStatus !== '00') throw saveOutSellerRes.saveSellerMSG ?? saveOutSellerRes.message

            const transferId = saveOutSellerRes.transferId

            if (!props?.buyer?.state) {
                message.error('State is missing.')
                return
            }

            let buyerDistrictList = await getDistrictList(props?.buyer?.state)

            if (!buyerDistrictList) {
                message.error('District list is empty.')
                return
            }

            if (!props?.buyer?.district) {
                message.error('District is missing.')
                return
            }

            const buyerDistrictCode = buyerDistrictList.find(district => (
                district?.districtName.toUpperCase() === props.buyer?.district.toUpperCase()
            ))?.districtCode;

            let buyerInfo = {
                address1: props.buyer?.address1,
                address2: props.buyer?.address2,
                address3: props.buyer?.address3,
                correspondentAddress1: props.buyer?.isAddressDifferent === true ? props.buyer?.correspondentAddress1 : props.buyer?.address1,
                correspondentAddress2: props.buyer?.isAddressDifferent === true ? props.buyer?.correspondentAddress2 : props.buyer?.address2,
                correspondentAddress3: props.buyer?.isAddressDifferent === true ? props.buyer?.correspondentAddress3 : props.buyer?.address3,
                correspondentDistrict: buyerDistrictCode,
                correspondentEmail: props.buyer?.correspondentEmail,
                correspondentMobileNo: props.buyer?.correspondentMobileNo,
                correspondentPostcode: props.buyer?.isAddressDifferent === true ? props.buyer?.correspondentPostcode : props.buyer?.postcode,
                correspondentState: props.buyer?.isAddressDifferent === true ? props.buyer?.correspondentState : props.buyer?.state,
                dateOfBirth: props.buyer?.dateOfBirth,
                city: props.buyer?.city,
                district: buyerDistrictCode,
                email: props.buyer?.email,
                gender: props.buyer?.gender,
                identificationNo: props.buyer?.identificationNo,
                name: props.buyer?.name,
                phoneNo: props.buyer?.phoneNo,
                mobileNo: props.buyer?.mobileNo,
                postcode: props.buyer?.postcode,
                state: props.buyer?.state,
                buyerType: props.buyer?.buyerType,
                citizenship: props?.buyer?.citizenship?.replace(/\0.*$/, "").toUpperCase(),
            }

            const isAccountExistRes = await isAccountExist(buyerInfo.identificationNo)

            if (isAccountExistRes.status === false) {

                const { formattedDob, isMale } = breakdownIc(buyerInfo.identificationNo)
                let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

                const create1MIDAccountRes = await create1MIDAccount(
                    buyerInfo.correspondentAddress1,
                    buyerInfo.correspondentAddress2,
                    buyerInfo.correspondentAddress3,
                    localFormattedDob,
                    '',
                    '',
                    buyerInfo.correspondentDistrict,
                    buyerInfo.correspondentEmail,
                    buyerInfo.correspondentEmail,
                    isMale ? "MALE" : "FEMALE",
                    buyerInfo.correspondentMobileNo,
                    buyerInfo.name,
                    buyerInfo.identificationNo,
                    false,
                    buyerInfo.correspondentMobileNo,
                    buyerInfo.correspondentPostcode,
                    '',
                    '',
                    buyerInfo.correspondentState
                )

                if (!create1MIDAccountRes.status) {

                    let msg = create1MIDAccountRes.message

                    if ('Sorry, an account already exists with email address - ' === create1MIDAccountRes.message) {
                        msg += buyerInfo.correspondentEmail
                    }

                    throw msg
                }

            }

            const saveOutBuyerRes = await saveOutBuyer(
                buyerInfo,
                user.username,
                company.coRegNo,
                // null,
                transferId,
                props.transactionType === OUT_TRANSACTION_TYPES_IDS.D2C ? buyerInfo?.identificationNo : null
            )

            if (saveOutBuyerRes.saveBuyerStatus !== '00') return saveOutBuyerRes

            message.success('Buyer information saved.')

            return saveOutBuyerRes

        } catch (error) {
            error && message.error(error?.toString())
        }
    }

    const onSavePressed = async () => {

        setSaveBuyerLoading(true)

        const vehicleInfo = {
            // 'carMake': vehicleInfoForm.getFieldValue('carMake').toUpperCase(),
            // 'carModel': vehicleInfoForm.getFieldValue('carModel').toUpperCase(),
            'chassisNo': vehicleInfoForm.getFieldValue('chassisNo').toUpperCase(),
            'engineNo': vehicleInfoForm.getFieldValue('engineNo').toUpperCase(),
            // 'manufactureYear': vehicleInfoForm.getFieldValue('manufactureYear')?.format("YYYY"),
            'vehicleRegistrationNo': vehicleInfoForm.getFieldValue('vehicleRegistrationNo').replaceAll(' ', '').toUpperCase(),
            // 'vehicleType': vehicleInfoForm.getFieldValue('vehicleType')
        }

        const vehicle = {
            ...props.vehicle,
            ...vehicleInfo
        }

        let response = await saveBuyer(vehicle)
        if(response.saveBuyerStatus !== '00') {
            message.error(response.saveBuyerMSG)
            setSaveSellerLoading(false)
            return
        }

        setSaveBuyerLoading(false)
        setIsOnsaveModalVisible(false);

        message.loading('Redirect to homepage...')

        setTimeout(() => {
            window.location.pathname = '/'
        }, 1 * 1000);
    }

    const COMPONENT_STEPS = {
        [STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO]: {
            component: (
                <div
                    style={{
                        // display: 'flex',
                        // justifyContent: 'flex-start',
                        // alignItems: 'flex-start',
                        // flexDirection: 'column'
                    }}
                >

                    <Modal className='in-save-seller-info-modal' visible={isOnsaveModalVisible} onCancel={() => { setIsOnsaveModalVisible(false) }} footer={null} width={330}>
                        <Space direction='vertical' style={{ textAlign: 'center', marginTop: 24 }}>
                            <h1 style={{ font: "normal normal 600 24px Raleway" }}>Save the current information?</h1>
                            <p style={{ font: "normal normal 400 18px Raleway" }}>The current information will be save and you will be redirect to homepage when it's completed.</p>
                            <Button
                                size='large'
                                style={{

                                    background: " #2B61C4 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 4px 0px #1E4489",
                                    borderRadius: "8px",
                                    color: 'white',
                                    borderColor: 'transparent'
                                }}
                                type='primary'
                                onClick={() => onSavePressed()}
                                loading={saveBuyerLoading}
                            >
                                Save and back to homepage
                            </Button>
                            <Button type='link' onClick={() => { setIsOnsaveModalVisible(false) }} style={{ font: 'normal normal 600 14px Open Sans', color: "#2B61C4", paddingTop: 16 }}>
                                CANCEL
                            </Button>
                        </Space>
                    </Modal>


                    <Form
                        form={vehicleInfoForm}
                        layout={'vertical'}
                        style={{
                            width: '100%',
                        }}
                        onFinish={async values => {
                            // values.manufactureYear = values.manufactureYear?.format("YYYY")
                            values.vehicleRegistrationNo = values.vehicleRegistrationNo.replaceAll(' ', '').toUpperCase()
                            // values.carMake = values.carMake.trim().toUpperCase()
                            // values.carModel = values.carModel.trim().toUpperCase()
                            values.chassisNo = values.chassisNo.trim().toUpperCase()
                            values.engineNo = values.engineNo.trim().toUpperCase()

                            let vehicle = {
                                ...props.vehicle,
                                ...values
                            }
                            setSaveSellerLoading(true)

                            props.onChange(vehicle)

                            if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

                                // getVehicleDetails(branch.id ?? 0, company.id, values.vehicleRegistrationNo)
                                    // .then(async res => {

                                        // if (res.message != 'Success') {
                                        //     setSaveSellerLoading(false)
                                        //     throw res.message
                                        // }

                                        // let transferId = await saveBuyer(vehicle)
                                        let response = await saveBuyer(vehicle)
                                        if(response.saveBuyerStatus !== '00') {
                                            message.error(response.saveBuyerMSG)
                                            setSaveSellerLoading(false)
                                            return
                                        }

                                        history.push(history.location.pathname, {
                                            ...history.location.state,
                                            step: STEPS.JPJ_SUBMISSION.label,
                                            subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW,
                                            // transferId : transferId
                                            transferId : response.transferId
                                        })

                                    // }).catch(err => {
                                    //     err && message.error(err)
                                    //     return
                                    // })
                                    // .finally(() => {
                                    //     setSaveSellerLoading(false)
                                    // })

                            } else {
                                setSaveSellerLoading(false)
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.JPJ_SUBMISSION.label,
                                    subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW
                                })
                            }

                        }}
                        initialValues={{
                            // ...props.vehicle,
                            manufactureYear: moment(props.vehicle?.manufactureYear || undefined)
                        }}
                    >

                        <div style={{
                            padding: '24px',
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "4px",
                            width: '100%',

                        }}>
                            <h2 style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 24 }}>Vehicle Information</h2>
                            <Row
                                gutter={16}
                                style={{ width: '100%', }}>
                                <Col
                                    className="gutter-row"
                                    span={8}
                                >
                                    <Form.Item
                                        label={<p style={styles.label}>Vehicle Registration Number</p>}
                                        name='vehicleRegistrationNo'
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^[a-zA-Z0-9]{2,20}$'),
                                                // pattern: new RegExp('^[a-zA-Z0-9\\-]{2,20}$'),
                                                message: (
                                                    <ol>
                                                        <li>{'Must be between 2 - 20 characters'}</li>
                                                        <li>{'Allow for (a-z), (A-Z), (0-9)'}</li>
                                                    </ol>
                                                ),
                                            },
                                        ]}
                                    >
                                        {
                                            props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I ?
                                            <Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
                                                <Input
                                                    className='textbox-form-style'
                                                    placeholder='Enter vehicle registration number'
                                                    style={{ width: '100%' }}
                                                    value={searchTerm}
                                                    onChange={(e) => {
                                                        setSearchTerm(e.target.value);
                                                        let vehicleRegNo = e.target.value.toUpperCase().replace(/\s/g, "")

                                                        props.onChange({
                                                            ...props.vehicle,
                                                            vehicleRegistrationNo: e.target.value.toUpperCase()
                                                        })

                                                        if (!e.target.value) return

                                                        // getVehicleDetails(branch.id ?? 0, company.id, vehicleRegNo)
                                                        getVehicleDetails("", company.id, vehicleRegNo)
                                                            .then(res => {

                                                                if (res.message == 'Success') {

                                                                    setDisableVehicleInfoField(true)

                                                                    props.onChange({
                                                                        ...props.vehicle,
                                                                        // carMake: res.inventory.make,
                                                                        // carModel: res.inventory.model,
                                                                        chassisNo: res.inventory.chassisNo,
                                                                        engineNo: res.inventory.engineNo,
                                                                        // manufactureYear: res.inventory.manufacturerYear,
                                                                        vehicleRegistrationNo: res.inventory.vehicleNo,
                                                                        // vehicleType: res.inventory.vehicleType,
                                                                    })

                                                                } else {

                                                                    setDisableVehicleInfoField(false)

                                                                    props.onChange({
                                                                        ...props.vehicle,
                                                                        // carMake: '',
                                                                        // carModel: '',
                                                                        chassisNo: '',
                                                                        engineNo: '',
                                                                        // manufactureYear: '',
                                                                        vehicleRegistrationNo: vehicleRegNo,
                                                                        // vehicleType: '',
                                                                    })
                                                                }
                                                            })
                                                    }}
                                                />
                                            </Tooltip>
                                                :
                                            <Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
                                                <Input
                                                    className='textbox-form-style'
                                                    placeholder='Enter vehicle registration number'
                                                    style={{ width: '100%' }}
                                                    onChange={(e) => {
                                                        setSearchTerm(e.target.value);

                                                        props.onChange({
                                                            ...props.vehicle,
                                                            vehicleRegistrationNo: e.target.value.toUpperCase().replace(/\s/g, "")
                                                        })

                                                    }}
                                                />
                                            </Tooltip>
                                        }

                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row gutter={16} style={{ width: '100%', }}>

                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Make</p>}
                                        name='carMake'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Make is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ width: '100%' }} className='textbox-form-style'
                                            placeholder='Enter vehicle make'
                                            disabled={disableVehicleInfoField}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Model</p>}
                                        name='carModel'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Model is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ width: '100%' }} className='textbox-form-style'
                                            placeholder='Enter vehicle model'
                                            disabled={disableVehicleInfoField}
                                        />
                                    </Form.Item>

                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Manufacture Year</p>}
                                        name='manufactureYear'
                                        rules={[
                                            {
                                                required: true,
                                                message: `Manufacture Year is required`
                                            },
                                        ]}
                                    >
                                        <DatePicker.YearPicker
                                            disabledDate={disabledYear}
                                            style={{ width: '100%', height: 40 }}
                                            className='textbox-form-style'
                                            placeholder='Select year'
                                            disabled={disableVehicleInfoField}
                                        />
                                    </Form.Item>

                                </Col>
                            </Row>
 */}

                            <Row gutter={16} style={{ width: '100%', }}>

                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Chassis Number</p>}
                                        name='chassisNo'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Chassis Number is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ width: '100%' }}
                                            className='textbox-form-style'
                                            placeholder='Enter chassis number'
                                            disabled={disableVehicleInfoField}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Engine Number</p>}
                                        name='engineNo'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Engine Number is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ width: '100%' }}
                                            placeholder='Enter engine number'
                                            className='textbox-form-style'
                                            disabled={disableVehicleInfoField}
                                        />
                                    </Form.Item>

                                </Col>
                                {/* <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Vehicle Type</p>}
                                        name='vehicleType'
                                        rules={[
                                            {
                                                required: true,
                                                message: `Vehicle Type is required`
                                            },
                                        ]}
                                    >
                                        <Select
                                            // disabled={vehicleInfoForm.getFieldValue('vehicleType') == undefined ? false : disableVehicleInfoField}
                                            disabled={disableVehicleInfoField}
                                            showSearch
                                            placeholder="Select a vehicle type" className='custom-select'
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: '100%', height: 40 }}
                                            loading={vehicleLoading}
                                        >
                                            {
                                                vehicleTypes.map(vt => {
                                                    return <Select.Option value={vt.id} key={vt.id}>
                                                        {vt.name}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                </Col> */}
                            </Row>
                        </div>

                        <br />
                        <div style={{
                            padding: '24px',
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "4px",
                            width: '100%',

                        }}>
                            <InventoryListingPage
                                isReport={false}
                                isM2mTransaction={false}
                                vehicleProps={props}
                                setVehicleSearchTerm={setSearchTerm}
                                setDisableVehicle={setDisableVehicleInfoField}
                            />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                marginBlock: 24
                            }}
                        >
                            <Form.Item
                                name='tnc'
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value
                                                ? Promise.resolve()
                                                : Promise.reject(),
                                        message: (
                                            <span>To proceed, you need to agree with the terms & conditions</span>
                                        )
                                    }
                                ]}
                            >
                                <Checkbox
                                    style={{
                                        margin: 10
                                    }}
                                >
                                    <p style={{ textAlign: 'left' }}>
                                        I hereby agree to transfer the ownership of this vehicle and shall take full responsibility on this transfer upon the agreed
                                        <a
                                            target='_blank'
                                            rel="noreferrer"
                                            href={links.termsAndCondition}
                                        >
                                            &nbsp; Terms & Conditions.
                                        </a>
                                    </p>
                                </Checkbox>
                            </Form.Item>
                        </div>

                        <Form.Item>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Button
                                    className='back-button buttons'
                                    shape='round'
                                    onClick={() => {

                                        props.onChange({
                                            ...props.vehicle,
                                            // carMake: '',
                                            // carModel: '',
                                            chassisNo: '',
                                            engineNo: '',
                                            // manufactureYear: '',
                                            vehicleRegistrationNo: '',
                                            // vehicleType: '',
                                        })

                                        switch (props.transactionType) {
                                            case OUT_TRANSACTION_TYPES_IDS.I2C:
                                                history.push(history.location.pathname, {
                                                    ...history.location.state,
                                                    step: STEPS.AUTHORIZE_SELLER.label,
                                                    subStep: STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY
                                                })

                                                break
                                            default:
                                                history.push(history.location.pathname, {
                                                    ...history.location.state,
                                                    step: STEPS.AUTHORIZE_BUYER.label,
                                                    subStep: STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY
                                                })
                                        }
                                    }}
                                >
                                    <Image src={arrow_back} preview={false} />
                                    Back
                                </Button>
                                <Space style={{ alignItems: 'self-start' }}>
                                    <Button
                                        className='back-button buttons'
                                        icon={<SaveOutlined />}
                                        shape='round'
                                        onClick={() => {
                                            setSearchTerm(props.vehicle.vehicleRegistrationNo)
                                            vehicleInfoForm
                                                .validateFields()
                                                .then(() => {
                                                    setIsOnsaveModalVisible(true);
                                                });
                                        }}
                                        loading={saveSellerLoading}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        id='1'
                                        size='large'
                                        style={{
                                            width: 200,
                                            background: " #2B61C4 0% 0% no-repeat padding-box",
                                            boxShadow: "0px 4px 0px #1E4489",
                                            borderRadius: "8px",
                                            color: 'white',
                                            borderColor: 'transparent'
                                        }}
                                        htmlType='submit'
                                        type='primary'
                                        onClick={() => {
                                            setSearchTerm(props.vehicle.vehicleRegistrationNo)
                                        }}
                                        loading={saveSellerLoading}
                                    >
                                        Continue
                                    </Button>
                                </Space>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            )
        },
        [STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO]: {
            component: (
                <div>


                    <Form
                        form={vehicleInfoForm}
                        layout={'vertical'}
                        style={{
                            width: '100%',
                        }}
                        onFinish={values => {

                            setVehicleLoading(true)

                            if (props.transactionType == OUT_TRANSACTION_TYPES_IDS.M2M) {

                                inUpdateVehicleDetails(
                                    //props?.vehicle?.carMake.trim().toUpperCase(),
                                    //props?.vehicle?.carModel.trim().toUpperCase(),
                                    values?.chassisNo.trim().toUpperCase(),
                                    company.coRegNo,
                                    values?.engineNo.trim().toUpperCase(),
                                    //props?.vehicle?.manufactureYear,
                                    params.transferId ?? history?.location?.state?.transferId,
                                    //props?.vehicle?.vehicleType
                                ).then(res => {

                                    if (res.updateStatus != '00') throw res.updateMSG

                                    message.success(res.updateMSG)

                                    if (history?.location?.state?.fromAdminPage == true) history.push('/adminSearchVehicleInfo')
                                    else
                                        history.push('/reporting/transferreport/Out')

                                }).catch(err => { err && message.error(err) }).finally(() => setVehicleLoading(false))

                            } else {

                                outUpdateVehicleDetails(
                                    //values.carMake.trim().toUpperCase(),
                                    //values.carModel.trim().toUpperCase(),
                                    values.chassisNo.trim().toUpperCase(),
                                    company.coRegNo,
                                    values.engineNo.trim().toUpperCase(),
                                    //values.manufactureYear?.format("YYYY"),
                                    // "",
                                    params.transferId ?? history?.location?.state?.transferId,
                                    //values.vehicleType
                                ).then(res => {

                                    if (res.updateStatus != '00') {

                                        if (res.updateMSG) throw res.updateMSG

                                        throw res.message
                                    }

                                    message.success(res.updateMSG)

                                    if (history?.location?.state?.fromAdminPage == true)
                                        history.push('/adminSearchVehicleInfo')
                                    // return
                                    else
                                        history.push('/reporting/transferreport/Out')

                                }).catch(err => { err && message.error(err) }).finally(() => setVehicleLoading(false))

                            }

                        }}
                        initialValues={{
                            ...props?.vehicle,
                            manufactureYear: moment(props.vehicle?.manufactureYear || undefined)
                        }}
                    >

                        <div style={{
                            padding: '24px',
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "4px",
                            width: '100%',

                        }}>
                            <h2 style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 24 }}>Vehicle Information</h2>
                            <Row gutter={16} style={{ width: '100%', }}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Vehicle Registration Number</p>}
                                        name='vehicleRegistrationNo'
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^[a-zA-Z0-9]{2,20}$'),
                                                message: (
                                                    <ol>
                                                        <li>{'Must be between 2 - 20 characters'}</li>
                                                        <li>{'Allow for (a-z), (A-Z), (0-9)'}</li>
                                                    </ol>
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled
                                            className='textbox-form-style'
                                            placeholder='Enter vehicle registration number'
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                props.onChange({
                                                    vehicleRegistrationNo: e.target.value.toUpperCase()
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row gutter={16} style={{ width: '100%', }}>

                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Make</p>}
                                        name='carMake'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Make is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={!props?.vehicle?.carMake || history?.location?.state?.fromAdminPage == true ? false : true}
                                            style={{ width: '100%' }} className='textbox-form-style'
                                            placeholder='Enter vehicle make'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Model</p>}
                                        name='carModel'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Model is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={!props?.vehicle?.carModel || history?.location?.state?.fromAdminPage == true ? false : true}
                                            style={{ width: '100%' }} className='textbox-form-style'
                                            placeholder='Enter vehicle model'
                                        />
                                    </Form.Item>

                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Manufacture Year</p>}
                                        name='manufactureYear'
                                        rules={[
                                            {
                                                required: true,
                                                message: `Manufacture Year is required`
                                            },
                                        ]}
                                    >
                                        <DatePicker.YearPicker
                                            disabled={!props?.vehicle?.manufactureYear || history?.location?.state?.fromAdminPage == true ? false : true}
                                            disabledDate={disabledYear}
                                            style={{ width: '100%', height: 40 }}
                                            className='textbox-form-style'
                                            placeholder='Select year'
                                        />
                                    </Form.Item>

                                </Col>
                            </Row> */}


                            <Row gutter={16} style={{ width: '100%', }}>

                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Chassis Number</p>}
                                        name='chassisNo'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Chassis Number is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ width: '100%' }} className='textbox-form-style'
                                            placeholder='Enter chassis number'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Engine Number</p>}
                                        name='engineNo'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: `Engine Number is required`
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ width: '100%' }}
                                            placeholder='Enter engine number' className='textbox-form-style'
                                        />
                                    </Form.Item>

                                </Col>
                                {/* <Col className="gutter-row" span={8}>

                                    <Form.Item
                                        label={<p style={styles.label}>Vehicle Type</p>}
                                        name='vehicleType'
                                        rules={[
                                            {
                                                required: true,
                                                message: `Vehicle Type is required`
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={history?.location?.state?.fromAdminPage == true ? false : true}
                                            showSearch
                                            placeholder="Select a vehicle type" className='custom-select'
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: '100%', height: 40 }}
                                            loading={vehicleLoading}
                                        >
                                            {
                                                vehicleTypes.map(vt => {
                                                    return <Select.Option value={vt.id} key={vt.id}>
                                                        {vt.name}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                </Col> */}
                            </Row>
                        </div>

                        <Form.Item>

                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBlock: 24
                                }}
                            >
                                <Button
                                    className='back-button buttons'
                                    shape='round'
                                    onClick={() => {

                                        if (history?.location?.state?.fromAdminPage == true) history.push('/adminSearchVehicleInfo')
                                        else
                                            history.push('/reporting/transferreport/Out')
                                    }}
                                >
                                    <Image src={arrow_back} preview={false} />
                                    Back
                                </Button>
                                <Button
                                    id='1'
                                    size='large'
                                    style={{
                                        width: 200,
                                        background: " #2B61C4 0% 0% no-repeat padding-box",
                                        boxShadow: "0px 4px 0px #1E4489",
                                        borderRadius: "8px",
                                        color: 'white',
                                        borderColor: 'transparent'
                                    }}
                                    htmlType='submit'
                                    type='primary'
                                >
                                    Update
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            )
        }
    }

    const [currentSubStep, setCurrentSubStep] = useState(STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO)
    const currentStep = COMPONENT_STEPS[currentSubStep]

    useEffect(() => {
        // if (!history.location.state) return null
        // if (!history.location.state.subStep) return null
        setCurrentSubStep(history.location.state?.subStep)
    }, [history.location.state])

    return (

        <Card
            style={{
                height: '50%',
                width: '100%',
                background: 'transparent',
                border: 0
            }}
        >
            {
                currentStep && currentStep.component
            }
            {
                !params.transferId || !history?.location?.state?.transferId
                && !props.vehicle.vehicleRegistrationNo
                && currentSubStep != STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO
                && currentSubStep != STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO
                && currentSubStep &&
                <Card
                    style={{
                        height: '50%',
                        width: '100%',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <h1>Unknown transfer</h1>
                    <a
                        onClick={() => history.push('/reporting/transferreport/Out')}
                    >Go back pending list</a>
                </Card>
            }
            <>
            </>
        </Card>
    )
}

VehicleInformation.propTypes = {
    vehicle: propTypes.object,
    dealer: propTypes.object,
    onChange: propTypes.func,
    transactionType: propTypes.number,
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0, fontFamily: 'Open Sans', fontSize: '16px'
    },
    value: {
        fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '16px'
    },
    ownershipTypeCard: {
        cursor: 'pointer',
        margin: 5,
    },
    ownershipTypeCardDisabled: {
        opacity: 0.5,
        margin: 5
    },
    ownershipTypeCardSelected: {
        boxShadow: `0 0 20px rgba(9, 8, 8, 0.393)`,
        transition: `box-shadow .1s`,

    }
}

export default VehicleInformation