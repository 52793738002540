import {
    LoadingOutlined, CloseOutlined
} from '@ant-design/icons'
import {
    Button,
    Card,
    Col,
    message,
    Modal,
    Row,
    Space,
    Image,
    Skeleton
} from 'antd'
import propTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { SessionContext } from '../../App'
import {
    STATES,
    TRANSACTION_OUT_RESPONSE_CODES,
    TRANSACTION_RESPONSE_CODES,
    APP_CONST,
    OUT_TRANSACTION_TYPES_IDS,
    OUT_TRANSACTION_STATUSES
} from '../../constants'
import {
    getOutTransferByTransferId,
    i2iSaveOutSellerBuyerInquiry,
    saveOutBuyer,
    saveOutSeller,
    verifyApproverOTP,
    verifyApproverSmsOtp,
    isAccountExist,
    create1MIDAccount,
    getDistrictList
} from '../../services/api'
import {
    breakdownIc,
    getDistrictName,
    getIsMobileLayout,
    parseAddress,
    parseCorrespondenceAddress,
    getDecryptedVerifyOtpResponse
} from '../../util'
import './index.less'
import JPJSubmissionModal from './jpjSubmission_modal'
import failedIcon from "../../assets/icon-failed.svg";
import successIcon from "../../assets/icon-success.svg";
import {
    STEPS
} from './steps'
import moment from 'moment'
import {
    getCompanyId,
    getUserRole,
} from '../../services/local'
import {
    processErrorMessage,
    validateRolePermission,
} from '../../components/Utils'

function JPJSubmission(props) {

    const { isReport } = props

    const history = useHistory()
    const params = useParams()
    const { user, company, branch } = useContext(SessionContext)
    const { state: locationState } = useLocation()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [transferId, setTransferId] = useState(null)
    const [transactionStatus, setTransactionStatus] = useState(null)
    const [currentSubStep, setCurrentSubStep] = useState(STEPS.JPJ_SUBMISSION.subSteps.REVIEW)
    const [isLoading, setIsLoading] = useState(false)
    const [statusMSG, setStatusMSG] = useState('')
    const [isModalVerificationVisible, setIsModalVerificationVisible] = useState(false);
    const { formattedDob: sellerFormattedDob, isMale: isSellerMale } = breakdownIc(props.seller.identificationNo)
    const { formattedDob: buyerFormattedDob, isMale: isBuyerMale } = breakdownIc(props.buyer.identificationNo)
    const isMobileLayout = getIsMobileLayout()

    const [sellerDistricts, setSellerDistricts] = useState([])
    const [sellerCorrespondenceDistricts, setSellerCorrespondenceDistricts] = useState([])
    const [buyerDistricts, setBuyerDistricts] = useState([])
    const [buyerCorrespondenceDistricts, setBuyerCorrespondenceDistricts] = useState([])

    const [sellerDistrictName, setSellerDistrictName] = useState('')
    const [sellerCorrespondenceDistrictName, setSellerCorrespondenceDistrictName] = useState('')
    const [buyerDistrictName, setBuyerDistrictName] = useState('')
    const [buyerCorrespondenceDistrictName, setBuyerCorrespondenceDistrictName] = useState('')
    const [loggedInUserRole, setLoggedInUserRole] = useState('')

    useEffect(() => {
        getUserRole()
            .then(res => {
                setLoggedInUserRole(res)
            })
    }, [])

    useEffect(() => {
        if (!history.location.state) return null
        if (!history.location.state.subStep) return null
        setCurrentSubStep(history.location.state.subStep)
    }, [history.location.state])

    useEffect(async () => {
        /**
         * 7/3/2022 daniel.kwok
         * Checks if this is a previously saved transfer.
         * If yes, transferId would be in history state.
         * Only need to call saveOutSellerBuyerInquiry if that's the case
         */
        const _transferId = Number(params.transferId)
        if (_transferId) {
            setIsLoading(true)
            setTransferId(_transferId)

            const res = await getOutTransferByTransferId(_transferId, "I2I", getCompanyId())
            if(res.status === 4) {
                message.warning("Redirect to printslip")
                history.push(`/transfer/i2i/printslip/${_transferId}`)
            }
            setIsLoading(false)
            setTransactionStatus(res.status)
            const state = {
                step: STEPS.JPJ_SUBMISSION.label,
                subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW,
                buyer: res.buyerInfo,
                seller: res.sellerInfo,
                vehicle: {
                    vehicleRegistrationNo: res.carRegistrationNo,
                    chassisNo: res.chassisNo,
                    engineNo: res.engineNo,
                    vehicleTypeId: res.vehicleTypeId,
                    // carMake: res.make,
                    // carModel: res.model,
                    // manufactureYear: res.manufactureYear,
                },
            }

            history.push(history.location.pathname, {
                ...history.location.state,
                ...state,
            })
        }
    }, [])

    useEffect(() => {

        if (transferId) {

            getOutTransferByTransferId(transferId, "I2I", getCompanyId())
                .then(res => {
                    setTransactionStatus(res.status)
                })
                .catch(err => {
                    message.error(err)
                })
                .finally()
        }

    }, [transferId])

    useEffect(() => {

        if (params.transferId) {

            if (props.buyer?.state && props.buyer?.district) {

                if (isNaN(parseInt(props.buyer?.district))) {

                    setBuyerDistrictName(props.buyer?.district)

                } else {

                    getDistrictName(
                        props.buyer.state,
                        props.buyer.district
                    ).then((res) => {
                        setBuyerDistrictName(res)
                    })

                }

            }

            if (props.buyer?.correspondentState && props.buyer?.correspondentDistrict) {

                if (isNaN(parseInt(props.buyer?.correspondentDistrict))) {

                    setBuyerCorrespondenceDistrictName(props.buyer?.correspondentDistrict)

                } else {

                    getDistrictName(
                        props.buyer.correspondentState,
                        props.buyer.correspondentDistrict
                    ).then((res) => {
                        setBuyerCorrespondenceDistrictName(res)
                    })

                }

            }

            if (props.seller?.state && props.seller?.district) {

                if (isNaN(parseInt(props.seller?.district))) {

                    setSellerDistrictName(props.seller?.district)

                } else {

                    getDistrictName(
                        props.seller?.state,
                        props.seller?.district
                    ).then((res) => {
                        setSellerDistrictName(res)
                    })

                }

            }

            if (props.seller?.correspondentState && props.seller?.correspondentDistrict) {

                if (isNaN(parseInt(props.seller?.correspondentDistrict))) {

                    setSellerCorrespondenceDistrictName(props.seller?.correspondentDistrict)

                } else {

                    getDistrictName(
                        props.seller?.correspondentState,
                        props.seller?.correspondentDistrict
                    ).then((res) => {
                        setSellerCorrespondenceDistrictName(res)
                    })

                }

            }

        } else {

            if (props.buyer?.district) {
                setBuyerDistrictName(props.buyer?.district)
            }

            if (props.buyer?.correspondentDistrict) {
                setBuyerCorrespondenceDistrictName(props.buyer?.correspondentDistrict)
            }

            if (props.seller?.district) {
                setSellerDistrictName(props.seller?.district)
            }

            if (props.seller?.correspondentDistrict) {
                setSellerCorrespondenceDistrictName(props.seller?.correspondentDistrict)
            }
        }

    }, [params.transferId, props])

    useEffect(() => {

        if (props?.seller?.state) {
            setIsLoading(true)
            getDistrictList(props?.seller?.state)
                .then(res => {
                    setSellerDistricts(res)
                }).finally(() => setIsLoading(false))
        }

        if (props?.seller?.correspondentState) {
            setIsLoading(true)
            getDistrictList(props?.seller?.correspondentState)
                .then(res => {
                    setSellerCorrespondenceDistricts(res)
                }).finally(() => setIsLoading(false))
        }

        if (props?.buyer?.state) {
            setIsLoading(true)
            getDistrictList(props?.buyer?.state)
                .then(res => {
                    setBuyerDistricts(res)
                }).finally(() => setIsLoading(false))
        }

        if (props?.buyer?.correspondentState) {
            setIsLoading(true)
            getDistrictList(props?.buyer?.correspondentState)
                .then(res => {
                    setBuyerCorrespondenceDistricts(res)
                }).finally(() => setIsLoading(false))
        }

    }, [props?.seller?.state, props?.seller?.correspondentState, props?.buyer?.state, props?.buyer?.correspondentState])

    const COMPONENT_STEPS = {
        [STEPS.JPJ_SUBMISSION.subSteps.REVIEW]: {
            component: (
                <div style={{ width: '100%', }}>
                    <Modal className='i2i-buyer-info-modal' visible={isModalVerificationVisible} onCancel={() => { setIsModalVerificationVisible(false) }} footer={null} width={330}>
                        <Space direction='vertical' style={{ textAlign: 'center', marginTop: 24 }}>
                            <h1 style={{ font: "normal normal 600 24px Raleway" }}>Submit for JPJ Verification?</h1>
                            <Button
                                style={{
                                    background: " #2B61C4 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 4px 0px #1E4489",
                                    borderRadius: "8px",
                                    color: 'white',
                                    borderColor: 'transparent',
                                    width: "50%"
                                }}
                                type='primary'
                                onClick={() => {
                                    setIsModalVerificationVisible(false)
                                    onJPJSubmit()
                                }}
                            >
                                Submit
                            </Button>
                            <Button type='link' onClick={() => { setIsModalVerificationVisible(false) }} style={{ font: 'normal normal 600 14px Open Sans', color: "#2B61C4", paddingTop: 16 }}>
                                CANCEL
                            </Button>
                        </Space>
                    </Modal>
                    <div style={{ width: '100%' }}>
                        <Col span={24}>
                            <Card
                                style={{
                                    textAlign: 'left',
                                    marginBottom: 10,
                                    boxShadow: '0px 3px 6px #00000029',
                                    borderRadius: '4px'
                                }}
                            >
                                <h2>Vehicle Information</h2>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Vehicle Number</p>
                                        <p style={styles.value}>{props.vehicle?.vehicleRegistrationNo}</p>
                                    </Col>
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Make</p>
                                        <p style={styles.value}>{props.vehicle?.carMake}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Model</p>
                                        <p style={styles.value}>{props.vehicle?.carModel}</p>
                                    </Col> */}
                                {/* </Row>
                                <Row gutter={16}> */}
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Manufacture Year</p>
                                        <p style={styles.value}>{props.vehicle?.manufactureYear}</p>
                                    </Col> */}
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Engine Number</p>
                                        <p style={styles.value}>{props.vehicle?.engineNo}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Chassis Number</p>
                                        <p style={styles.value}>{props.vehicle?.chassisNo}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 10,
                        }}
                    >
                        {
                            isLoading ? (
                                <Skeleton active />
                            ) : (
                                props.transactionType === 1 || props.transactionType === 4 ?
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Owner's Details</h2>
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.seller.name}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.seller?.identificationNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{sellerFormattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isSellerMale ? 'Male' : 'Female'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller?.state)?.label, districtName: sellerDistrictName })}</p>
                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>
                                            {
                                                props.seller.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.seller, correspondentState: Object.values(STATES).find(s => s.code === props.seller.correspondentState)?.label, districtName: sellerCorrespondenceDistrictName })
                                                    : parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label, districtName: sellerDistrictName })
                                            }
                                        </p> */}
                                        <p style={styles.label}>Email</p>
                                        <p style={styles.value}>{props.seller?.correspondentEmail}</p>
                                        <p style={styles.label}>Mobile Number</p>
                                        <p style={styles.value}>{props.seller?.correspondentMobileNo}</p>
                                    </Card>
                                    :
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Seller's Company Details</h2>
                                        {/* <p style={styles.label}>Company Name</p>
                                    <p style={styles.value}>{company.coName}</p>
                                    <p style={styles.label}>Company ROC</p>
                                    <p style={styles.value}>{company.coRegNo}</p>
                                    <p style={styles.label}>Company Address</p>
                                    <p style={styles.value}>{parseCompanyAddress({ ...company.companyAddress })}</p>
                                    <p style={styles.label}>Representative Name</p>
                                    <p style={styles.value}>{user.name}</p>
                                    <p style={styles.label}>Representative NRIC</p>
                                    <p style={styles.value}>{user.nric}</p>
                                    <p style={styles.label}>Representative Email</p>
                                    <p style={styles.value}>{user.email}</p>
                                    <p style={styles.label}>Representative Mobile Number</p>
                                    <p style={styles.value}>{user.mobileno}</p> */}
                                        <p style={styles.label}>Company Name</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Company ROC</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Company Address</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative Name</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative NRIC</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative Email</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative Mobile Number</p>
                                        <p style={styles.value}>-</p>
                                    </Card>
                            )

                        }

                        {
                            isLoading ? (
                                <Skeleton active />
                            ) : (
                                props.transactionType === 1 || props.transactionType === 2 ?
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Buyer's Details</h2>
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.buyer?.name}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.buyer?.identificationNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{buyerFormattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isBuyerMale ? 'Male' : 'Female'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer?.state)?.label, districtName: buyerDistrictName })}</p>
                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>
                                            {
                                                props.buyer.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.buyer, correspondentState: Object.values(STATES).find(s => s.code === props.buyer.correspondentState)?.label, districtName: buyerCorrespondenceDistrictName })
                                                    : parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer.state)?.label, districtName: buyerDistrictName })
                                            }
                                        </p> */}
                                        <p style={styles.label}>Email</p>
                                        <p style={styles.value}>{props.buyer?.correspondentEmail}</p>
                                        <p style={styles.label}>Mobile Number</p>
                                        <p style={styles.value}>{props.buyer?.correspondentMobileNo}</p>
                                    </Card>

                                    :
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Buyer's Company Details</h2>
                                        <p style={styles.label}>Company Name</p>
                                        <p style={styles.value}>{props.buyer?.companyDetails?.coName ?? props.buyer?.name}</p>
                                        <p style={styles.label}>Company ROC</p>
                                        <p style={styles.value}>{props.buyer?.companyDetails?.coRegNo ?? props.buyer?.identificationNo}</p>
                                        <p style={styles.label}>Company Address</p>
                                        <p style={styles.value}>
                                            {props.buyer?.correspondentAddress1
                                                ? parseCorrespondenceAddress({ ...props.buyer, correspondentState: Object.values(STATES).find(s => s.code === props.buyer?.correspondentState)?.label, districtName: buyerCorrespondenceDistrictName })
                                                : parseAddress({ ...props?.buyer?.companyDetails?.companyAddress, state: Object.values(STATES).find(s => s.code === props.buyer?.companyDetails?.companyAddress?.stateCode)?.label, districtName: props?.buyer?.companyDetails?.companyAddress?.district ? props?.buyer?.companyDetails?.companyAddress?.district : props?.buyer?.companyDetails?.companyAddress.city })}
                                            {/* : parseAddress({ ...props?.buyer.companyDetails.companyAddress, state: Object.values(STATES).find(s => s.code === props.buyer?.companyDetails.companyAddress.stateCode)?.label, districtName: props?.buyer?.companyDetails?.companyAddress.district ? props?.buyer?.companyDetails?.companyAddress.district : props?.buyer?.companyDetails?.companyAddress.city })} */}
                                        </p>
                                        <p style={styles.label}>Representative Name</p>
                                        <p style={styles.value}>{props.buyer?.repName ?? props.buyer?.name}</p>
                                        <p style={styles.label}>Representative NRIC</p>
                                        <p style={styles.value}>{props.buyer?.repIc ?? props.buyer?.identificationNo}</p>
                                        <p style={styles.label}>Representative Email</p>
                                        <p style={styles.value}>{props.buyer?.correspondentEmail}</p>
                                        <p style={styles.label}>Representative Mobile Number</p>
                                        <p style={styles.value}>{props.buyer?.correspondentMobileNo}</p>
                                    </Card>
                            )

                        }
                    </div>
                    <div
                        style={{ marginTop: 20 }}
                    >
                        {
                            isMobileLayout ? (
                                <div>
                                    {
                                        isReport == true
                                            ?
                                            <></>
                                            :
                                            transactionStatus == OUT_TRANSACTION_STATUSES.SUCCESS ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION
                                                ?
                                                <></>
                                                :
                                                <Button
                                                    size='large'
                                                    style={{
                                                        width: 200,
                                                        background: " #2B61C4 0% 0% no-repeat padding-box",
                                                        boxShadow: "0px 4px 0px #1E4489",
                                                        borderRadius: "8px",
                                                        borderColor: 'transparent'
                                                    }}
                                                    onClick={() => {
                                                        setIsModalVerificationVisible(true)
                                                    }}
                                                    type='primary'
                                                >
                                                    JPJ Submission
                                                </Button>
                                    }
                                    <Button
                                        size='large'
                                        style={{ background: 'transparent' }}
                                        danger
                                        shape='round'
                                        icon={<CloseOutlined />}
                                        onClick={() => {
                                            window.location.pathname = '/'
                                        }}
                                    >
                                        CANCEL
                                    </Button>
                                </div>
                            ) : (
                                <Row
                                    style={{
                                        width: '100%', justifyContent: 'space-between'
                                    }}
                                >
                                    <Col>
                                        <Button
                                            className='back-button buttons'
                                            shape='round'
                                            onClick={() => {
                                                if (locationState?.oldIsReport) {
                                                    if (locationState?.fromPendingPage === true) {
                                                        history.push('/reporting/transferreport/i2i')
                                                    } else {
                                                        history.push('/reporting/transferreport')
                                                    }
                                                } else {
                                                    if (history.location.state.transferId) {
                                                        history.push('/')
                                                    } else if (params.transferId == null) {
                                                        history.push(history.location.pathname, {
                                                            ...locationState,
                                                            step: STEPS.AUTHORIZE_BUYER.label,
                                                            subStep: STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY,
                                                        })
                                                    } else {
                                                        history.push('/')
                                                    }
                                                }
                                            }}
                                        >
                                            {history.location.state.transferId ? 'Home' : 'Back'}
                                        </Button>
                                    </Col>
                                    <Col>
                                        {
                                            isReport == true
                                                ?
                                                <></>
                                                :
                                                transactionStatus == OUT_TRANSACTION_STATUSES.SUCCESS ||
                                                    transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL ||
                                                    transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP ||
                                                    transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP ||
                                                    transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP ||
                                                    transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION
                                                    ?
                                                    <></>
                                                    :
                                                    <Button
                                                        size='large'
                                                        style={{
                                                            width: 200,
                                                            background: " #2B61C4 0% 0% no-repeat padding-box",
                                                            boxShadow: "0px 4px 0px #1E4489",
                                                            borderRadius: "8px",
                                                            borderColor: 'transparent'
                                                        }}
                                                        onClick={() => {
                                                            setIsModalVerificationVisible(true)
                                                        }}
                                                        type='primary'
                                                    >
                                                        JPJ Submission
                                                    </Button>
                                        }
                                    </Col>
                                </Row>
                            )
                        }
                    </div>
                </div>
            )
        },
        [STEPS.JPJ_SUBMISSION.subSteps.JPJ_CHECKING]: {
            component: (

                <Card
                    style={{
                        height: '50%',
                        width: '100%',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '4px'
                    }}
                >
                    <div>
                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Checking in Progress
                        </h1>
                        <p>Retrieve data</p>
                        <LoadingOutlined />
                    </div>
                </Card>
            )
        },
        [STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED]: {
            component: (
                <div style={{ width: '100%', }}>
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={failedIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <Space direction='vertical' size={0}>
                                <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ verification failed</h2>
                                <p style={{ font: 'normal normal normal 16px Open Sans', margin: 0 }} dangerouslySetInnerHTML={{ __html: statusMSG }}></p>
                            </Space>
                        </div>
                    </Card>
                    <Modal className='i2i-buyer-info-modal' visible={isModalVerificationVisible} onCancel={() => { setIsModalVerificationVisible(false) }} footer={null} width={330}>
                        <Space direction='vertical' style={{ textAlign: 'center', marginTop: 24 }}>
                            <h1 style={{ font: "normal normal 600 24px Raleway" }}>Submit for JPJ Verification?</h1>
                            <Button

                                style={{

                                    background: " #2B61C4 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 4px 0px #1E4489",
                                    borderRadius: "8px",
                                    color: 'white',
                                    borderColor: 'transparent',
                                    width: "50%"
                                }}
                                type='primary'
                                onClick={() => {
                                    onJPJSubmit()
                                }}
                            >
                                Submit
                            </Button>
                            <Button type='link' onClick={() => { setIsModalVerificationVisible(false) }} style={{ font: 'normal normal 600 14px Open Sans', color: "#2B61C4", paddingTop: 16 }}>
                                CANCEL
                            </Button>
                        </Space>
                    </Modal>
                    <div style={{ width: '100%' }}>
                        <Col span={24}>
                            <Card
                                style={{
                                    textAlign: 'left',
                                    marginBottom: 10,
                                    boxShadow: '0px 3px 6px #00000029',
                                    borderRadius: '4px'
                                }}
                            >
                                <h2>Vehicle Information</h2>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Vehicle Number</p>
                                        <p style={styles.value}>{props.vehicle?.vehicleRegistrationNo}</p>
                                    </Col>
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Make</p>
                                        <p style={styles.value}>{props.vehicle?.carMake}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Model</p>
                                        <p style={styles.value}>{props.vehicle?.carModel}</p>
                                    </Col> */}
                                {/* </Row>
                                <Row gutter={16}> */}
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Manufacture Year</p>
                                        <p style={styles.value}>{props.vehicle?.manufactureYear}</p>
                                    </Col> */}
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Engine Number</p>
                                        <p style={styles.value}>{props.vehicle?.engineNo}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Chassis Number</p>
                                        <p style={styles.value}>{props.vehicle?.chassisNo}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 10,
                        }}
                    >
                        {
                            isLoading ? (
                                <Skeleton active />
                            ) : (
                                props.transactionType === 1 || props.transactionType === 4 ?
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Owner's Details</h2>
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.seller?.name}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.seller?.identificationNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{sellerFormattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isSellerMale ? 'Male' : 'Female'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller?.state)?.label, districtName: sellerDistrictName })}</p>
                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>
                                            {
                                                props.seller.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.seller, correspondentState: Object.values(STATES).find(s => s.code === props.seller.correspondentState)?.label, districtName: sellerCorrespondenceDistrictName })
                                                    : parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label, districtName: sellerDistrictName })
                                            }
                                        </p> */}
                                        <p style={styles.label}>Email</p>
                                        <p style={styles.value}>{props.seller?.correspondentEmail}</p>
                                        <p style={styles.label}>Mobile Number</p>
                                        <p style={styles.value}>{props.seller?.correspondentMobileNo}</p>
                                    </Card>
                                    :
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Seller's Company Details</h2>
                                        <p style={styles.label}>Company Name</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Company ROC</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Company Address</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative Name</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative NRIC</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative Email</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative Mobile Number</p>
                                        <p style={styles.value}>-</p>
                                    </Card>
                            )

                        }

                        {
                            isLoading ? (
                                <Skeleton active />
                            ) : (
                                props.transactionType === 1 || props.transactionType === 2 ?
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Buyer's Details</h2>
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.buyer?.name}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.buyer?.identificationNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{buyerFormattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isBuyerMale ? 'Male' : 'Female'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer?.state)?.label, districtName: buyerDistrictName })}</p>
                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>
                                            {
                                                props.buyer.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.buyer, correspondentState: Object.values(STATES).find(s => s.code === props.buyer.correspondentState)?.label, districtName: buyerCorrespondenceDistrictName })
                                                    : parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer.state)?.label, districtName: buyerDistrictName })
                                            }
                                        </p> */}
                                        <p style={styles.label}>Email</p>
                                        <p style={styles.value}>{props.buyer?.correspondentEmail}</p>
                                        <p style={styles.label}>Mobile Number</p>
                                        <p style={styles.value}>{props.buyer?.correspondentMobileNo}</p>
                                    </Card>

                                    :
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Buyer's Company Details</h2>
                                        <p style={styles.label}>Company Name</p>
                                        <p style={styles.value}>{props.buyer?.companyDetails?.coName ?? props.buyer?.name}</p>
                                        <p style={styles.label}>Company ROC</p>
                                        <p style={styles.value}>{props.buyer?.companyDetails?.coRegNo ?? props.buyer?.identificationNo}</p>
                                        <p style={styles.label}>Company Address</p>
                                        <p style={styles.value}>
                                            {props.buyer?.correspondentAddress1
                                                ? parseCorrespondenceAddress({ ...props.buyer, correspondentState: Object.values(STATES).find(s => s.code === props.buyer?.correspondentState)?.label, districtName: buyerCorrespondenceDistrictName })
                                                : parseAddress({ ...props?.buyer?.companyDetails?.companyAddress, state: Object.values(STATES).find(s => s.code === props.buyer?.companyDetails?.companyAddress.stateCode)?.label, districtName: props?.buyer?.companyDetails?.companyAddress.district ? props?.buyer?.companyDetails?.companyAddress.district : props?.buyer?.companyDetails?.companyAddress.city })}
                                            {/* // : parseAddress({ ...props?.buyer.companyDetails.companyAddress, state: Object.values(STATES).find(s => s.code === props.buyer?.companyDetails.companyAddress.stateCode)?.label, districtName: props?.buyer?.companyDetails?.companyAddress.district ? props?.buyer?.companyDetails?.companyAddress.district : props?.buyer?.companyDetails?.companyAddress.city })} */}
                                        </p>
                                        <p style={styles.label}>Representative Name</p>
                                        <p style={styles.value}>{props.buyer?.repName ?? props.buyer?.name}</p>
                                        <p style={styles.label}>Representative NRIC</p>
                                        <p style={styles.value}>{props.buyer?.repIc ?? props.buyer?.identificationNo}</p>
                                        <p style={styles.label}>Representative Email</p>
                                        <p style={styles.value}>{props.buyer?.correspondentEmail}</p>
                                        <p style={styles.label}>Representative Mobile Number</p>
                                        <p style={styles.value}>{props.buyer?.correspondentMobileNo}</p>
                                    </Card>
                            )

                        }
                    </div>
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16,
                            marginTop: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={failedIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <Space direction='vertical' size={0}>
                                <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ verification failed</h2>
                                <p style={{ font: 'normal normal normal 16px Open Sans', margin: 0 }} dangerouslySetInnerHTML={{ __html: statusMSG }}></p>
                            </Space>
                        </div>
                    </Card>
                    <div
                        style={{ marginTop: 20 }}
                    >

                        {
                            isMobileLayout ? (
                                <div>
                                    {
                                        isReport == true
                                            ?
                                            <></>
                                            :
                                            transactionStatus == OUT_TRANSACTION_STATUSES.SUCCESS ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION
                                                ?
                                                <></>
                                                :
                                                <Button
                                                    size='large'
                                                    style={{
                                                        width: 200,
                                                        background: " #2B61C4 0% 0% no-repeat padding-box",
                                                        boxShadow: "0px 4px 0px #1E4489",
                                                        borderRadius: "8px",
                                                        borderColor: 'transparent'
                                                    }}
                                                    onClick={() => {
                                                        setIsModalVerificationVisible(true)
                                                    }}
                                                    type='primary'
                                                >
                                                    JPJ Submission
                                                </Button>
                                    }



                                </div>
                            ) : (
                                <Row
                                    style={{
                                        width: '100%', justifyContent: 'space-between'
                                    }}
                                >
                                    <Col>
                                        <Button
                                            className='back-button buttons'
                                            shape='round'
                                            onClick={() => {
                                                if (locationState?.isReport) {
                                                    if (locationState?.fromPendingPage === true) {
                                                        history.push('/reporting/transferreport/i2i')
                                                    } else {
                                                        history.push('/reporting/transferreport')
                                                    }
                                                } else {
                                                    history.push('/')
                                                }
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </Col>
                                    {
                                        transactionStatus == OUT_TRANSACTION_STATUSES.SUCCESS ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_SELLER_INFO ?

                                            <></> :
                                            <Col>
                                                <Button
                                                    size='large'
                                                    style={{
                                                        width: 200,
                                                        background: " #2B61C4 0% 0% no-repeat padding-box",
                                                        boxShadow: "0px 4px 0px #1E4489",
                                                        borderRadius: "8px",
                                                        borderColor: 'transparent'
                                                    }}
                                                    onClick={() => {
                                                        setIsModalVerificationVisible(true)
                                                    }}
                                                    type='primary'
                                                >
                                                    Resubmit
                                                </Button>
                                            </Col>
                                    }

                                </Row>
                            )
                        }
                    </div>

                </div>

            )
        },
        [STEPS.JPJ_SUBMISSION.subSteps.JPJ_SUCCESS]: {
            component: (
                <div style={{ width: '100%', }}>
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <div style={{ display: 'flex', flex: 4, alignItems: 'center' }}>
                                <h2 style={{ color: '#40e327a8', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ checking completed</h2>
                            </div>
                        </div>
                    </Card>
                    <Modal className='i2i-buyer-info-modal' visible={isModalVerificationVisible} onCancel={() => { setIsModalVerificationVisible(false) }} footer={null} width={330}>
                        <Space direction='vertical' style={{ textAlign: 'center', marginTop: 24 }}>
                            <h1 style={{ font: "normal normal 600 24px Raleway" }}>Submit for JPJ Verification?</h1>
                            <Button

                                style={{

                                    background: " #2B61C4 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 4px 0px #1E4489",
                                    borderRadius: "8px",
                                    color: 'white',
                                    borderColor: 'transparent',
                                    width: "50%"
                                }}
                                type='primary'
                                onClick={() => {
                                    onJPJSubmit()
                                }}
                            >
                                Submit
                            </Button>
                            <Button type='link' onClick={() => { setIsModalVerificationVisible(false) }} style={{ font: 'normal normal 600 14px Open Sans', color: "#2B61C4", paddingTop: 16 }}>
                                CANCEL
                            </Button>
                        </Space>
                    </Modal>
                    <div style={{ width: '100%' }}>
                        <Col span={24}>
                            <Card
                                style={{
                                    textAlign: 'left',
                                    marginBottom: 10,
                                    boxShadow: '0px 3px 6px #00000029',
                                    borderRadius: '4px'
                                }}
                            >
                                <h2>Vehicle Information</h2>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Vehicle Number</p>
                                        <p style={styles.value}>{props.vehicle?.vehicleRegistrationNo}</p>
                                    </Col>
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Make</p>
                                        <p style={styles.value}>{props.vehicle?.carMake}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Model</p>
                                        <p style={styles.value}>{props.vehicle?.carModel}</p>
                                    </Col> */}
                                {/* </Row>
                                <Row gutter={16}> */}
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Manufacture Year</p>
                                        <p style={styles.value}>{props.vehicle?.manufactureYear}</p>
                                    </Col> */}
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Engine Number</p>
                                        <p style={styles.value}>{props.vehicle?.engineNo}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Chassis Number</p>
                                        <p style={styles.value}>{props.vehicle?.chassisNo}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 10,
                        }}
                    >
                        {
                            isLoading ? (
                                <Skeleton active />
                            ) : (
                                props.transactionType === 1 || props.transactionType === 4 ?
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Owner's Details</h2>
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.seller?.name}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.seller?.identificationNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{sellerFormattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isSellerMale ? 'Male' : 'Female'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller?.state)?.label, districtName: sellerDistrictName })}</p>
                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>
                                            {
                                                props.seller.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.seller, correspondentState: Object.values(STATES).find(s => s.code === props.seller.correspondentState)?.label, districtName: sellerCorrespondenceDistrictName })
                                                    : parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label, districtName: sellerDistrictName })
                                            }
                                        </p> */}
                                        <p style={styles.label}>Email</p>
                                        <p style={styles.value}>{props.seller?.correspondentEmail}</p>
                                        <p style={styles.label}>Mobile Number</p>
                                        <p style={styles.value}>{props.seller?.correspondentMobileNo}</p>
                                    </Card>
                                    :
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Seller's Company Details</h2>
                                        <p style={styles.label}>Company Name</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Company ROC</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Company Address</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative Name</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative NRIC</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative Email</p>
                                        <p style={styles.value}>-</p>
                                        <p style={styles.label}>Representative Mobile Number</p>
                                        <p style={styles.value}>-</p>
                                    </Card>
                            )

                        }

                        {
                            isLoading ? (
                                <Skeleton active />
                            ) : (
                                props.transactionType === 1 || props.transactionType === 2 ?
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Buyer's Details</h2>
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.buyer?.name}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.buyer?.identificationNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{buyerFormattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isBuyerMale ? 'Male' : 'Female'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer?.state)?.label, districtName: buyerDistrictName })}</p>
                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>
                                            {
                                                props.buyer.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.buyer, correspondentState: Object.values(STATES).find(s => s.code === props.buyer.correspondentState)?.label, districtName: buyerCorrespondenceDistrictName })
                                                    : parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer.state)?.label, districtName: buyerDistrictName })
                                            }
                                        </p> */}
                                        <p style={styles.label}>Email</p>
                                        <p style={styles.value}>{props.buyer?.correspondentEmail}</p>
                                        <p style={styles.label}>Mobile Number</p>
                                        <p style={styles.value}>{props.buyer?.correspondentMobileNo}</p>
                                    </Card>

                                    :
                                    <Card
                                        style={{
                                            flex: 3,
                                            textAlign: 'left',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <h2>Buyer's Company Details</h2>
                                        <p style={styles.label}>Company Name</p>
                                        <p style={styles.value}>{props.buyer?.companyDetails?.coName ?? props.buyer?.name}</p>
                                        <p style={styles.label}>Company ROC</p>
                                        <p style={styles.value}>{props.buyer?.companyDetails?.coRegNo ?? props.buyer?.identificationNo}</p>
                                        <p style={styles.label}>Company Address</p>
                                        <p style={styles.value}>
                                            {props.buyer?.correspondentAddress1
                                                ? parseCorrespondenceAddress({ ...props.buyer, correspondentState: Object.values(STATES).find(s => s.code === props.buyer?.correspondentState)?.label, districtName: buyerCorrespondenceDistrictName })
                                                : parseAddress({ ...props?.buyer?.companyDetails?.companyAddress, state: Object.values(STATES).find(s => s.code === props.buyer?.companyDetails?.companyAddress.stateCode)?.label, districtName: props?.buyer?.companyDetails?.companyAddress.district ? props?.buyer?.companyDetails?.companyAddress.district : props?.buyer?.companyDetails?.companyAddress.city })}
                                            {/* // : parseAddress({ ...props?.buyer.companyDetails.companyAddress, state: Object.values(STATES).find(s => s.code === props.buyer?.companyDetails.companyAddress.stateCode)?.label, districtName: props?.buyer?.companyDetails?.companyAddress.district ? props?.buyer?.companyDetails?.companyAddress.district : props?.buyer?.companyDetails?.companyAddress.city })} */}
                                        </p>
                                        <p style={styles.label}>Representative Name</p>
                                        <p style={styles.value}>{props.buyer?.repName ?? props.buyer?.name}</p>
                                        <p style={styles.label}>Representative NRIC</p>
                                        <p style={styles.value}>{props.buyer?.repIc ?? props.buyer?.identificationNo}</p>
                                        <p style={styles.label}>Representative Email</p>
                                        <p style={styles.value}>{props.buyer?.correspondentEmail}</p>
                                        <p style={styles.label}>Representative Mobile Number</p>
                                        <p style={styles.value}>{props.buyer?.correspondentMobileNo}</p>
                                    </Card>
                            )

                        }
                    </div>
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16,
                            marginTop: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <div style={{ display: 'flex', flex: 4, alignItems: 'center' }}>
                                <h2 style={{ color: '#40e327a8', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ checking completed</h2>
                            </div>
                        </div>
                    </Card>
                    <div
                        style={{ marginTop: 20 }}
                    >

                        {
                            isMobileLayout ? (
                                <div>
                                    {
                                        isReport == true
                                            ?
                                            <></>
                                            :
                                            transactionStatus == OUT_TRANSACTION_STATUSES.SUCCESS ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP ||
                                                transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION
                                                ?
                                                <></>
                                                :
                                                <Button
                                                    size='large'
                                                    style={{
                                                        width: 200,
                                                        background: " #2B61C4 0% 0% no-repeat padding-box",
                                                        boxShadow: "0px 4px 0px #1E4489",
                                                        borderRadius: "8px",
                                                        borderColor: 'transparent'
                                                    }}
                                                    onClick={() => {
                                                        setIsModalVerificationVisible(true)
                                                    }}
                                                    type='primary'
                                                >
                                                    JPJ Submission
                                                </Button>
                                    }



                                </div>
                            ) : (
                                <Row
                                    style={{
                                        width: '100%', justifyContent: 'space-between'
                                    }}
                                >
                                    <Col>
                                        <Button
                                            className='back-button buttons'
                                            shape='round'
                                            onClick={() => {
                                                if (locationState?.isReport) {
                                                    if (locationState?.fromPendingPage === true) {
                                                        history.push('/reporting/transferreport/i2i')
                                                    } else {
                                                        history.push('/reporting/transferreport')
                                                    }
                                                } else {
                                                    history.push('/')
                                                }
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </Col>
                                    {
                                        transactionStatus == OUT_TRANSACTION_STATUSES.SUCCESS ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO ||
                                            transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_SELLER_INFO ?

                                            <></> :
                                            <Col>
                                            {
                                                <Button
                                                    className='rectangle-button'
                                                    onClick={() => {
                                                        // setIsModalVisible(true)
                                                        history.push('/transfer/i2i/create', {
                                                            ...history.location.state,
                                                            step: STEPS.TRANSACTION_TYPE.label,
                                                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                                        })
            
                                                    }}
                                                    type='primary'
                                                >
                                                    OK
                                                </Button>
                                            }
                                            </Col>
                                    }

                                </Row>
                            )
                        }
                    </div>

                </div>

            )
        },
    }

    const currentStep = COMPONENT_STEPS[currentSubStep]

    const onJPJSubmit = async () => {

        try {

            history.push(history.location.pathname, {
                ...history.location.state,
                step: STEPS.JPJ_SUBMISSION.label,
                subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_CHECKING,
            })

            setIsLoading(true)

            let trfId = history.location.state.transferId ? history.location.state.transferId : transferId

            let i2iSaveOutSellerBuyerInquiryRes = await i2iSaveOutSellerBuyerInquiry(trfId)

            if (i2iSaveOutSellerBuyerInquiryRes.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) throw i2iSaveOutSellerBuyerInquiryRes.statusMSG

            if(!validateRolePermission(loggedInUserRole, "I2I", true)) {
                history.push(`/transfer/i2i/payment/${trfId}`)
            }
            else {
                history.push(history.location.pathname, {
                    ...history.location.state,
                    step: STEPS.JPJ_SUBMISSION.label,
                    subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_SUCCESS,
                })
            }

        } catch (error) {

            setStatusMSG(error?.toString())

            // error && message.error(error?.toString())
            processErrorMessage(error)

            history.push(history.location.pathname, {
                ...history.location.state,
                step: STEPS.JPJ_SUBMISSION.label,
                subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
            })

        } finally {
            setIsLoading(false)
        }

    }
    // const onJPJSubmit = () => {

    //     history.push(history.location.pathname, {
    //         ...history.location.state,
    //         step: STEPS.JPJ_SUBMISSION.label,
    //         subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_CHECKING,
    //     })

    //     setIsLoading(true)

    //     const sellerIsCompany =
    //         props.transactionType === OUT_TRANSACTION_TYPES_IDS.D2I
    //         || props.transactionType === OUT_TRANSACTION_TYPES_IDS.D2C

    //     const buyerIsCompany =
    //         props.transactionType === OUT_TRANSACTION_TYPES_IDS.D2C
    //         || props.transactionType === OUT_TRANSACTION_TYPES_IDS.I2C

    //     let sellerDistrictCode

    //     if (props?.seller?.district) {
    //         sellerDistricts
    //             .filter(district => {

    //                 return district?.districtName.toUpperCase() === props.seller?.district.toUpperCase()

    //             })
    //             .map(district => {
    //                 sellerDistrictCode = district.districtCode
    //             })
    //     }

    //     let sellerCorrespondentDistrictCode

    //     if (props?.seller?.correspondentDistrict) {
    //         sellerCorrespondenceDistricts
    //             .filter(district => {

    //                 return district?.districtName.toUpperCase() === props.seller?.correspondentDistrict.toUpperCase()

    //             })
    //             .map(district => {
    //                 sellerCorrespondentDistrictCode = district.districtCode
    //             })
    //     }

    //     let seller = {
    //         address1: props?.seller?.address1,
    //         address2: props?.seller?.address2,
    //         address3: props?.seller?.address3,
    //         correspondentAddress1: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentAddress1 : props?.seller?.address1,
    //         correspondentAddress2: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentAddress2 : props?.seller?.address2,
    //         correspondentAddress3: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentAddress3 : props?.seller?.address3,
    //         correspondentDistrict: props?.seller?.isAddressDifferent === true ? sellerCorrespondentDistrictCode : sellerDistrictCode,
    //         correspondentEmail: props?.seller?.correspondentEmail,
    //         correspondentMobileNo: props?.seller?.correspondentMobileNo,
    //         correspondentPostcode: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentPostcode : props?.seller?.postcode,
    //         correspondentState: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentState : props?.seller?.state,
    //         dateOfBirth: props?.seller?.dateOfBirth,
    //         district: sellerDistrictCode,
    //         email: props?.seller?.email,
    //         gender: props?.seller?.gender,
    //         identificationNo: props?.seller?.identificationNo,
    //         name: props?.seller?.name,
    //         phoneNo: props?.seller?.phoneNo,
    //         mobileNo: props?.seller?.mobileNo,
    //         postcode: props?.seller?.postcode,
    //         state: props?.seller?.state,
    //         sellerType: props?.seller?.sellerType
    //     }

    //     let buyerDistrictCode

    //     if (props?.buyer?.district) {
    //         buyerDistricts
    //             .filter(district => {

    //                 return district?.districtName.toUpperCase() === props.buyer?.district.toUpperCase()

    //             })
    //             .map(district => {
    //                 buyerDistrictCode = district.districtCode
    //             })
    //     }

    //     let buyerCorrespondentDistrictCode

    //     if (props?.buyer?.correspondentDistrict) {
    //         buyerCorrespondenceDistricts
    //             .filter(district => {

    //                 return district?.districtName.toUpperCase() === props.buyer?.correspondentDistrict.toUpperCase()

    //             })
    //             .map(district => {
    //                 buyerCorrespondentDistrictCode = district.districtCode
    //             })
    //     }

    //     let buyer = {
    //         address1: props?.buyer?.address1,
    //         address2: props?.buyer?.address2,
    //         address3: props?.buyer?.address3,
    //         correspondentAddress1: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentAddress1 : props?.buyer?.address1,
    //         correspondentAddress2: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentAddress2 : props?.buyer?.address2,
    //         correspondentAddress3: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentAddress3 : props?.buyer?.address3,
    //         correspondentDistrict: props?.buyer?.isAddressDifferent === true ? buyerCorrespondentDistrictCode : buyerDistrictCode,
    //         correspondentEmail: props?.buyer?.correspondentEmail,
    //         correspondentMobileNo: props?.buyer?.correspondentMobileNo,
    //         correspondentPostcode: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentPostcode : props?.buyer?.postcode,
    //         correspondentState: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentState : props?.buyer?.state,
    //         dateOfBirth: props?.buyer?.dateOfBirth,
    //         district: buyerDistrictCode,
    //         email: props?.buyer?.email,
    //         gender: props?.buyer?.gender,
    //         identificationNo: props?.buyer?.identificationNo,
    //         name: props?.buyer?.name,
    //         phoneNo: props?.buyer?.phoneNo,
    //         mobileNo: props?.buyer?.mobileNo,
    //         postcode: props?.buyer?.postcode,
    //         state: props?.buyer?.state,
    //         buyerType: props?.buyer?.buyerType
    //     }

    //     if (transferId) {

    //         if (sellerIsCompany) seller = {}

    //         if (buyerIsCompany) {

    //             let buyerDistrictCode

    //             if (props?.buyer?.companyDetails?.companyAddress?.city) {
    //                 buyerDistricts
    //                     .filter(district => {

    //                         return district?.districtName.toUpperCase() === props?.buyer?.companyDetails?.companyAddress?.city.toUpperCase()

    //                     })
    //                     .map(district => {
    //                         buyerDistrictCode = district.districtCode
    //                     })
    //             }

    //             let buyerCorrespondentDistrictCode

    //             if (props?.buyer?.correspondentDistrict) {
    //                 buyerCorrespondenceDistricts
    //                     .filter(district => {

    //                         return district?.districtName.toUpperCase() === props.buyer?.correspondentDistrict.toUpperCase()

    //                     })
    //                     .map(district => {
    //                         buyerCorrespondentDistrictCode = district.districtCode
    //                     })
    //             }

    //             buyer = {
    //                 address1: props?.buyer?.companyDetails?.companyAddress?.line1,
    //                 address2: props?.buyer?.companyDetails?.companyAddress?.line2,
    //                 address3: props?.buyer?.companyDetails?.companyAddress?.line3,
    //                 correspondentAddress1: props?.buyer?.correspondentAddress1,
    //                 correspondentAddress2: props?.buyer?.correspondentAddress2,
    //                 correspondentAddress3: props?.buyer?.correspondentAddress3,
    //                 correspondentDistrict: buyerCorrespondentDistrictCode,
    //                 correspondentEmail: props?.buyer?.correspondentEmail,
    //                 correspondentMobileNo: props?.buyer?.correspondentMobileNo,
    //                 correspondentPostcode: props?.buyer?.correspondentPostcode,
    //                 correspondentState: props?.buyer?.correspondentState,
    //                 dateOfBirth: props?.buyer?.dateOfBirth,
    //                 district: buyerDistrictCode,
    //                 email: props?.buyer?.email,
    //                 gender: props?.buyer?.gender,
    //                 identificationNo: props?.buyer?.companyDetails?.coRegNo,
    //                 name: props?.buyer?.companyDetails?.coName,
    //                 phoneNo: props?.buyer?.phoneNo,
    //                 postcode: props?.buyer?.companyDetails?.companyAddress?.postcode,
    //                 repIc: props?.buyer?.repIc,
    //                 repName: props?.buyer?.name,
    //                 state: props?.buyer?.companyDetails?.companyAddress?.stateCode
    //             }

    //         }

    //         if (buyerIsCompany === false) {

    //             isAccountExist(buyer.identificationNo)
    //                 .then(res => {

    //                     if (res.status === false) {

    //                         const { formattedDob, isMale } = breakdownIc(buyer.identificationNo)
    //                         let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

    //                         return create1MIDAccount(
    //                             buyer.correspondentAddress1,
    //                             buyer.correspondentAddress2,
    //                             buyer.correspondentAddress3,
    //                             localFormattedDob,
    //                             '',
    //                             '',
    //                             buyer.correspondentDistrict,
    //                             buyer.correspondentEmail,
    //                             buyer.correspondentEmail,
    //                             isMale ? "MALE" : "FEMALE",
    //                             buyer.correspondentMobileNo,
    //                             buyer.name,
    //                             buyer.identificationNo,
    //                             false,
    //                             buyer.correspondentMobileNo,
    //                             buyer.correspondentPostcode,
    //                             '',
    //                             '',
    //                             buyer.correspondentState
    //                         )

    //                     } else if (res.status === true) {

    //                         return {
    //                             status: true
    //                         }

    //                     } else {
    //                         throw 'Failed to create1MIDAccount'
    //                     }

    //                 }).then(res => {

    //                     if (!res.status) {

    //                         let msg = res.message

    //                         if ('Sorry, an account already exists with email address - ' === res.message) {
    //                             msg += buyer.correspondentEmail
    //                         }

    //                         throw msg
    //                     }

    //                     if (transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_PAYMENT ||
    //                         transactionStatus == OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED ||
    //                         transactionStatus == OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS ||
    //                         transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL ||
    //                         transactionStatus == OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED
    //                     ) {

    //                         return {
    //                             saveBuyerStatus: TRANSACTION_RESPONSE_CODES.SUCCESS,
    //                             transferId: transferId
    //                         }

    //                     } else {

    //                         return saveOutBuyer(
    //                             buyer,
    //                             user.username,
    //                             company.coRegNo,
    //                             null,
    //                             transferId,
    //                             null
    //                         )
    //                     }

    //                 }).then(res => {

    //                     if (res.saveBuyerStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.saveBuyerMSG

    //                     return i2iSaveOutSellerBuyerInquiry(res.transferId)

    //                 }).then(res => {

    //                     if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) throw res.statusMSG

    //                     history.push(`/transfer/i2i/payment/${res.transferId}`)

    //                 }).catch(err => {

    //                     setStatusMSG(err?.toString())

    //                     err && message.error(err?.toString())

    //                     history.push(history.location.pathname, {
    //                         ...history.location.state,
    //                         step: STEPS.JPJ_SUBMISSION.label,
    //                         subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
    //                     })

    //                 }).finally(() => setIsLoading(false))

    //         } else {

    //             // if (transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_PAYMENT ||
    //             //     transactionStatus == OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED ||
    //             //     transactionStatus == OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS ||
    //             //     transactionStatus == OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL ||
    //             //     transactionStatus == OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED
    //             // )
    //             saveOutBuyer(
    //                 buyer,
    //                 user.username,
    //                 company.coRegNo,
    //                 sellerIsCompany ? props?.vehicle?.tacNumber : null,
    //                 transferId,
    //                 buyer?.repIc
    //             ).then(res => {

    //                 if (res.saveBuyerStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.saveBuyerMSG

    //                 setTransferId(res.transferId)

    //                 return i2iSaveOutSellerBuyerInquiry(res.transferId)

    //             }).then(res => {

    //                 if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) throw res.statusMSG

    //                 history.push(`/transfer/i2i/payment/${res.transferId}`)

    //             }).catch(err => {

    //                 setStatusMSG(err?.toString())

    //                 err && message.error(err?.toString())

    //                 history.push(history.location.pathname, {
    //                     ...history.location.state,
    //                     step: STEPS.JPJ_SUBMISSION.label,
    //                     subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
    //                 })

    //             }).finally(() => setIsLoading(false))

    //         }

    //     } else {

    //         if (sellerIsCompany) seller = {}

    //         if (buyerIsCompany) {

    //             let buyerDistrictCode

    //             if (props?.buyer?.companyDetails?.companyAddress?.city) {
    //                 buyerDistricts
    //                     .filter(district => {

    //                         return district?.districtName.toUpperCase() === props?.buyer?.companyDetails?.companyAddress?.city.toUpperCase()

    //                     })
    //                     .map(district => {
    //                         buyerDistrictCode = district.districtCode
    //                     })
    //             }

    //             let buyerCorrespondentDistrictCode

    //             if (props?.buyer?.correspondentDistrict) {
    //                 buyerCorrespondenceDistricts
    //                     .filter(district => {

    //                         return district?.districtName.toUpperCase() === props.buyer?.correspondentDistrict.toUpperCase()

    //                     })
    //                     .map(district => {
    //                         buyerCorrespondentDistrictCode = district.districtCode
    //                     })
    //             }

    //             buyer = {
    //                 address1: props?.buyer?.companyDetails?.companyAddress?.line1,
    //                 address2: props?.buyer?.companyDetails?.companyAddress?.line2,
    //                 address3: props?.buyer?.companyDetails?.companyAddress?.line3,
    //                 correspondentAddress1: props?.buyer?.correspondentAddress1,
    //                 correspondentAddress2: props?.buyer?.correspondentAddress2,
    //                 correspondentAddress3: props?.buyer?.correspondentAddress3,
    //                 correspondentDistrict: buyerCorrespondentDistrictCode,
    //                 correspondentEmail: props?.buyer?.correspondentEmail,
    //                 correspondentMobileNo: props?.buyer?.correspondentMobileNo,
    //                 correspondentPostcode: props?.buyer?.correspondentPostcode,
    //                 correspondentState: props?.buyer?.correspondentState,
    //                 dateOfBirth: props?.buyer?.dateOfBirth,
    //                 district: buyerDistrictCode,
    //                 email: props?.buyer?.email,
    //                 gender: props?.buyer?.gender,
    //                 identificationNo: props?.buyer?.companyDetails?.coRegNo,
    //                 name: props?.buyer?.companyDetails?.coName,
    //                 phoneNo: props?.buyer?.phoneNo,
    //                 postcode: props?.buyer?.companyDetails?.companyAddress?.postcode,
    //                 repIc: props?.buyer?.identificationNo,
    //                 repName: props?.buyer?.name,
    //                 state: props?.buyer?.companyDetails?.companyAddress?.stateCode
    //             }

    //         }

    //         let transferId

    //         if (sellerIsCompany) {

    //             saveOutSeller(
    //                 branch.id,
    //                 user.username,
    //                 company.coRegNo,
    //                 false,
    //                 seller,
    //                 props.transactionType,
    //                 props.vehicle,
    //             ).then(res => {

    //                 if (res.saveSellerStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.saveSellerMSG ?? res.message

    //                 setTransferId(res.transferId)
    //                 transferId = res.transferId

    //                 if (buyerIsCompany === false) return isAccountExist(buyer?.identificationNo)

    //                 return {
    //                     status: true
    //                 }

    //             }).then(res => {

    //                 if (res.status === false) {

    //                     const { formattedDob, isMale } = breakdownIc(buyer?.identificationNo)
    //                     let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

    //                     return create1MIDAccount(
    //                         buyer?.correspondentAddress1,
    //                         buyer?.correspondentAddress2,
    //                         buyer?.correspondentAddress3,
    //                         localFormattedDob,
    //                         '',
    //                         '',
    //                         buyer?.correspondentDistrict,
    //                         buyer?.correspondentEmail,
    //                         buyer?.correspondentEmail,
    //                         isMale ? "MALE" : "FEMALE",
    //                         buyer?.correspondentMobileNo,
    //                         buyer?.name,
    //                         buyer?.identificationNo,
    //                         false,
    //                         buyer?.correspondentMobileNo,
    //                         buyer?.correspondentPostcode,
    //                         '',
    //                         '',
    //                         buyer?.correspondentState
    //                     )

    //                 } else if (res.status === true) {

    //                     return {
    //                         status: true
    //                     }

    //                 } else {
    //                     throw 'Failed to create1MIDAccount'
    //                 }

    //             }).then(res => {

    //                 if (!res.status) {

    //                     let msg = res.message

    //                     if ('Sorry, an account already exists with email address - ' === res.message) {
    //                         msg += buyer?.correspondentEmail
    //                     }

    //                     throw msg
    //                 }

    //                 return saveOutBuyer(
    //                     buyer,
    //                     user.username,
    //                     company.coRegNo,
    //                     props?.vehicle?.tacNumber,
    //                     transferId,
    //                     buyerIsCompany ? buyer?.repIc : null
    //                 )

    //             }).then(res => {

    //                 if (res.saveBuyerStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.saveBuyerMSG

    //                 return i2iSaveOutSellerBuyerInquiry(res.transferId)

    //             }).then(res => {

    //                 if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) throw res.statusMSG

    //                 history.push(`/transfer/i2i/payment/${res.transferId}`)

    //             }).catch(err => {

    //                 setStatusMSG(err?.toString())
    //                 err && message.error(err?.toString())

    //                 history.push(history.location.pathname, {
    //                     ...history.location.state,
    //                     step: STEPS.JPJ_SUBMISSION.label,
    //                     subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
    //                 })

    //             }).finally(() => setIsLoading(false))

    //         } else {

    //             if (Object.keys(seller).length === 0) {
    //                 message.error('Seller info is empty.')
    //                 return
    //             }

    //             isAccountExist(seller?.identificationNo)
    //                 .then(res => {

    //                     if (res.status === false) {

    //                         const { formattedDob, isMale } = breakdownIc(seller?.identificationNo)
    //                         let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

    //                         return create1MIDAccount(
    //                             seller?.correspondentAddress1,
    //                             seller?.correspondentAddress2,
    //                             seller?.correspondentAddress3,
    //                             localFormattedDob,
    //                             '',
    //                             '',
    //                             seller?.correspondentDistrict,
    //                             seller?.correspondentEmail,
    //                             seller?.correspondentEmail,
    //                             isMale ? "MALE" : "FEMALE",
    //                             seller?.correspondentMobileNo,
    //                             seller?.name,
    //                             seller?.identificationNo,
    //                             false,
    //                             seller?.correspondentMobileNo,
    //                             seller?.correspondentPostcode,
    //                             '',
    //                             '',
    //                             seller?.correspondentState
    //                         )

    //                     } else if (res.status === true) {

    //                         return {
    //                             status: true
    //                         }

    //                     } else {
    //                         throw 'Failed to create1MIDAccount'
    //                     }


    //                 }).then(res => {

    //                     if (!res.status) {

    //                         let msg = res.message

    //                         if ('Sorry, an account already exists with email address - ' === res.message) {
    //                             msg += seller?.correspondentEmail
    //                         }

    //                         throw msg
    //                     }

    //                     return saveOutSeller(
    //                         branch.id,
    //                         user.username,
    //                         company.coRegNo,
    //                         false,
    //                         seller,
    //                         props.transactionType,
    //                         props.vehicle,
    //                     )

    //                 }).then(res => {

    //                     if (res.saveSellerStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.saveSellerMSG ?? res.message

    //                     setTransferId(res.transferId)
    //                     transferId = res.transferId

    //                     if (buyerIsCompany === false) return isAccountExist(buyer?.identificationNo)

    //                     return {
    //                         status: true
    //                     }

    //                 }).then(res => {

    //                     if (res.status === false) {

    //                         const { formattedDob, isMale } = breakdownIc(buyer?.identificationNo)
    //                         let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

    //                         return create1MIDAccount(
    //                             buyer?.correspondentAddress1,
    //                             buyer?.correspondentAddress2,
    //                             buyer?.correspondentAddress3,
    //                             localFormattedDob,
    //                             '',
    //                             '',
    //                             buyer?.correspondentDistrict,
    //                             buyer?.correspondentEmail,
    //                             buyer?.correspondentEmail,
    //                             isMale ? "MALE" : "FEMALE",
    //                             buyer?.correspondentMobileNo,
    //                             buyer?.name,
    //                             buyer?.identificationNo,
    //                             false,
    //                             buyer?.correspondentMobileNo,
    //                             buyer?.correspondentPostcode,
    //                             '',
    //                             '',
    //                             buyer?.correspondentState
    //                         )

    //                     } else if (res.status === true) {

    //                         return {
    //                             status: true
    //                         }

    //                     } else {

    //                         throw 'Failed to create1MIDAccount'
    //                     }

    //                 }).then(res => {

    //                     if (!res.status) {

    //                         let msg = res.message

    //                         if ('Sorry, an account already exists with email address - ' === res.message) {
    //                             msg += buyer?.correspondentEmail
    //                         }

    //                         throw msg
    //                     }

    //                     return saveOutBuyer(
    //                         buyer,
    //                         user.username,
    //                         company.coRegNo,
    //                         null,
    //                         transferId,
    //                         buyerIsCompany ? buyer?.repIc : null
    //                     )

    //                 }).then(res => {

    //                     if (res.saveBuyerStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.saveBuyerMSG

    //                     return i2iSaveOutSellerBuyerInquiry(res.transferId)

    //                 }).then(res => {

    //                     if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) throw res.statusMSG

    //                     history.push(`/transfer/i2i/payment/${res.transferId}`)

    //                 }).catch(err => {

    //                     setStatusMSG(err?.toString())

    //                     err && message.error(err?.toString())

    //                     history.push(history.location.pathname, {
    //                         ...history.location.state,
    //                         step: STEPS.JPJ_SUBMISSION.label,
    //                         subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
    //                     })

    //                 }).finally(() => setIsLoading(false))

    //         }

    //     }

    // }

    return (
        <>
            <JPJSubmissionModal
                transactionType={props?.transactionType}
                isModalVisible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={(approverIc, authCode) => {
                    if (APP_CONST.USE_OTP === true) {
                        verifyApproverSmsOtp(approverIc, authCode).then(res => {

                            const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)
                            if (decryptedResponse.status == 'false') throw decryptedResponse.message

                            props.onApproverNRICChange(approverIc)
                            setIsModalVisible(false)
                            onJPJSubmit(approverIc)

                        }).catch(err => {
                            err && message.error(err)
                        })
                    } else {
                        verifyApproverOTP(approverIc, authCode)
                            .then(res => {
                                if (res.status !== true) throw res.message
                                props.onApproverNRICChange(approverIc)
                                setIsModalVisible(false)
                                onJPJSubmit(approverIc)
                            })
                            .catch(err => err && message.error(err))
                    }

                }}
            />
            {
                currentStep.component
            }
        </>
    )
}

JPJSubmission.propTypes = {
    seller: propTypes.object.isRequired,
    buyer: propTypes.object.isRequired,
    vehicle: propTypes.object.isRequired,
    transactionType: propTypes.number.isRequired,
    onApproverNRICChange: propTypes.func.isRequired,
    approverNric: propTypes.string,
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0, fontFamily: 'Open Sans', fontSize: '16px'
    },
    value: {
        fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '16px',
        // textTransform: 'capitalize'
    }
}

export default JPJSubmission