import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Col,
    Divider,
    Dropdown,
    Layout,
    Menu,
    message,
    Row,
    Space,
    Modal,
    Select,
    Input,
    Button
} from 'antd';
import moment from "moment";
import { SessionContext } from '../../App';
import background from '../../assets/bg-texture.png';
// import logo from '../../assets/logo-myeg.svg';
import logo from '../../assets/logo/TWOCAR-Logo-White.png'
import { links } from '../../config';
import {
    OTHER_TRANSACTIONS_LINK,
    HQ_BRANCH,
    APP_CONST
} from '../../constants';
import {
    getMysikapLink,
    logout,
    sendSmsOtp,
    getAdminUsers,
    getAccountDetails,
    verifyApproverSmsOtp,
    verifyApproverOTP,
    getProfile
} from '../../services/api';
import {
    getUserRole,
    removeUserRole,
    getIsAuthorizeToAccessUserManagement,
    setIsAuthorizeToAccessUserManagement,
    getAuthorizedNric,
    removeCachedReportType
} from '../../services/local';
import { getIsMobileLayout, getDecryptedVerifyOtpResponse } from '../../util';
import Routes from '../Routes';
import './index.less';
import { WhiteChevronIcon, WhiteProfileIcon } from '../CustomIcons/index';
import blue_profile_icon from '../../assets/icon-profile-blue-44.svg'

import PrivacyPolicy from "../../assets/files/PrivacyPolicy.pdf"
import { useIdleTimer } from 'react-idle-timer'

const { Header, Content, Footer } = Layout;

export default function Frame(props) {

    const { user, company, branch } = useContext(SessionContext)
    const [mySikapLink, setMySikapLink] = useState("");

    const history = useHistory()
    const [currentTime, setCurrentTime] = useState(moment().format("DD MMM YYYY hh:mm A"))
    // const [branchs, setBranchs] = useState([])

    const isMobileLayout = getIsMobileLayout()

    const [isLoading, setIsLoading] = useState(false)
    const [authCode, setAuthCode] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [adminUsers, setAdminUsers] = useState([])
    const [userMobileNo, setUserMobileNo] = useState('')
    const [role, setRole] = useState(null)
    const [approverIc, setApproverIc] = useState(null)
    const [minutes, setMinutes] = useState('00')
    const [seconds, setSeconds] = useState('00')
    const [otpTimeout, setOtpTimeout] = useState(0)
    const [smsProvider, setSmsProvider] = useState(1)

    const Ref = useRef(null);

    const onPrompt = () => {
        // Fire a Modal Prompt
        // console.log('onPrompt...')
    }

    const onIdle = () => {
        // Close Modal Prompt
        // Do some idle action like log out your user
        // console.log('onIdle...')
        message.info('You will be logged out due to inactivity for more than 10 minutes.')
        setTimeout(async () => {
            await logout();
            await removeUserRole();
            history.push('/login');
            window.location.reload();
        }, 1 * 3000);
    }

    const onActive = (event) => {
        // Close Modal Prompt
        // Do some active action
        // console.log('onActive...')
    }

    const onAction = (event) => {
        // Do something when a user triggers a watched event
        // console.log('onAction...')
    }

    const {
        start,
        reset,
        activate,
        pause,
        resume,
        isIdle,
        isPrompted,
        isLeader,
        getTabId,
        getRemainingTime,
        getElapsedTime,
        getLastIdleTime,
        getLastActiveTime,
        getTotalIdleTime,
        getTotalActiveTime
    } = useIdleTimer({
        onPrompt,
        onIdle,
        onActive,
        onAction,
        // timeout: 5000,
        timeout: 1000 * 60 * 10,
        promptTimeout: 0,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false
    })

    useEffect(() => {

        if (company && company?.id) {

            getUserRole()
                .then(localRole => {

                    if (
                        localRole === 'VALUER'
                        || localRole === 'SALES'
                        // || localRole === 'GENERAL_MANAGER'
                    ) {

                        getAdminUsersFromApi()

                    } else {

                        setApproverIc(user?.nric)
                        setUserMobileNo(user?.mobileno)

                    }
                })
                .catch(err => err && message.error(err))

            // if (APP_CONST.USE_OTP === true) {

            //     getOwnerDetailsForCompany(company.coRegNo)
            //         .then(res => {

            //             if (!res.status) throw res.message

            //             setApproverIc(res.owner?.nric_no)

            //             return getAccountDetails(res.owner?.nric_no)


            //         })
            //         .then(res => {

            //             // if (!res.status) throw res.message

            //             setUserMobileNo(res?.mobileno)

            //         })
            //         .catch(err => {

            //             err && message.error(err)

            //         }).finally()

            // } else {

            //     // getUserRole()
            //     //     .then(localRole => {

            //     //         if (
            //     //             localRole === 'VALUER'
            //     //             || localRole === 'SALES'
            //     //             // || localRole === 'GENERAL_MANAGER'
            //     //         ) {

            //     //             getAdminUsersFromApi()

            //     //         } else {

            //     //             setApproverIc(user?.nric)
            //     //             setUserMobileNo(user?.mobileno)

            //     //         }
            //     //     })
            //     //     .catch(err => err && message.error(err))

            // }

        }

    }, [company])

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            setMinutes((minutes > 9 ? minutes : '0' + minutes))
            setSeconds((seconds > 9 ? seconds : '0' + seconds))

        } else {
            if (otpTimeout !== 0) {
                setOtpTimeout(0)
            }
        }
    }

    const clearTimer = (e) => {

        setMinutes('0' + otpTimeout)
        setSeconds('00')

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        // deadline.setSeconds(deadline.getSeconds() + otpTimeout)
        deadline.setMinutes(deadline.getMinutes() + otpTimeout)
        return deadline;
    }

    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    const sendOtp = () => {
        sendSmsOtp(approverIc, smsProvider).then(res => {
            if (!res.status) throw res.message
            // if (!res.status === true) throw res.message

            // setOtpTimeout(3)

        }).catch(err => err && message.error(err.toString()))
    }

    const resetOtpModal = () => {
        setOtpTimeout(0)
    }

    useEffect(() => {
        onClickReset()
    }, [otpTimeout])

    const getAdminUsersFromApi = () => {

        setIsLoading(true)

        getAdminUsers(company.id, HQ_BRANCH.id)
            .then(res => {
                if (res.status !== true) throw res.message
                setAdminUsers(res.users)
            })
            .catch(err => err && message.error(err.toString()))
            .finally(() => setIsLoading(false))
    }

    const setApproverInfo = (approverIc) => {

        setApproverIc(approverIc)

        getAccountDetails(approverIc)
            .then(res => {

                setUserMobileNo(res?.mobileno)

            })
            .catch(err => err && message.error(err))
    }

    const currentLocation = useLocation();
    // Is used to disable links if the page is before dashboard
    const isBeforeDashboardPage = currentLocation.pathname.includes("/2fa")
        || currentLocation.pathname.includes("/selectbranch")
        || currentLocation.pathname.includes("/remoteUserActivation")
        || currentLocation.pathname.includes("/otp");

    useEffect(() => {

        const isUsermanagementPage = currentLocation.pathname.includes("/usermanagement")

        if (isUsermanagementPage) {

            const canAccessUsermanagement = getIsAuthorizeToAccessUserManagement()

            if (canAccessUsermanagement === 'false') {

                setIsModalVisible(true)

            }

        } else {
            setIsAuthorizeToAccessUserManagement(true)//set to true to disable otp
        }

    }, [currentLocation])

    useEffect(() => {
        if (
            !currentLocation.pathname.includes('/reporting/transferreport') &&
            !currentLocation.pathname.startsWith('/transfer/in/printslip') &&
            !currentLocation.pathname.startsWith('/transfer/out/printslip') &&
            !currentLocation.pathname.startsWith('/transfer/i2i/printslip') &&
            !currentLocation.pathname.startsWith('/reporting/audittraillog/Etms') &&
            !currentLocation.pathname.startsWith('/reporting/audittraillog/stms')
        ) {
            // Call your removeCachedReportType function here
            removeCachedReportType();
        }

    }, [currentLocation])

    useEffect(async () => {

        if (company.id) {

            getUserRole().then((res) => {
                setRole(res)
            })

            //commented to not show mysikap label
            /* getMysikapLink(company.id).then(async (link) => {
                setMySikapLink(link.url);
            }) */
        }
    }, [company])

    const onCancelModal = () => {
        setIsModalVisible(false)
    }

    const verifyOtp = () => {

        setIsLoading(true)

        if (APP_CONST.USE_OTP === true) {

            verifyApproverSmsOtp(approverIc, authCode)
                .then(res => {

                    const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)
                    setAuthCode('')

                    if (decryptedResponse.status == 'false') throw decryptedResponse.message

                    message.success(decryptedResponse.message)
                    history.push('/usermanagement')

                })
                .catch(err => {

                    setAuthCode('')
                    err && message.error(err)

                })
                .finally(() => {
                    resetOtpModal()
                    setIsModalVisible(false)
                    setIsLoading(false)
                })

        } else {

            verifyApproverOTP(approverIc, authCode)
                .then(res => {

                    if (res.status !== true) throw res.message

                    message.success(res.message)
                    setIsAuthorizeToAccessUserManagement(true)//set to true to disable otp
                    history.push('/usermanagement')

                })
                .catch(err => {

                    err && message.error(err)

                })
                .finally(() => {
                    setIsModalVisible(false)
                    setIsLoading(false)
                })
        }


    }

    useEffect(() => {
        const paths = [
            '/transfer/in',
            '/transfer/out',
            '/transfer/i2i',
            '/reporting/transferreport/in',
            '/reporting/transferreport/Out',
            '/reporting/transferreport/i2i'
        ];
        const excludedPaths = [
            '/transfer/in/printslip',
            '/transfer/in/vehicleinfo',
            '/transfer/out/printslip',
            '/transfer/out/vehicleinfo',
            '/transfer/i2i/printslip',
            '/transfer/i2i/vehicleinfo',
            '/reporting/transferreport'
        ];

        if (paths.some(path => currentLocation.pathname.startsWith(path)) && !excludedPaths.some(exPath => currentLocation.pathname.startsWith(exPath))) {
            if (!getAuthorizedNric()) {
                const currentPath = paths.find(path => currentLocation.pathname.startsWith(path));
                const lastParam = currentPath.split('/').pop();
                const redirectPath = `/transfer/${lastParam}`;
                if (
                    !currentLocation.search &&//if search param is not empty meaning the prev location is from sagem authorization
                    currentLocation.state?.step !== 'AUTHORIZE_DEALER' &&
                    currentLocation.state?.subStep !== 'D_INSERT_IC') {
                    history.push(`${redirectPath}/create`, {
                        step: 'AUTHORIZE_DEALER',
                        subStep: 'D_INSERT_IC',
                    });
                }
            }
        }
    }, [currentLocation]);

    useEffect(() => {
        const checkInvalidToken = async () => {
            
            if (currentLocation.pathname !== "/login") {
                const res = await getProfile();

                let isInvalidToken = res?.details?.includes(
                    "Invalid user token.Force logout user."
                );
                if (isInvalidToken) {
                    setTimeout(async () => {
                        message.loading('Redirecting to login page...')
                        await logout();
                        await removeUserRole();
                        window.location.pathname = "/login";

                    }, 1 * 2000)
                }
            }
        }

        checkInvalidToken()
    }, [currentLocation]);

    return (

        <Layout>
            {
                APP_CONST.USE_OTP === true ?
                    <>
                        <Modal
                            visible={isModalVisible}
                            onCancel={onCancelModal}
                            width={400}
                            footer={null}
                            className='usermanagement-info-modal'
                            afterClose={() => setSmsProvider(1)}
                        >
                            <div direction='vertical' style={{ marginTop: 24, width: '100%' }}>
                                <p style={{ textAlign: 'center', font: 'normal normal 600 24px Raleway' }}>User management</p>

                                {
                                    role === 'VALUER'
                                        || role === 'SALES'
                                        // || role === 'GENERAL_MANAGER'
                                        ?
                                        <div>
                                            <p style={styles.label}>Approval Name</p>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 10,
                                                    marginBottom: 16
                                                }}
                                            >
                                                <Select
                                                    className='textbox-form-style'
                                                    loading={isLoading}
                                                    placeholder="Select an approver"
                                                    style={{ width: '100%' }}
                                                    onChange={approverIc => setApproverInfo(approverIc)}
                                                >
                                                    {
                                                        adminUsers.map(u => {
                                                            return <Select.Option value={u.nric_no} key={u.nric_no}>
                                                                {u.login_name}
                                                            </Select.Option>
                                                        })
                                                    }
                                                </Select>

                                            </div></div> : null}


                                <p style={{ fontStyle: 'italic' }}>Verification Code</p>
                                <p>Please enter the verification code sent to <br /><b>{userMobileNo}</b></p>
                                <Row gutter={[8, 8]}>
                                    <Col span={16}>
                                        <Input.Password
                                            className='textbox-form-style'
                                            disabled={!approverIc}
                                            placeholder='e.g. 000000'
                                            value={authCode}
                                            onChange={e => {
                                                setAuthCode(e.target.value)
                                            }}
                                            visibilityToggle
                                        />
                                    </Col>
                                    <Col span={8}>
                                        {
                                            minutes === '00' && seconds === '00' &&
                                            <Button
                                                id={'show-resend-otp'}
                                                style={{
                                                    width: '100%',
                                                    background: 'white',
                                                    border: 'none',
                                                    color: '#2B61C4',
                                                    textTransform: 'uppercase'
                                                }}
                                                type="secondary" onClick={() => {

                                                    // setSmsProvider((smsProvider < 2 ? smsProvider + 1 : 1))
                                                    setSmsProvider(1)
                                                    setOtpTimeout(3)
                                                    sendOtp()
                                                }}>
                                                SEND CODE
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                                {
                                    minutes === '00' && seconds === '00' ? <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}></p> :
                                        <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}>Code will expire in {minutes} min {seconds} sec</p>
                                }
                                <Button
                                    type='primary'
                                    onClick={verifyOtp}
                                    disabled={!authCode}
                                    size='large'
                                    loading={isLoading}
                                    style={{
                                        marginTop: 24
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal >
                    </>
                    :
                    <>
                        <Modal
                            visible={isModalVisible}
                            onCancel={onCancelModal}
                            width={330}
                            footer={null}
                            className='usermanagement-info-modal'
                        >
                            <div direction='vertical' style={{ marginTop: 24, width: '100%' }}>
                                <p style={{ textAlign: 'center', font: 'normal normal 600 24px Raleway' }}>User management</p>

                                {role === 'VALUER'
                                    || role === 'SALES'
                                    // || role === 'GENERAL_MANAGER'
                                    ? <div>
                                        <p style={styles.label}>Approval Name</p>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 10,
                                                marginBottom: 16
                                            }}
                                        >
                                            <Select
                                                className='textbox-form-style'
                                                loading={isLoading}
                                                placeholder="Select an approver"
                                                style={{ width: '100%' }}
                                                onChange={approverIc => setApproverIc(approverIc)}
                                            >
                                                {
                                                    adminUsers.map(u => {
                                                        return <Select.Option value={u.nric_no} key={u.nric_no}>
                                                            {u.login_name}
                                                        </Select.Option>
                                                    })
                                                }
                                            </Select>

                                        </div></div> : null}

                                <p style={styles.label}>Authentication Code</p>
                                <Input
                                    className='textbox-form-style'
                                    disabled={!approverIc}
                                    placeholder='e.g. 000000'
                                    value={authCode}
                                    onChange={e => {
                                        setAuthCode(e.target.value)
                                    }}
                                />
                                <Button
                                    type='primary'
                                    // onClick={() => props.onOk(approverIc, authCode)}
                                    disabled={!authCode}
                                    size='large'
                                    // loading={props.paymentLoading}
                                    style={{
                                        marginTop: 24
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal >
                    </>
            }
            <div style={{
                background: `linear-gradient(to right, #4A9D9B, #442372),url(${background})`,
                backgroundBlendMode: 'overlay',
                backgroundSize: isMobileLayout ? '' : `cover`,
                WebkitClipPath: 'polygon(0px 0px, 100% 0px, 100% 44%, 0px 70%)',
                clipPath: 'polygon(0px 0px, 100% 0px, 100% 44%, 0px 70%)',
                minHeight: '100vh',
            }}></div>
            <div style={{
                minHeight: '100vh',
                position: 'absolute',
                left: '0',
                right: '0',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {
                    isMobileLayout ? (
                        <Header
                            style={{
                                backgroundColor: 'transparent',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '32px',
                            }}
                        >
                            <img
                                style={{
                                    cursor: 'pointer'
                                }}
                                src={logo} height={50}
                                onClick={() => history.push('/')}
                            />

                            <a
                                href={`${window.location.origin}/profile`}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <WhiteProfileIcon />

                                </div>
                            </a>
                        </Header>
                    ) : (
                        <Header
                            style={{
                                backgroundColor: 'transparent',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '32px',
                            }}
                        >
                            <Space size='large'>

                                {!isBeforeDashboardPage &&
                                    <>
                                        <a
                                            href={window.location.origin}
                                        >
                                            <img
                                                src={logo} height={50} alt=''
                                            />
                                        </a>
                                        <a href={window.location.origin} style={{
                                            color: 'white',
                                            margin: 0,
                                            font: 'normal normal normal 16px/22px Open Sans',
                                            marginLeft: 5,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: 400
                                        }}>
                                            Dashboard
                                        </a></>}
                                {isBeforeDashboardPage && <img
                                    src={logo} height={50} alt=''
                                />}
                                {!isBeforeDashboardPage &&

                                    // role !== 'SALES_MANAGER' &&
                                    role !== 'MYEG_ADMIN' &&
                                    <>
                                        <Dropdown
                                            overlay={

                                                <Menu>
                                                    <Menu.ItemGroup title="TRANSFER VEHICLE OWNERSHIP" style={{ font: 'normal normal normal 14px/19px Open Sans', color: ' #666666' }}>
                                                        {
                                                            role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' &&

                                                            <Menu.Item key={"in"} onClick={() => {
                                                                if (getAuthorizedNric()) {

                                                                    history.push("/transfer/in/create", {
                                                                        step: 'OWNERSHIP_TYPE',
                                                                        subStep: 'OWNERSHIP_TYPE'
                                                                    })

                                                                } else {
                                                                    history.push("/transfer/in/create", {
                                                                        step: 'AUTHORIZE_DEALER',
                                                                        subStep: 'D_INSERT_IC',
                                                                    })
                                                                }
                                                            }} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>Owner to Dealer</Menu.Item>
                                                        }

                                                        { role !== 'VALUER' && role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' ? <Menu.Item key={"out"} onClick={() => {
                                                            if (getAuthorizedNric()) {

                                                                history.push("/transfer/out/create", {
                                                                    step: 'TRANSACTION_TYPE',
                                                                    subStep: 'TRANSACTION_TYPE'
                                                                })

                                                            } else {
                                                                history.push("/transfer/out/create", {
                                                                    step: 'AUTHORIZE_DEALER',
                                                                    subStep: 'D_INSERT_IC',
                                                                })
                                                            }
                                                        }} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>Dealer to Buyer</Menu.Item> : <></>}
                                                        {role !== 'VALUER' ? <Menu.Item key={"i2i"} onClick={() => {
                                                            if (getAuthorizedNric()) {

                                                                history.push("/transfer/i2i/create", {
                                                                    step: 'TRANSACTION_TYPE',
                                                                    subStep: 'TRANSACTION_TYPE'
                                                                })

                                                            } else {
                                                                history.push("/transfer/i2i/create", {
                                                                    step: 'AUTHORIZE_DEALER',
                                                                    subStep: 'D_INSERT_IC',
                                                                })
                                                            }
                                                        }} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>Owner to Owner</Menu.Item> : <></>}
                                                    </Menu.ItemGroup>
                                                    <Menu.ItemGroup title="OTHER TRANSACTION" style={{ font: 'normal normal normal 14px/19px Open Sans', color: ' #666666' }}>
                                                        {/* commented to not show mysikap label */}
                                                        {/* {mySikapLink ? (
                                                            <Menu.Item
                                                                key={"permanent"}
                                                                onClick={() => {
                                                                    window.open(mySikapLink, "_blank");
                                                                }}
                                                                style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}
                                                            >
                                                                mySikap Permanent Vehicle Transfer
                                                            </Menu.Item>
                                                        ) : null} */}
                                                        <Menu.Item key={"insurance"} onClick={() => window.open(OTHER_TRANSACTIONS_LINK.INSURANCE, '_blank')} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>Renew Insurance</Menu.Item>
                                                        <Menu.Item key={"roadtax"} onClick={() => window.open(OTHER_TRANSACTIONS_LINK.ROADTAX, '_blank')} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>Renew Road Tax</Menu.Item>
                                                    </Menu.ItemGroup>
                                                </Menu>
                                            }

                                        >

                                            <a>
                                                <p
                                                    style={{
                                                        color: 'white',
                                                        font: 'normal normal normal 16px/22px Open Sans',
                                                        margin: 0,
                                                        marginLeft: 5,
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: 400,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    Service
                                                    <WhiteChevronIcon />

                                                </p>
                                            </a>
                                        </Dropdown>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Menu.Item key={'1'} onClick={() => history.push('/reporting')} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>Reporting</Menu.Item>
                                                    {role !== 'ADMIN' && role !== 'VALUER' && role !== 'SALES' && role !== 'MYEG_RUNNER_1' && role !== 'MYEG_RUNNER_2' ? <Menu.Item key={'2'} onClick={() => history.push('/usermanagement')} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>User management</Menu.Item> : null}
                                                    {role == "OWNER" || role == "GENERAL_MANAGER" ? <Menu.Item key={'3'} onClick={() => history.push('/branchmanagement')} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>Branch management</Menu.Item> : null}
                                                    {role !== "SALES" && role !== "VALUER" && role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' ? <Menu.Item key={'4'} onClick={() => history.push('/interbranchtransfer')} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>Inter-branch transfer</Menu.Item> : null}
                                                    <Menu.Item key={'5'} onClick={() => history.push('/userguide')} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>User guide</Menu.Item>
                                                    <Menu.Item key={'6'} onClick={() => history.push('/download')} style={{ font: 'normal normal 600 16px/44px Open Sans', color: '#2B61C4' }}>Download forms</Menu.Item>
                                                </Menu>
                                            }

                                        >
                                            <a>
                                                <p
                                                    style={{
                                                        color: 'white',
                                                        margin: 0,
                                                        marginLeft: 5,
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: 400,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    System
                                                    <WhiteChevronIcon />

                                                </p>
                                            </a>
                                        </Dropdown></>}

                            </Space>

                            {!isBeforeDashboardPage ?
                                <Dropdown
                                    placement='bottomRight'
                                    overlay={
                                        <Menu>
                                            <Row style={{ padding: 15, display: "flex", alignItems: "center", marginRight: 25 }}>
                                                <Col>
                                                    <img alt="Profile icon" src={blue_profile_icon} />
                                                </Col>
                                                <Col style={{ marginLeft: '10px' }}>
                                                    <p style={{ padding: 0, margin: "0px 0px 10px 0px", font: "normal normal bold 16px Raleway" }}>{company.coName}</p>
                                                    <p style={{ padding: 0, margin: 0, font: "normal normal normal 14px Raleway" }}>{branch.branchName}</p>
                                                </Col>
                                            </Row>
                                            <Divider style={{ borderTop: "1px solid #00000050", margin: "0px 0px 10px 0px" }} />
                                            <Menu.Item
                                                key='view_profile'
                                                onClick={() => history.push('/profile')}
                                            >
                                                Profile
                                            </Menu.Item>
                                            <Menu.Item
                                                key='switch_branch'
                                                onClick={() => history.push("/selectbranch")}
                                            >
                                                Change branch
                                            </Menu.Item>
                                            <Menu.Item
                                                key='logout'
                                                onClick={async () => {
                                                    await logout();
                                                    // await removeUserRole();

                                                    window.location.pathname = "/login";
                                                }}
                                            >
                                                LOGOUT
                                            </Menu.Item>
                                        </Menu>
                                    }

                                >
                                    <a>
                                        <p
                                            style={{
                                                color: 'white',
                                                margin: 0,
                                                marginLeft: 5,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: 400,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <WhiteProfileIcon style={{ marginRight: '10px' }} />
                                            {user?.username}
                                        </p>
                                    </a>
                                </Dropdown> :
                                <Dropdown
                                    placement='bottomRight'
                                    overlay={
                                        <Menu>
                                            <Menu.Item
                                                key='logout'
                                                onClick={() => {
                                                    message.loading(`Logging out`)
                                                    logout()
                                                        .then(() => {
                                                            /**
                                                             * 9/2/2022 daniel.kwok
                                                             * Must redirect as such else will briefly
                                                             * show "Unkonwn page" page
                                                             */
                                                            removeUserRole()
                                                            window.location.pathname = '/login'
                                                        })
                                                }}
                                            >
                                                LOGOUT
                                            </Menu.Item>
                                        </Menu>
                                    }

                                >
                                    <a>
                                        <p
                                            style={{
                                                color: 'white',
                                                margin: 0,
                                                marginLeft: 5,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: 400,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <WhiteProfileIcon style={{ marginRight: '10px' }} />
                                            {user?.username}
                                        </p>
                                    </a>
                                </Dropdown>
                                // <p
                                //         style={{
                                //             color: 'white',
                                //             margin: 0,
                                //             marginLeft: 5,
                                //             whiteSpace: 'nowrap',
                                //             overflow: 'hidden',
                                //             textOverflow: 'ellipsis',
                                //             maxWidth: 400,
                                //             display: 'flex',
                                //             alignItems: 'center',
                                //         }}
                                // >
                                //     <WhiteProfileIcon style={{ marginRight: '10px' }}/>
                                //     {user?.username}
                                // </p>
                            }
                        </Header >
                    )
                }

                <Content
                    style={{
                        height: '100%',
                        backgroundColor: 'transparent',
                    }}
                >
                    <Routes />
                </Content>
                <Footer
                    style={{
                        position: 'relative',
                        // backgroundColor: 'transparent',
                        background: 'rgb(241, 241, 241)'
                    }}
                > <div>
                        <Row justify='space-between' style={{ borderTop: '1px dashed #CCCCCC' }}>
                            <Col style={{ paddingTop: '16px' }}>
                                <Space size='middle' align='center'>
                                    <p style={{ font: 'normal normal 600 14px/18px Open Sans', color: '#333333' }}>CALL OUR HELPLINE<a style={{ font: 'normal normal 600 14px/19px Open Sans', color: '#2B61C4', paddingLeft: '12px' }} href="tel:0376648181">03-7664 8181</a> or email us at <a><u>twocar@myeg.com.my</u></a></p>

                                    <p style={{ font: 'normal normal normal 14px/24px Open Sans', color: '#333333' }}>Available from <span style={{ font: 'normal normal 600 14px/18px Open Sans', color: '#333333' }}>9 AM - 5 PM</span> Monday to Friday except national public holidays.</p>

                                </Space>
                                <div>
                                    <p
                                        style={{
                                            font: 'normal normal normal 14px/24px Open Sans', color: "#999999"
                                        }}
                                    >
                                        © MY E.G. SERVICES BERHAD [200001003034 (505639-K)].

                                        <a target='_blank' href={PrivacyPolicy} style={{ marginLeft: 10, color: '#0645AD' }}>Privacy Policy</a>
                                        <a target='_blank' href={links.termsAndCondition} style={{ marginLeft: 10, color: '#0645AD' }}>Terms & Conditions</a>
                                    </p>
                                </div>
                            </Col>
                            <Col style={{ paddingTop: '16px' }}>

                                <p style={{
                                    font: 'normal normal normal 14px/24px Open Sans', color: '#CCCCCC', opacity: '1'
                                }}>{currentTime}</p>

                            </Col>
                        </Row>

                    </div>
                </Footer>
            </div>
        </Layout >
    );
}

const styles = {
    label: {
        color: 'grey', font: 'italic normal normal 16px Open Sans', marginBottom: 8,
        textAlign: 'initial'
    },
    value: {
        font: 'normal normal 600 16px Open Sans'
    }
}