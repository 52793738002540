import React, { useContext, useEffect, useState } from "react";
import {
	Form,
	Input,
	Button,
	PageHeader,
	Table,
	message,
	Select,
	Switch,
	Image,
	Breadcrumb,
} from "antd";
import { useHistory } from "react-router-dom";
import "./index.less";
import {
	activateOrDeactivateBranch,
	getCompanyOfLoggedInUser,
} from "../../services/api";
import { SessionContext } from "../../App";
import arrow_back from "../../assets/arrow_back.svg";
import { useForm } from "antd/lib/form/Form";

export default function BranchPage() {
	const { user, branchs: _branchs, company } = useContext(SessionContext);

	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [branchs, setBranchs] = useState([]);
	const [searchForm] = useForm();
	const [dataSource, setDataSource] = useState([]);

	useEffect(() => {

		if (Object.keys(company).length > 0) {

			getCompanyOfLoggedInUser(company.id).then((res) => {
				if (res.status === "00") {
					setBranchs(res.companyDetails.branchDetails || []);
				}
			});

		}
		
	}, [company]);

	// carries out when state of `branchs` changes
	useEffect(() => {
		setDataSource(
			branchs.map((branch) => {
				return {
					key: branch.id,
					...branch,
					state: branch.branchAddress.state,
				};
			})
		);
	}, [branchs]);

	const columns = [
		{
			title: "Branch ID",
			fixed: "left",
			dataIndex: "id",
			defaultSortOrder: "descend",
			sorter: (a, b) => a.id - b.id,
		},
		{
			title: "Branch Name",
			dataIndex: "branchName",
			ellipsis: true,
			width: "40%",
			sorter: (a, b) => a.branchName.localeCompare(b.branchName),
		},
		{
			title: "State",
			dataIndex: "state",
			ellipsis: true,
			sorter: (a, b) => a.state.localeCompare(b.state),
		},
		{
			title: "Branch Status",
			dataIndex: "is_active",
			sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
			render: (is_active) => {
				return is_active ? "Active" : "Inactive";
			},
		},
		{
			title: "Activate",
			sorter: (a, b) => a.is_active - b.is_active,
			render: (rowData) => {
				return (
					<>
						<Switch
							checked={rowData.is_active}
							onChange={(e) => {
								const is_active = e;

								setIsLoading(true);
								activateOrDeactivateBranch(
									rowData.id,
									company.id,
									is_active,
									user.username
								)
									.then((res) => {
										if (res.status !== true)
											throw res.message;
										const newBranchs = branchs.map((b) => {
											if (b.id === rowData.id) {
												b.is_active = is_active;
											}
											return b;
										});
										message.success(res.message);
										setBranchs(newBranchs);
									})
									.catch((err) => {
										err && message.error(err.toString());
									})
									.finally(() => setIsLoading(false));
							}}
						/>
					</>
				);
			},
		},
		{
			title: "Action",
			fixed: "right",
			render: (rowData) => {
				return (
					<Button
						style={{
							border: "1px solid #2B61C4",
							padding: "12px 24px",
							borderRadius: "4px",
							color: "#2B61C4",
							display: "flex",
							alignItems: "center",
							font: "normal normal 600 14px/19px Open Sans",
						}}
						onClick={() => {
							history.push(
								`/branchmanagement/edit/${rowData.id}`
							);
						}}
					>
						EDIT
					</Button>
				);
			},
		},
	];

	const onSubmit = () => {
		// get the search category and the search keyword user typed in through form state (useForm hook)
		const { searchBy, searchTerm } = searchForm.getFieldsValue();

		// filter the datasource to only show the data that matches the search keyword
		const filteredDataSource = branchs
			.filter((branch) =>
				branch[searchBy]
					.toString()
					.toLowerCase()
					.includes(searchTerm.toString().toLowerCase())
			)
			.map((branch) => {
				return {
					key: branch.id,
					...branch,
					state: branch.branchAddress.state,
				};
			});

		// set the filtered datasource to the state
		setDataSource(filteredDataSource);
	};

	return (
		<>
			<div className="page-content">
				<Breadcrumb separator=">" style={{ marginTop: "60px" }}>
					<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item href="/branchmanagement">
						Branch management
					</Breadcrumb.Item>
				</Breadcrumb>
				<PageHeader
					className="site-page-header"
					style={{ paddingLeft: "0px", paddingRight: "0px" }}
					// onBack={() => null}
					title="Branch Management"
					extra={[
						<Button
							className="add-new-branch-button"
							key="new_branch"
							type="primary"
							style={{ minWidth: "30%" }}
							onClick={() =>
								history.push("/branchmanagement/create")
							}
						>
							New Branch
						</Button>,
					]}
				/>
				<div
					style={{
						font: "italic normal normal 16px/22px Open Sans",
						color: "white",
						marginBottom: "7px",
					}}
				>
					Search by
				</div>
				<Form
					layout="inline"
					className="select-search-wrapper"
					style={{
						maxWidth: "65vw",
						borderRadius: 4,
						marginBottom: 25,
					}}
					initialValues={{
						searchBy: "branchName",
					}}
					form={searchForm}
				>
					<Form.Item className="form-item" name={"searchBy"}>
						<Select
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
							style={{ width: "100%" }}
						>
							<Select.Option value={"branchName"}>
								Branch Name
							</Select.Option>
							<Select.Option value={"id"}>
								Branch ID
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item className="form-item" name={"searchTerm"}>
						<Input.Search
							style={{ maxWidth: "100%" }}
							size="large"
							placeholder={"Search"}
							enterButton
							onSearch={onSubmit}
						/>
					</Form.Item>
				</Form>
				<Table
					className="report-table"
					size="small"
					columns={columns}
					dataSource={dataSource}
					loading={isLoading}
					scroll={{
						x: columns.length * 150,
					}}
					pagination={{
						showSizeChanger: false,
						showQuickJumper: false,
					}}
				/>
				<Button
					className="back-button buttons"
					shape="round"
					onClick={() => {
						history.goBack();
					}}
				>
					<Image src={arrow_back} preview={false} />
					Back
				</Button>
			</div>
			<style>
				{`                                
                .ant-select  {
                    max-width: 100% !important;
                }
                
                .ant-select > .ant-select-selector {
                    height: 48px !important;
                    display: flex !important;
                    align-items: center !important;
                }
            `}
			</style>
		</>
	);
}
