import { Breadcrumb, Button, DatePicker, Form, Image, message, PageHeader, Row, Select, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useState } from "react";
import export_xls from "../../../assets/export_xls_filled.svg";
import ReactExport from "react-export-excel";
import moment from "moment";
import { SessionContext } from "../../../App";
import { getVehicleMovementReport } from "../../../services/api";
import './index.less'
import { HQ_BRANCH } from "../../../constants";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function VehicleMovementReportPage() {
	const [searchForm] = useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [dataSource, setDataSource] = useState([]);
	const [initialDataSource, setInitialDataSource] = useState([]);
	const [page, setPage] = useState(1);

	const { company, branchs } = useContext(SessionContext);

	const dateFrom = moment(dateFrom).subtract(6, 'days')
	const dateTo = moment()

	const columns = [
		{
			title: "No",
			width: 70,
			render: (text, record, index) => (page - 1) * 10 + index + 1,
		},
		{
			title: "Vehicle Number",
			dataIndex: "vehicleNumber",
			width: 170,
			sorter: (a, b) => a.vehicleNumber.localeCompare(b.vehicleNumber),
		},
		{
			title: "Vehicle Model",
			dataIndex: "vehicleModel",
			width: 170,
		},
		{
			title: "Tx Type",
			dataIndex: "txnType",
			wdith: 100,
			render: (txnType) => {
				return txnType.toLowerCase().includes("in") ? (
					<p style={{ color: "#079B1B", margin: 0 }}>{txnType}</p>
				) : (
					<p style={{ color: "#E53B34", margin: 0 }}>{txnType}</p>
				);
			},
		},
		{
			title: "Tx Status",
			dataIndex: "txnStatus",
			width: 100,
			render: (txnStatus) => {
				return txnStatus.toString().toLowerCase().includes("approved") ? (
					<p style={{ color: "#079B1B", margin: 0 }}>{txnStatus}</p>
				) : (
					<p style={{ color: "#2B61C4", margin: 0 }}>{txnStatus}</p>
				);
			},
		},
		{
			title: "Thumb In/Out User",
			dataIndex: "thumbInUser",
			width: 200,
			ellipsis: true,
		},
		{
			title: "Creation Date",
			dataIndex: "createdDate",
			width: 150,
			render: (createdDate) => {
				let formattedDate = new Date(createdDate);
				return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
			},
			sorter: (a, b) => {
				let formattedA = moment(new Date(a.createdDate));
				let formattedB = moment(new Date(b.createdDate));

				return formattedA.diff(formattedB);
			},
		},
		{
			title: "Approved Date",
			dataIndex: "approvedDate",
			width: 150,
			render: (approvedDate) => {
				if (approvedDate != "-") {
					let formattedDate = new Date(approvedDate);
					return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
				} else return approvedDate;
			},
			sorter: (a, b) => {
				let formattedA = moment(new Date(a.approvedDate));
				let formattedB = moment(new Date(b.approvedDate));

				return formattedA.diff(formattedB);
			},
		},
		{
			title: "Location",
			// dataIndex: "location",
			// render: (location) => location ?? "-",
			dataIndex: "branchId",
			render: (branchId) => {
				let allBranches = branchs ? [...branchs, HQ_BRANCH] : [HQ_BRANCH];
				return allBranches.find((b) => b.id == branchId)?.branchName || "-";
			},
		},
	];

	const handleSearch = async () => {
		setIsLoading(true);
		const { searchBy, from } = searchForm.getFieldsValue();
		let to = searchForm.getFieldValue("to");

		if (moment(to).isBefore(from) || moment(to).isAfter(moment(from).add(6, "days"))) {
			to = moment(from).add(6, "days");
			searchForm.setFieldsValue({ to });
		}

		if (from && to) {
			getVehicleMovementReport(
				company.coRegNo,
				from.format("YYYY-MM-DD hh:mm:ss"),
				to.format("YYYY-MM-DD hh:mm:ss")
			)
				.then((response) => {
					let data = response;

					if (response.message) {
						message.error(response.message);
						data = [];
					}

					// Check if data is an array before mapping over it
					if (Array.isArray(data)) {
						let dataWithKey = data.map((item, index) => ({ key: index + 1, ...item }));

						if (searchBy !== "all") {
							const lowerSearchBy = searchBy.toLowerCase();
							dataWithKey = dataWithKey.filter((item) => {
								if (lowerSearchBy === 'apt' && item.aptStatus === 'Success') {
									return true;
								}
								return item.txnType.toLowerCase().includes(lowerSearchBy);
							});
						}

						setDataSource(dataWithKey);
					} else {
						console.log('Data is not a valid array');
					}

					setIsLoading(false);
				})
				.catch((error) => message.error(error));
		} else {
			const dataSource = searchBy === "all" ? initialDataSource : initialDataSource.filter((item) => item.txnType.toLowerCase().includes(searchBy.toLowerCase()));
			setDataSource(dataSource);
			setIsLoading(false);
		}
	};

	const exportXLSButton = () => {
		return (
			<div className="button-wrapper">
				<ExcelFile
					element={
						<Button
							shape="round"
							className="add-new-user-button export-report"
							type="primary"
							style={{ marginBottom: 10 }}
							disabled={false}
						>
							<Image src={export_xls} preview={false} width={30} height={30} style={{ paddingRight: 10 }} />
							Export report
						</Button>
					}
					filename="VehicleMovementReport"
				>
					<ExcelSheet data={dataSource} name="Vehicle Movement Report">
						<ExcelColumn label="Vehicle Number" value="vehicleNumber" />
						<ExcelColumn label="Vehicle Model" value="vehicleModel" />
						<ExcelColumn label="Tx Type" value="txnType" />
						<ExcelColumn label="Tx Status" value="txnStatus" />
						<ExcelColumn label="Thumb In/Out User" value="thumbInUser" />
						<ExcelColumn
							label="Creation Date"
							value={(col) => {
								let formattedDate = new Date(col.createdDate);
								return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
							}}
						/>
						<ExcelColumn
							label="Approved Date"
							value={(col) => {
								if (col.approvedDate != "-") {
									let formattedDate = new Date(col.approvedDate);
									return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
								} else return col.approvedDate;
							}}
						/>
						<ExcelColumn
							label="Location"
							value={(col) =>
								[...branchs, HQ_BRANCH].find((b) => b.id == col.branchId)?.branchName || "-"
							}
						/>
					</ExcelSheet>
				</ExcelFile>
			</div>
		);
	};

	useEffect(() => {
		// fetch data and setDataSource
		if (Object.keys(company).length > 0) {
			setIsLoading(true);

			getVehicleMovementReport(
				company.coRegNo,
				dateFrom.format("YYYY-MM-DD hh:mm:ss"),
				dateTo.format("YYYY-MM-DD hh:mm:ss")
			)
				.then((res) => {

					let data = res

					if (res.message) {

						message.error(res.message)
						data = []

					}

					const dataWithKey = data.map((item, index) => {
						return {
							key: index + 1,
							...item,
						};
					});
					setDataSource(dataWithKey);
					setInitialDataSource(dataWithKey);
					setIsLoading(false);
				})
				.catch((err) => err && message.error(err));
		}
	}, [company]);

	return (
		<>
			<div className="page-content">
				<Breadcrumb separator=">" style={{ marginTop: "60px" }}>
					<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item href="/reporting">Reporting</Breadcrumb.Item>
					<Breadcrumb.Item href="/reporting/vehiclemovementreport">Vehicle movement report</Breadcrumb.Item>
				</Breadcrumb>
				<PageHeader className="site-page-header" title={"Vehicle Movement Report"} />
				<Row
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "flex-end",
						marginBottom: 10,
					}}
				>
					<Form
						layout="vertical"
						className="form-container"
						style={{ borderRadius: 4, width: "65vw" }}
						initialValues={{
							searchBy: "all",
							from: dateFrom,
							to: dateTo,
						}}
						form={searchForm}
						onValuesChange={handleSearch}
					>
						<Form.Item
							className="date-wrapper"
							label="Transaction type"
							name={"searchBy"}
							style={{ width: "200px" }}
						>
							<Select
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								<Select.Option value={"all"}>All</Select.Option>
								<Select.Option value={"in"}>In</Select.Option>
								<Select.Option value={"out"}>Out</Select.Option>
								<Select.Option value={"i2i"}>i2i</Select.Option>
								<Select.Option value={"apt"}>APT</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item label="From" className="date-wrapper" name={"from"}>
							<DatePicker
								format={"DD-MMM-YYYY"}
							// onChange={(date) => {
							// 	if (
							// 		moment(searchForm.getFieldValue("to")) > moment(date).add(6, "days") ||
							// 		moment(searchForm.getFieldValue("to")) < moment(date)
							// 	) {
							// 		searchForm.setFieldsValue({
							// 			to: moment(date).add(6, "days"),
							// 		});
							// 	}
							// }}
							/>
						</Form.Item>
						<Form.Item label="To" className="date-wrapper" name={"to"}>
							<DatePicker
								format={"DD-MMM-YYYY"}
								disabledDate={(date) => {
									if (
										date > moment(searchForm.getFieldValue("from")).add(6, "days") ||
										date < searchForm.getFieldValue("from")
									) {
										return true;
									} else {
										return false;
									}
								}}
							/>
						</Form.Item>
					</Form>
					{exportXLSButton()}
				</Row>
				<Table
					className="report-table"
					dataSource={dataSource}
					columns={columns}
					loading={isLoading}
					scroll={{
						x: columns.length * 130,
					}}
					onChange={(pagination) => {
						setPage(pagination.current);
					}}
					size="small"
					pagination={{ showSizeChanger: false, showQuickJumper: false }}
				/>
				<Row style={{ display: "flex", justifyContent: "end" }}>{exportXLSButton()}</Row>
			</div>
			<style>
				{`
                .site-page-header { 
                    padding-left: 0px;
                    padding-bottom: 15px;
                }
                .search-header { 
                    font: italic normal normal 16px/22px Open Sans;
                    color: white;
                    margin-bottom: 7px;
                }
                .add-new-user-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 127px !important;	
                }
								.add-new-user-button > span { 
									font-size: 16px;
								}
                .export-report { 
                    padding-left: 30px;
                    padding-right: 30px;
                    box-shadow: none;
                }
                .date-wrapper { 
                    width: 20vw;
                    margin-right: 20px;
										margin-bottom: 0px;
                }
                .ant-select-selector { 
                    border: 1px solid #2B61C4 !important;
                    box-shadow: 0px 2px 4px #00000033 !important;
                    background: #FFFFFF 0% 0% no-repeat padding-box !important;
                    border-radius: 4px !important;
                    height: 48px !important;
                    display: flex;
                    align-items: center;
                }
                .ant-select-arrow {
                    color: #2B61C4;
                }
            `}
			</style>
		</>
	);
}
