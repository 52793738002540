import React, { useContext, useEffect, useState } from "react";
import {
	Form,
	Input,
	Button,
	PageHeader,
	Table,
	message,
	Select,
	Switch,
	Modal,
	Row,
	Col,
	Image,
	Breadcrumb,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { HQ_BRANCH } from "../../constants";
import "./index.less";
import {
	deleteBranchuser,
	activateOrDeactivateBranchUser,
	getCompanyOfLoggedInUser,
	getRolesByUsername,
	getUsersByCompanyId,
} from "../../services/api";
import { SessionContext } from "../../App";
import { STEPS } from "../BranchUserFormPage/steps";
import arrow_back from "../../assets/arrow_back.svg";
import {
	getIsAuthorizeToAccessUserManagement,
	setIsAuthorizeToAccessUserManagement,
	getUserRole
} from '../../services/local';

export default function BranchUsersPage() {
	const { users: _users, company, branch, branchs } = useContext(SessionContext);

	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [searchBranchId, setSearchBranchId] = useState("All");
	const [selectedIds, setSelectedIds] = useState([]);
	const [users, setUsers] = useState([]);
	const [branches, setBranches] = useState([]);
	const [searchForm] = Form.useForm();
	const [dataSource, setDataSource] = useState([]);
	const currentLocation = useLocation();

	const getInitialData = () => {
		if (!company?.id || parseInt(branch?.id) >= 0 == false) return null;

		setIsLoading(true);

		setBranches(branchs || [])
		// getCompanyOfLoggedInUser(company.id)
		getUsersByCompanyId(company.id)
			.then(async (res) => {
				if (res.status !== "00") throw res.message;

				// let users = []

				// for (let user_ of res.users) {

				// 	let isOwner = false

				// 	let res = await getRolesByUsername(user_.nricNo)

				// 	if (res.roles) {

				// 		for (let role of res.roles) {
				// 			if (role.name === 'OWNER') isOwner = true
				// 		}

				// 	}

				// 	let user = {
				// 		isOwner: isOwner,
				// 		...user_
				// 	}

				// 	users.push(user)
				// }

				// setUsers(users || []);
				// message.success(`${users?.length || 0}  users found`);
				setUsers(res.users || []);
				message.success(`${res.users?.length || 0}  users found`);
				// setBranchs(res?.companyDetails?.branchDetails || []);
			})
			.catch((err) => err && message.error(err))
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {

		const canAccessUsermanagement = getIsAuthorizeToAccessUserManagement()

		if (canAccessUsermanagement === 'true') {
			getInitialData();
		}
		else if (canAccessUsermanagement === 'false') {
			history.goBack()
		}

	}, [branch, company]);

	useEffect(() => {
		setDataSource(
			users.map((user) => {
				return {
					key: user.id,
					...user,
				};
			})
		);
	}, [users]);

	const onDelete = () => {
		Modal.confirm({
			title: `Confirm delete ${selectedIds.length} users?`,
			onOk: async () => {
				setIsLoading(true);
				const promises = selectedIds
					.filter((id) => id)
					.map((id) => deleteBranchuser(id));

				await Promise.allSettled(promises)
					.then((results) => {
						message.success(`Delete user(s)`);
						setSelectedIds([]);
						getInitialData();
					})
					.catch((err) => {
						err && message.error(err.toString());
					})
					.finally(() => {
						setIsLoading(false);
					});
			},
		});
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "personName",
			sorter: (a, b) => a.personName.localeCompare(b.personName),
		},
		{
			title: "Company branch",
			dataIndex: "branchId",
			ellipsis: true,
			width: "35%",
			sorter: (firstBranch, secondBranch) =>
				[...branches, HQ_BRANCH]
					.find((a) => a.id === firstBranch.branchId)
					?.branchName.localeCompare(
						[...branches, HQ_BRANCH].find(
							(b) => b.id === secondBranch.branchId
						)?.branchName
					),
			render: (branchId) => {
				return (
					[...branches, HQ_BRANCH].find((b) => b.id === branchId)
						?.branchName || `(No branch found)`
				);
			},
		},
		{
			title: "Status",
			dataIndex: "is_active",
			width: "12%",
			sorter: (a, b) => Number(b.is_active) - Number(a.is_active),
			render: (is_active) => {
				return is_active ? "Active" : "Inactive";
			},
		},
		{
			title: "Activate",
			sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
			render: (rowData) => {
				// if (rowData.is_owner === true) return
				return (
					<>
						<Switch
							checked={rowData.is_active}
							disabled={rowData.is_active ? false : true}
							onChange={(e) => {
								const is_active = e;

								setIsLoading(true);
								activateOrDeactivateBranchUser(
									is_active,
									rowData.id
								)
									.then((res) => {
										if (res.status !== true)
											throw res.message;
										const newBranchUsers = users.map(
											(b) => {
												if (b.id === rowData.id) {
													b.is_active = is_active;
												}
												return b;
											}
										);
										message.success(res.message);
										setUsers(newBranchUsers);
									})
									.catch((err) => {
										err && message.error(err.toString());
									})
									.finally(() => setIsLoading(false));
							}}
						/>
					</>
				);
			},
		},
		{
			title: "Action",
			fixed: "right",
			render: (rowData) => {
				if (rowData.is_active !== true) return
				return (
					<Button
						style={{
							border: "1px solid #2B61C4",
							padding: "12px 24px",
							borderRadius: "4px",
							color: "#2B61C4",
							display: "flex",
							alignItems: "center",
							font: "normal normal 600 14px/19px Open Sans",
						}}
						onClick={() => {
							history.push(`/usermanagement/edit`, {
								subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM,
								user: rowData
							})
						}}
					>
						EDIT{" "}
					</Button>
				);
			},
		},
	];

	const onSearch = () => {
		// get the searchBy and search term user input from the form state through useForm hook
		const { searchBy, searchTerm } = searchForm.getFieldsValue();

		// filter the user data source based on the searchBy and searchTerm
		const filteredUsers = users
			.filter((user) => {
				if (searchBy !== "branchId") {
					return user[searchBy]
						.toString()
						.toLowerCase()
						.includes(searchTerm.toString().toLowerCase());
				} else {
					return [...branches, HQ_BRANCH]
						.find((b) => b.id === user.branchId)
						?.branchName.toLowerCase()
						.includes(searchTerm.toString().toLowerCase());
				}
			})
			.map((user) => {
				return {
					key: user.id,
					...user,
				};
			});

		// alter and re-render the table based on the filtered result of filtering /searching
		setDataSource(filteredUsers);
	};

	return (
		<>
			<div className="page-content">
				<Breadcrumb separator=">" style={{ marginTop: "60px" }}>
					<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item href="/usermanagement">
						User management
					</Breadcrumb.Item>
				</Breadcrumb>
				<PageHeader
					className="site-page-header"
					style={{ paddingLeft: "0px" }}
					title="User Management"
				/>
				<Row>
					<Col span={24}>
						<div
							style={{
								font: "italic normal normal 16px/22px Open Sans",
								color: "white",
								marginBottom: "7px",
							}}
						>
							Search by
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginBottom: "25px",
							}}
						>
							<Form
								layout="inline"
								className="select-search-wrapper"
								style={{ minWidth: "65vw", borderRadius: 4 }}
								initialValues={{
									searchBy: "personName",
								}}
								form={searchForm}
							>
								<Form.Item
									className="form-item"
									name={"searchBy"}
								>
									<Select
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >=
											0
										}
										value={searchBranchId}
										style={{ width: "100%", maxWidth: 200 }}
									>
										<Select.Option value={"personName"}>
											Name
										</Select.Option>
										<Select.Option value={"branchId"}>
											Company Name
										</Select.Option>
									</Select>
								</Form.Item>
								<Form.Item
									className="form-item"
									name={"searchTerm"}
								>
									<Input.Search
										style={{ maxWidth: "100%" }}
										size="large"
										placeholder={"Search"}
										enterButton
										onSearch={onSearch}
									/>
								</Form.Item>
							</Form>
							<Button
								className="add-new-user-button"
								type="primary"
								onClick={() => window.location.pathname = "/usermanagement/create"}
							>
								Add Staff
							</Button>
						</div>
					</Col>
				</Row>
				<Table
					className="report-table"
					size="small"
					columns={columns}
					dataSource={dataSource}
					loading={isLoading}
					scroll={{
						x: columns.length * 150,
					}}
					// rowSelection={{
					// 	type: "checkbox",
					// 	onChange: (selectedRowKeys) => {
					// 		setSelectedIds(selectedRowKeys);
					// 	},
					// }}
					pagination={{
						showSizeChanger: false,
						showQuickJumper: false,
					}}
					footer={() => {
						// return (
						// 	selectedIds.length > 0 && (
						// 		<Button
						// 			className="delete-button buttons"
						// 			icon={
						// 				<CloseOutlined
						// 					style={{ fontSize: 16 }}
						// 				/>
						// 			}
						// 			shape="round"
						// 			disabled={!selectedIds.length}
						// 			onClick={onDelete}
						// 			type="danger"
						// 		>
						// 			Delete Accounts
						// 		</Button>
						// 	)
						// );
					}}
				/>
				<Button
					className="back-button buttons"
					shape="round"
					onClick={() => {
						history.push('/')
					}}
				>
					<Image src={arrow_back} preview={false} />
					BACK
				</Button>
			</div>
			<style>
				{`                                
                .ant-select  {
                    max-width: 100% !important;
                }
                
                .ant-select > .ant-select-selector {
                    height: 48px !important;
                    display: flex !important;
                    align-items: center !important;
                }
            `}
			</style>
		</>
	);
}
