import {
    ReadOutlined,
    DownloadOutlined,
    EditOutlined,
    PrinterOutlined
} from '@ant-design/icons'
import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    Image,
    Input,
    message,
    PageHeader,
    Row,
    Select,
    Table,
    Tag,
    Tooltip,
    Checkbox 
} from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { SessionContext } from '../../../App'
import {
    APT_TRANSACTION_STATUSES_LABELS,
    APT_TRANSACTION_STATUSES_COLORS,
    IN_TRANSACTION_STATUSES,
    IN_TRANSACTION_STATUSES_COLORS,
    IN_TRANSACTION_STATUSES_LABELS,
    OUT_TRANSACTION_STATUSES,
    OUT_TRANSACTION_STATUSES_COLORS,
    OUT_TRANSACTION_STATUSES_LABELS,
    OUT_TRANSACTION_TYPES, OUT_TRANSACTION_TYPES_IDS,
    APT_TRANSACTION_STATUSES,
    SEARCH_TYPES,
    NRIC_TYPES,
    SEARCH_QUERY_TOOLTIP,
    M2M_SEARCH_TYPES
} from '../../../constants'
import {
    getAptTransactionCount,
    getAptTransactionList,
    geti2iTransactionCount,
    geti2iTransactionList,
    getInTransactionCount,
    getInTransactionList,
    getOutTransactionCount,
    getOutTransactionList,
    getReceiptAPT,
    getPendingi2iTransactionCount,
    getPendingi2iTransactionList,
    getPendingInTransactionCount,
    getPendingInTransactionList,
    getInTxnByComRocVechNoStatus,
    getAptTxnByComRocVechNoStatus,
    getOutTxnByComRocVechNoStatus,
    getPendingOutTransactionCount,
    getPendingOutTransactionList,
    getI2iTxnByComRocVechNoStatus,
    searchInTransactions,
    searchAptTransactions,
    searchI2iTransactions,
    searchOutTransactions,
    searchM2mTransactions,
    m2mProceedBulk,
    verifyApproverSmsOtp,
    getV2ReceiptAPT,
} from '../../../services/api'
import { parseTime } from '../../../util'
import './index.less'
import arrow_back from '../../../assets/arrow_back.svg'
import export_xls from '../../../assets/export_xls.svg'
import ReactExport from "react-export-excel";
import {
    setCachedReportType,
    removeCachedReportType,
    getCachedReportType,
    getCompanyId,
    getUserRole,
} from '../../../services/local'
import { validateTransactionVersion, onApprovalM2mBulkSubmit, useShortcut,
    validateRolePermission,
} from '../../../components/Utils'
import ApproverAuthModal from '../../Member2MemberPage/jpjSubmission_modal'
import { getDecryptedVerifyOtpResponse } from '../../../util/index'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const REPORT_TYPE_LABELS = {
    ALL: 'All',
    IN: 'In',
    APT: 'APT',
    OUT: 'Out',
    I2I: 'i2i',
    M2M: 'm2m',

}

export default function TransferReportPage() {

    const { pendingType, isbulk } = useParams()

    const history = useHistory()
    const { company, branch } = useContext(SessionContext)
    const [isLoading, setIsLoading] = useState(false)
    const [prevReportType, setPrevReportType] = useState(REPORT_TYPE_LABELS.IN)
    // const [reportType, setReportType] = useState(pendingType ?? REPORT_TYPE_LABELS.IN)
    const [reportType, setReportType] = useState(() => {
        const cachedReportType = getCachedReportType();
        if (pendingType) return pendingType
        if (cachedReportType) return cachedReportType
        else return REPORT_TYPE_LABELS.IN
    });

    const [transfers, setTransfers] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [initialDataSource, setInitialDataSource] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, showSizeChanger: false, showQuickJumper: false });
    const [sortBy, setSortBy] = useState('1D')
    const [status, setStatus] = useState(pendingType ? 'Pending' : 'All')
    const [isSearchDataExist, setIsSearchDataExist] = useState(false)
    const [searchType, setSearchType] = useState(SEARCH_TYPES.VEHICLE_NUMBER.key)
    const [nricType, setNricType] = useState(NRIC_TYPES.SELLER.key)
    const [searchForm] = Form.useForm();
    const [filterByStatusform] = Form.useForm();
	const [selectedKeys, setSelectedKeys] = useState([]);
    const [isM2mAdminUser, setIsM2mAdminUser] = useState(false)
	const [allChecked, setAllChecked] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isIncorrectOtp, setIsIncorrectOtp] = useState(false)
    const [isM2mBulkInitial, setIsM2mBulkInitial] = useState(true)
    const [isBulkLoading, setIsBulkLoading] = useState(false)
    const [loggedInUserRole, setLoggedInUserRole] = useState('')
    const [isM2MSelect, setIsM2MSelect] = useState(false)
    {useShortcut(setIsModalVisible)}

    const ReceiptType = {
        vehicleDealerCopy : '1',
        confirmationSlip : '2',
        dealerCopy : '3',
    }

    useEffect(() => {
        getUserRole()
            .then(res => {
                setLoggedInUserRole(res)
            })
    }, [])

    useEffect(() => {
        if (Object.keys(branch).length > 0) {
            // Fetch data from the server based on the new pagination settings
            getData(pagination.current, isSearchDataExist);
        }
    }, [sortBy])

    useEffect(() => {
        if (!pendingType)
            setCachedReportType(reportType)
    }, [reportType, pendingType])

    useEffect(() => {
        if (Object.keys(branch).length > 0) {
            getData(1)
        }
    }, [company, reportType, branch])

    useEffect(() => {

        const datas = transfers
            .map(t => {
                return {
                    key: t.id,
                    ...t,
                }
            })

        setDataSource(datas)
        setInitialDataSource(datas)

    }, [transfers])


    const filterSearchBy = async () => {

        getData(1, true)

    };

    const getData = async (currentPage, isSearch) => {

        if (!company?.coRegNo) return null;

        setIsLoading(true);

        try {
            const res = await report.api(company.coRegNo, currentPage, isSearch);

            if (res) {

                setTransfers(res);
            } else {
                setTransfers([]);
                setPagination(prevPagination => ({
                    ...prevPagination,
                    total: 0,
                }));
            }

        } catch (err) {
            setIsSearchDataExist(false);
            message.error(err.toString());
            setPagination(prevPagination => ({
                ...prevPagination,
                total: 0,
            }));
        } finally {
            setIsLoading(false);
            setPrevReportType(reportType);
        }
    };

    const handleTableChange = (pagination_, filters, sorter) => {
        const sortMap = {
            'createdDate-ascend': '1A',
            'createdDate-descend': '1D',
            'carRegistrationNo-ascend': '2A',
            'carRegistrationNo-descend': '2D',
        };

        const sortKey = sorter.field + '-' + sorter.order;
        const newSortBy = sortMap[sortKey] || null;
        if (newSortBy) setSortBy(newSortBy);

        const isUserClickPagination = pagination_.current !== pagination.current;
        setPagination(pagination_);

        if (Object.keys(branch).length > 0 && isUserClickPagination) {
            getData(pagination_.current, isSearchDataExist);
        }
    };

    const footerButtons = () => {
        return (
            <>
                <ExcelFile
                    element={
                        <Button
                            className="delete-button buttons footer-button"
                            icon={<DownloadOutlined style={{ fontSize: 16 }} />}
                            shape="round"
                            disabled
                            onClick={() => { }}
                        >
                            Download
                        </Button>
                    }
                    filename="TransferReport"
                >
                    <ExcelSheet data={dataSource} name="Transfer Report">
                        {REPORT_TYPES[reportType].columns.map((column) => {
                            return (
                                <ExcelColumn
                                    label={column.title}
                                    value={
                                        column.dataIndex == "createdDate"
                                            ? (value) => moment(value).format("DD/MM/YYYY hh:mm:ss")
                                            : column.dataIndex
                                    }
                                />
                            );
                        })}
                    </ExcelSheet>
                </ExcelFile>
                <ExcelFile
                    element={
                        <Button
                            className="delete-button buttons footer-button"
                            shape="round"
                            disabled
                            onClick={() => { }}
                        >
                            <Image
                                src={export_xls}
                                preview={false}
                                width={30}
                                height={25}
                                style={{ paddingRight: 10 }}
                            />
                            Export
                        </Button>
                    }
                    filename="TransferReport"
                >
                    <ExcelSheet
                        data={dataSource.filter((item) => selectedRowKeys.includes(item.key))}
                        name="Transfer Report"
                    >
                        {REPORT_TYPES[reportType].columns.map((column) => {
                            return (
                                <ExcelColumn
                                    label={column.title}
                                    value={
                                        column.dataIndex == "createdDate"
                                            ? (value) => moment(value).format("DD/MM/YYYY hh:mm:ss")
                                            : column.dataIndex
                                    }
                                />
                            );
                        })}
                    </ExcelSheet>
                </ExcelFile>
            </>
        );
    }

    const TransactionTypeDropdown = () => {
        return (
            <Col span={12}>
                <Form layout="vertical">
                    <Form.Item label="Transaction Type" className="date-wrapper">
                        <Select
                            value={reportType}
                            onChange={async (search) => {
                                searchForm.resetFields([
                                    'transactionStatus',
                                    'searchType',
                                    'nricType',
                                    'createdDate',
                                    'searchTerm'
                                ]);
                                setReportType(search);
                                setSearchType(SEARCH_TYPES.VEHICLE_NUMBER.key)
                            }}
                        >
                            {Object.keys(REPORT_TYPES).map((key) => {
                                // Use a conditional return to skip the option if the key is 'OUT'
                                if (
                                    // key !== REPORT_TYPE_LABELS.OUT &&
                                    key !== REPORT_TYPE_LABELS.M2M &&
                                    key !== REPORT_TYPE_LABELS.ALL) {
                                    return <Select.Option key={key}>{REPORT_TYPES[key].label.toLocaleUpperCase()}</Select.Option>;
                                }
                                return null; // Do not render anything for 'OUT'
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Col>
        );
    }

    const onChangeOutTransactionType = () => {
        // const { outTransactionType } = searchForm.getFieldsValue();
        getData(1, false)
    }

    const InputSearchBy = (props) => {
        const { style } = props
        return (
            <Col span={12}>
                <div
                    style={{
                        font: "italic normal normal 16px/22px Open Sans",
                        color: "white",
                        marginBottom: "7px",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    Search
                </div>
                <Form
                    className='select-search-wrapper'
                    layout="inline"
                    initialValues={{
                        searchType: SEARCH_TYPES.VEHICLE_NUMBER.key,
                        nricType: NRIC_TYPES.SELLER.key,
                        transactionType: REPORT_TYPE_LABELS.IN,
                        searchTerm: "",
                        outTransactionType: "MYKAD",
                    }}
                    form={searchForm}
                    onFinish={filterSearchBy}
                    style={style}
                >
                    {
                        reportType === REPORT_TYPE_LABELS.OUT && pendingType ?
                            <>
                                <Form.Item
                                className="form-item"
                                name="outTransactionType"
                                // initialValue={"MYKAD"}
                                >
                                <Select
                                onChange={onChangeOutTransactionType}>
                                    return (
                                    <>
                                    <Select.Option value={"MYKAD"}>
                                        {"MYKAD"}
                                    </Select.Option>
                                    <Select.Option value={"M2M"}>
                                        {"M2M"}
                                    </Select.Option>
                                    </>
                                    );
                                </Select>
                                </Form.Item>
                            </>
                            :<></>
                    }

                    {
                        pendingType &&

                        <Form.Item
                            className="form-item"
                            name="transactionStatus"
                            initialValue={pendingType ? 'Pending' : 'All'}
                        >
                            <Select
                                style={{ width: "100%", maxWidth: 200 }}
                            >
                                <Select.Option key={pendingType ? 'Pending' : 'All'} value={pendingType ? 'Pending' : 'All'}>{pendingType ? 'All Pending Status' : 'All Status'}</Select.Option>
                                {
                                    reportType === REPORT_TYPE_LABELS.IN &&
                                    Object.keys(IN_TRANSACTION_STATUSES).map((key) => {
                                        if (pendingType === REPORT_TYPE_LABELS.IN && key === 'TRANSACTION_SUCCESS') {
                                            return null; // Exclude the TRANSACTION_SUCCESS option
                                        }
                                        return (
                                            <Select.Option key={key} value={IN_TRANSACTION_STATUSES[key]}>
                                                {key}
                                            </Select.Option>
                                        );
                                    })
                                }


                                {
                                    reportType === REPORT_TYPE_LABELS.APT &&
                                    Object.keys(APT_TRANSACTION_STATUSES).map((key) => {
                                        if (pendingType === REPORT_TYPE_LABELS.APT && key === 'SUCCESS') {
                                            return null; // Exclude the TRANSACTION_SUCCESS option
                                        }
                                        return (

                                            <Select.Option key={key} value={APT_TRANSACTION_STATUSES[key]}>
                                                {key}
                                            </Select.Option>
                                        )
                                    })
                                }

                                {
                                    (reportType === REPORT_TYPE_LABELS.OUT ||
                                        reportType === REPORT_TYPE_LABELS.I2I) && (
                                        Object.keys(OUT_TRANSACTION_STATUSES).map((key) => {
                                            if (pendingType === REPORT_TYPE_LABELS.OUT || pendingType === REPORT_TYPE_LABELS.I2I && key === 'SUCCESS') {
                                                return null; // Exclude the TRANSACTION_SUCCESS option
                                            }
                                            return (<Select.Option key={key} value={OUT_TRANSACTION_STATUSES[key]}>
                                                {key}
                                            </Select.Option>)
                                        })
                                    )
                                }

                            </Select>
                        </Form.Item>
                    }

                    <Form.Item className="form-item" name={"searchType"} style={{ width: "160px" }}>
                        <Select
                            value={searchType}
                            onChange={value => {
                                searchForm.resetFields([
                                    'nricType',
                                    'createdDate',
                                    'searchTerm'
                                ]);
                                setSearchType(value)
                            }
                            }
                        >
                            {
                            !isM2MSelect ?
                            Object.values(SEARCH_TYPES).map(searchType => {
                                // Check if reportType is 'IN' and the searchType is ROC_NUMBER
                                if (reportType === REPORT_TYPE_LABELS.IN && searchType.key === 'rocnumber') {
                                    return null; // Skip rendering this option
                                }

                                return (
                                    <Select.Option key={searchType.key}>
                                        {searchType.label}
                                    </Select.Option>
                                );
                            }) :

                            Object.values(M2M_SEARCH_TYPES).map(searchType => {
                                return (
                                    <Select.Option key={searchType.key}>
                                        {searchType.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>



                    {
                        searchType === SEARCH_TYPES.NRIC.key &&

                        <Form.Item className="form-item" name={"nricType"} style={{ width: "120px" }}>
                            <Select
                                style={{ width: "100%", maxWidth: 200 }}
                                value={nricType}
                                onChange={value => setNricType(value)}
                            >
                                {Object.values(NRIC_TYPES).map(nricType => (
                                    <Select.Option key={nricType.key}>
                                        {nricType.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }

                    {
                        searchType === SEARCH_TYPES.TRANSACTION_DATE.key &&

                        <Form.Item name={"createdDate"} className='date-wrapper' style={{ width: "200px" }}>
                            <DatePicker onChange={filterSearchBy} format={'YYYY-MM-DD'} />
                        </Form.Item>

                    }

                    {
                        searchType !== SEARCH_TYPES.TRANSACTION_DATE.key && searchType!== SEARCH_TYPES.VEHICLE_NUMBER.key &&

                        <Form.Item className="date-wrapper" name={"searchTerm"} style={{ width: "200px" }}>
                            <Input.Search
                                allowClear
                                style={{ maxWidth: "100%" }}
                                placeholder={"Search"}
                                enterButton
                                onSearch={filterSearchBy}
                                onChange={e => {
                                    searchForm.setFieldsValue({
                                        searchTerm: e.target.value.toUpperCase()
                                    })
                                }}
                            />
                        </Form.Item>

                    }

                    {
                        searchType === SEARCH_TYPES.VEHICLE_NUMBER.key &&

                        <Form.Item className="date-wrapper" name={"searchTerm"} style={{ width: "200px" }}>
                            <Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
                                <Input.Search
                                    allowClear
                                    style={{ maxWidth: "100%" }}
                                    placeholder={"Search"}
                                    enterButton
                                    onSearch={filterSearchBy}
                                    onChange={e => {
                                        searchForm.setFieldsValue({
                                            searchTerm: e.target.value.toUpperCase()
                                        })
                                    }}
                                />
                            </Tooltip>
                        </Form.Item>

                    }

                </Form>
            </Col>
        );
    }

	function validateAndTrimSearchQuery(searchTerm) {
		if (typeof searchTerm === 'undefined' || searchTerm === undefined) {
			searchTerm = ""; // Assign empty string if searchTerm is undefined
		}
        return searchTerm.replace(/\s/g, "");
	}

    const proceedM2mBulk = async (appApprovalResponse) => {
        let bulkTransferId = appApprovalResponse && appApprovalResponse.bulkTransferId ? appApprovalResponse.bulkTransferId : null;
        const saveBuyerPayload = {
            transferIdList: bulkTransferId == null ? selectedRowKeys : [],
            uId: "",
            bulkTransferId : bulkTransferId ?? null,
            companyId: getCompanyId()
        }

        setIsLoading(true)
        const m2mProceedRes = await m2mProceedBulk(saveBuyerPayload)
        setIsIncorrectOtp(true) //reset otp
        if (m2mProceedRes.inquiryStatus !== '00') {
            message.error("Inquiry Failed found.")
        } else {
            message.success("APPROVED")
        }
        getData(pagination.current, false)
    }

    const bulkSaveButtons = () => {
        return (
            <>
            {/* {console.log(selectedRowKeys)} */}
            <Button
                className='delete-button buttons footer-button'
                shape="round"
                disabled={!isM2mAdminUser}
                onClick={() => {
                    // onApprovalM2mBulkSubmit(setIsModalVisible, proceedM2mBulk, company)
                    setIsModalVisible(true)
                    // proceedM2mBulk()
                }}>
                Seller Approval & JPJ Submission
            </Button>
            </>
        );
    }

    /**
     * ------- FOR ALL REPORT TYPES -------
     * Get all transaction count first, then throw the count into "get-transaction-list".
     * This is because filter by date and vehicle number does not account for pagination feature
     * Therefore, we get all the transactions in order to make filter by date and vehicle number work normally
     * ------- END -------
     */
    const REPORT_TYPES = {
        [REPORT_TYPE_LABELS.IN]: {
            label: REPORT_TYPE_LABELS.IN,
            api: async (dealerCompanyROC, currentPage, isSearch) => {
                // if (isSearch) {

                const {
                    searchType,
                    searchTerm,
                    nricType,
                    createdDate,
                    transactionStatus
                } = searchForm.getFieldsValue();

                let search = isSearch === true ? searchType : ''
                let searchParam = ''
                let status = pendingType ? transactionStatus : 1

                if (search === SEARCH_TYPES.NRIC.key) search = nricType

                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key
                    || search === SEARCH_TYPES.ROC_NUMBER.key
                    || search === NRIC_TYPES.BUYER.key
                    || search === NRIC_TYPES.SELLER.key) {

                    // if (!searchTerm.trim()) {
                    //     setPagination(prevPagination => ({
                    //         ...prevPagination,
                    //         total: 0,
                    //     }));
                    //     return
                    // }

                    searchParam = searchTerm

                }

                if (search === SEARCH_TYPES.TRANSACTION_DATE.key) {

                    if (!createdDate) {
                        setPagination(prevPagination => ({
                            ...prevPagination,
                            total: 0,
                        }));
                        return
                    }

                    searchParam = moment(createdDate).format('YYYY-MM-DD')
                }

                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                    searchParam = validateAndTrimSearchQuery(searchParam);
                }

                try {
                    let res = await searchInTransactions(
                        branch.id,
                        dealerCompanyROC,
                        pagination.pageSize,
                        currentPage,
                        search,
                        searchParam,
                        sortBy,
                        status
                    );

                    if (Array.isArray(res.data)) {
                        setIsSearchDataExist(isSearch)
                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));
                    }
                    return res.data;

                } catch (err) {
                    throw err;
                }

                // } else {

                //     const transactionCountFunc = pendingType === REPORT_TYPE_LABELS.IN ? getPendingInTransactionCount : getInTransactionCount;
                //     const transactionListFunc = pendingType === REPORT_TYPE_LABELS.IN ? getPendingInTransactionList : getInTransactionList;

                //     const count = await transactionCountFunc(dealerCompanyROC);
                //     setPagination(prevPagination => ({
                //         ...prevPagination,
                //         total: count.count,
                //         current: currentPage
                //     }));

                //     setIsSearchDataExist(false)

                //     return await transactionListFunc(dealerCompanyROC, pagination.pageSize, currentPage, sortBy, status);
                // }

            },
            columns: [
                {
                    title: 'Created Date',
                    dataIndex: 'createdDate',
                    render: createdDate => parseTime(moment(createdDate).valueOf()),
                    sorter: isSearchDataExist ? (a, b) => moment(a.createdDate).valueOf() - moment(b.createdDate).valueOf() : (a, b) => { },
                    defaultSortOrder: 'descend',
                },
                {
                    title: 'Vehicle Number',
                    dataIndex: 'carRegistrationNo',
                    sorter: isSearchDataExist ? (a, b) => a.carRegistrationNo.localeCompare(b.carRegistrationNo) : (a, b) => { },
                },
                {
                    title: 'Seller Name',
                    dataIndex: 'sellerName',
                    // sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
                },
                {
                    title: 'Transaction Type',
                    render: (record) => {
                        if (record.isM2mTransaction) {
                            return 'M2M'
                        } else {
                            return 'IN'
                        }
                    },
                },
                {
                    title: 'Engine Number',
                    dataIndex: 'engineNo',
                    // sorter: (a, b) => a.buyerNRIC.localeCompare(b.buyerNRIC),
                    render: (engineNo) => {
                        return engineNo ? engineNo : "-"
                    }
                },
                {
                    title: 'Chasis Number',
                    dataIndex: 'chassisNo',
                    // sorter: (a, b) => a.buyerNRIC.localeCompare(b.buyerNRIC),
                    render: (chassisNo) => {
                        return chassisNo ? chassisNo : "-"
                    }
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    render: status => <Tag
                        color={IN_TRANSACTION_STATUSES_COLORS[status]}
                    >{IN_TRANSACTION_STATUSES_LABELS[status]}</Tag>,
                    // sorter: (a, b) => a.status - b.status,
                },
                {
                    title: 'JPJ Response Message',
                    dataIndex: 'failCode',
                    width: 300,
                    // sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
                    render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
                },
                // {
                //     title: 'JPJ Response Message',
                //     dataIndex: 'failCode',
                //     // sorter: (a, b) => a.failCode.localeCompare(b.failCode),
                //     render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
                // },
                {
                    title: 'Action',
                    fixed: 'right',
                    render: (rowData) => {
                        let url = ''
                        let showEditButton = false

                        if (rowData.status === IN_TRANSACTION_STATUSES.FAILED) showEditButton = true

                        if (rowData.status === IN_TRANSACTION_STATUSES.PENDING_APPROVAL) {
                            url += '/transfer/in/payment'
                        } else if (rowData.status === IN_TRANSACTION_STATUSES.TRANSACTION_SUCCESS) {
                            url += '/transfer/in/printslip'
                        } else {
                            if (pendingType) url += '/transfer/in/create'
                            else url += '/transfer/in/payment'
                        }

                        return (
                            <div>
                                <Button
                                    style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                    onClick={() => {
                                        if (!pendingType || (pendingType && validateTransactionVersion(rowData))) {
                                            history.push(`${url}/${rowData.id}`, {
                                                isM2M: rowData.isM2mTransaction ?? false,
                                                isReport: pendingType ? false : true,
                                                oldIsReport: true, // to avoid conflict with what's already implemented, keep the old isReport
                                                fromPendingPage: pendingType ? true : false,
                                                jpjErrorMsg: rowData.failCode
                                            })
                                        }
                                    }}
                                >
                                    {!pendingType ? (
                                        <>
                                            <ReadOutlined />
                                            <span className='img-btn-text'>VIEW</span>
                                        </>
                                    ) : (
                                        <>
                                            <EditOutlined />
                                            <span className='img-btn-text'>REVIEW</span>
                                        </>
                                    )}
                                </Button>
                                {!pendingType ? (
                                    <Button
                                        style={{
                                            border: "1px solid #2B61C4",
                                            color: "#2B61C4",
                                            borderRadius: "4px",
                                        }}
                                        onClick={() => {
                                            history.push(`/reporting/audittraillog/in/${rowData.id}`);
                                        }}
                                    >
                                        <ReadOutlined />
                                        AUDIT TRAIL LOG
                                    </Button>
                                ) : null}

                                {
                                    pendingType && showEditButton == true ?
                                        (
                                            <Button
                                                style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                                onClick={() => {
                                                    if (validateTransactionVersion(rowData)) {
                                                        history.push(`/transfer/in/vehicleinfo/${rowData.id}`)
                                                    }
                                                }}
                                            >
                                                <>
                                                    <EditOutlined />
                                                    <span className='img-btn-text'>EDIT</span>
                                                </>
                                            </Button>
                                        ) : null
                                }


                            </div>
                        );
                    }
                }
            ]

        },
        [REPORT_TYPE_LABELS.OUT]: {
            label: REPORT_TYPE_LABELS.OUT,
            api: async (dealerCompanyROC, currentPage, isSearch) => {

                // if (isSearch) {

                const {
                    searchType,
                    searchTerm,
                    nricType,
                    createdDate,
                    transactionStatus,
                    outTransactionType
                } = searchForm.getFieldsValue();

                if (outTransactionType === "M2M") {
                    setIsM2MSelect(true)
                }
                else {
                    setIsM2MSelect(false)
                }

                let search = isSearch === true ? searchType : ''
                let searchParam = ''
                let status = pendingType ? transactionStatus : 4//status success

                if (search === SEARCH_TYPES.NRIC.key) search = nricType

                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key
                    || search === SEARCH_TYPES.ROC_NUMBER.key
                    || search === NRIC_TYPES.BUYER.key
                    || search === NRIC_TYPES.SELLER.key
                    || search === M2M_SEARCH_TYPES.VEHICLE_NUMBER.key
                    || search === M2M_SEARCH_TYPES.BUYER_NAME.key
                    || search === M2M_SEARCH_TYPES.SELLER_NAME.key) {

                    // if (!searchTerm.trim()) {
                    //     setPagination(prevPagination => ({
                    //         ...prevPagination,
                    //         total: 0,
                    //     }));
                    //     return
                    // }

                    searchParam = searchTerm

                }

                if (search === SEARCH_TYPES.TRANSACTION_DATE.key) {

                    if (!createdDate) {
                        setPagination(prevPagination => ({
                            ...prevPagination,
                            total: 0,
                        }));
                        return
                    }

                    searchParam = moment(createdDate).format('YYYY-MM-DD')
                }

                //WIP - OUT option currently not in transaction report
                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                    searchParam = validateAndTrimSearchQuery(searchParam);
                }

                if (searchParam === "") {
                    search = ""
                }

                try {
                    let res ;
                    if (outTransactionType === "M2M") {
                        res = await searchM2mTransactions(
                            branch.id,
                            dealerCompanyROC,
                            pagination.pageSize,
                            currentPage,
                            search,
                            searchParam,
                            sortBy,
                            status
                        );
                    }
                    else {
                        res = await searchOutTransactions(
                            branch.id,
                            dealerCompanyROC,
                            pagination.pageSize,
                            currentPage,
                            search,
                            searchParam,
                            sortBy,
                            status
                        );
                    }

                    if (Array.isArray(res.data)) {
                        setIsSearchDataExist(isSearch)
                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));
                    }
                    return res.data;


                } catch (err) {
                    throw err;
                }

                // } else {

                //     const transactionCountFunc = pendingType === REPORT_TYPE_LABELS.OUT ? getPendingOutTransactionCount : getOutTransactionCount;
                //     const transactionListFunc = pendingType === REPORT_TYPE_LABELS.OUT ? getPendingOutTransactionList : getOutTransactionList;

                //     const count = await transactionCountFunc(branch.id, dealerCompanyROC);
                //     setPagination(prevPagination => ({
                //         ...prevPagination,
                //         total: count.count,
                //         current: currentPage
                //     }));

                //     const list = await transactionListFunc(branch.id, dealerCompanyROC, pagination.pageSize, currentPage, sortBy, status);

                //     setIsSearchDataExist(false)

                //     return list.map(result => ({
                //         ...result,
                //         transactionTypeLabel: OUT_TRANSACTION_TYPES[result.transactionType]?.label || 'Unknown transaction type',
                //     }));
                // }
            },
            columns: [
                {
                    title: 'Created Date',
                    dataIndex: 'createdDate',
                    render: createdDate => parseTime(moment(createdDate).valueOf()),
                    sorter: isSearchDataExist ? (a, b) => moment(a.createdDate).valueOf() - moment(b.createdDate).valueOf() : (a, b) => { },
                    defaultSortOrder: 'descend',
                },
                {
                    title: 'Vehicle Number',
                    dataIndex: 'carRegistrationNo',
                    sorter: isSearchDataExist ? (a, b) => a.carRegistrationNo.localeCompare(b.carRegistrationNo) : (a, b) => { },
                },
                {
                    title: 'Seller Name',
                    dataIndex: 'sellerName',
                    // sorter: (a, b) => a.buyerName.localeCompare(b.buyerName),
                },
                {
                    title: 'Buyer Name',
                    dataIndex: 'buyerName',
                    // sorter: (a, b) => a.buyerName.localeCompare(b.buyerName),
                },
                {
                    title: 'Engine Number',
                    dataIndex: 'engineNo',
                    // sorter: (a, b) => a.buyerNRIC.localeCompare(b.buyerNRIC),
                    render: (engineNo) => {
                        return engineNo ? engineNo : "-"
                    }
                },
                {
                    title: 'Chasis Number',
                    dataIndex: 'chassisNo',
                    // sorter: (a, b) => a.buyerNRIC.localeCompare(b.buyerNRIC),
                    render: (chassisNo) => {
                        return chassisNo ? chassisNo : "-"
                    }
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    width: 300,
                    render: (status, rowData) => {
                        if (rowData.isM2mTransaction && rowData.sellerInquirySuccess !== null) {
                        return <Tag
                            color={"blue"}
                        >{"Pending Seller Approval"}</Tag>
                        }
                        else if (rowData.isM2mTransaction && rowData.failCode !== "") {
                            return <Tag
                            color={"red"}
                        >{"Failed"}</Tag>
                        }
                        else if (rowData.isM2mTransaction) {
                            return <Tag
                            color={"blue"}
                        >{"Pending JPJ Submission"}</Tag>
                        }
                        else {
                            return <Tag
                        color={OUT_TRANSACTION_STATUSES_COLORS[status]}
                    >{OUT_TRANSACTION_STATUSES_LABELS[status]}</Tag>
                        }
                    }
                   /*  render: status => <Tag
                        color={OUT_TRANSACTION_STATUSES_COLORS[status]}
                    >{OUT_TRANSACTION_STATUSES_LABELS[status]}</Tag>, */
                    // sorter: (a, b) => a.status - b.status,
                },
                {
                    title: 'JPJ Response Message',
                    dataIndex: 'failCode',
                    width: 300,
                    // sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
                    render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
                },
                {
                    title: 'Day Remaining',
                    dataIndex: 'dayRemaining',
                    // sorter: (a, b) => a.dayRemaining - b.dayRemaining,
                    render: (dayRemaining, rowData) => {
                        if (rowData.status === 4) {
                            return "";
                        }

                        if (dayRemaining > 1) {
                            return `${dayRemaining} days`;
                        } else if (dayRemaining > 0) {
                            return `${dayRemaining} day`;
                        } else if (rowData.isM2mTransaction){
                            return "N/A"
                        } else {
                            return "Expired";
                        }
                    }
                },
                // {
                //     title: 'JPJ Response Message',
                //     dataIndex: 'failCode',
                //     // sorter: (a, b) => a.failCode.localeCompare(b.failCode),
                //     render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
                // },
                {
                    title: 'Stage',
                    dataIndex: 'stage',
                    render: (stage, rowData) => {
                        // if (rowData.isM2mTransaction) {
                        //     return "Pending Approval"
                        // }
                        // else {
                            return stage
                        // }
                    }
                    // sorter: (a, b) => a.stage.localeCompare(b.stage),
                },
                {
                    title: 'Action',
                    fixed: 'right',
                    render: (rowData) => {

                        let url = ''
                        let serviceId = ''

                        let allowedStatusToEdit = []

                        Object.values(OUT_TRANSACTION_STATUSES).filter(value => {
                            // Exclude values 1, 3, 4, 6, 7, 8
                            if (![
                                OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS,
                                OUT_TRANSACTION_STATUSES.PENDING_PAYMENT,
                                OUT_TRANSACTION_STATUSES.SUCCESS,
                                OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED,
                                OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED,
                                OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL
                            ].includes(value)) {
                                allowedStatusToEdit.push(value);
                            }
                        })

                        if (rowData.transactionTypeLabel
                            &&
                            rowData.transactionTypeLabel == OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.D2I].label
                            ||
                            rowData.transactionTypeLabel == OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.D2C].label) {
                            allowedStatusToEdit.push(
                                // OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED,
                                OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED
                            )
                        }

                        let showEditButton = false

                        if (rowData.transactionTypeLabel != OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.M2M].label) {

                            if (allowedStatusToEdit.includes(rowData.status)) showEditButton = true
                        }

                        switch (rowData.transactionTypeLabel) {
                            case OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.I2I].label:
                                url += `/transfer/i2i`
                                serviceId = "i2i"
                                break
                            case OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.M2M].label:
                                url += `/transfer/out`;
                                serviceId = "m2m"
                                break;
                            default:
                                url += `/transfer/out`
                                serviceId = "out"

                        }
                        switch (rowData.status) {
                            /**
                             * 11/3/2022 daniel.kwok
                             * These are the status that allowed for re-submittion of transfer
                             */
                            case OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL:
                            case OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP:
                            case OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP:
                            case OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP:
                            case OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED:
                            case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED:
                            case OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO:
                            case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS:
                                /**
                                 * 11/3/2022 daniel.kwok
                                 * Disable user from re-submitting a record
                                 * if dayRemaining <= 0
                                 */
                                if (rowData.dayRemaining > 0) {
                                    // const state = {
                                    //     step: STEPS.JPJ_SUBMISSION.label,
                                    //     subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW
                                    // }
                                    // const cached = encodeURIComponent(JSON.stringify(state))
                                    if (pendingType) url += `/create/${rowData.id}`
                                    else url += `/payment/${rowData.id}`
                                } else {
                                    url += `/payment/${rowData.id}`
                                }
                                break
                            case OUT_TRANSACTION_STATUSES.PENDING_PAYMENT:
                                url += `/payment/${rowData.id}`;
                                break
                            case OUT_TRANSACTION_STATUSES.SUCCESS:
                                url += `/printslip/${rowData.id}`;
                                break
                            // case OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED:
                            //     url += `/printslip/${rowData.id}`;
                            //     break
                            default:
                                url += `/payment/${rowData.id}`
                                break
                        }

                        return !rowData.isM2mTransaction ? (
                            rowData.dayRemaining > 0 ? (
                                <div>
                                    <Button
                                        style={{
                                            border: "1px solid #2B61C4",
                                            color: "#2B61C4",
                                            borderRadius: "4px",
                                            marginBottom: 10,
                                            marginRight: 10,
                                        }}
                                        onClick={async () => {
                                            if (!pendingType || (pendingType && validateTransactionVersion(rowData))) {
                                                history.push(url, {
                                                    isReport: pendingType ? false : true,
                                                    oldIsReport: true,
                                                    fromPendingPage: pendingType ? true : false,
                                                    jpjErrorMsg: rowData.failCode
                                                });
                                            }
                                        }}
                                        disabled={pendingType && validateRolePermission(loggedInUserRole,"OUT", rowData.status === OUT_TRANSACTION_STATUSES.PENDING_PAYMENT)}
                                    >
                                        {!pendingType ? (
                                            <>
                                                <ReadOutlined />
                                                <span className='img-btn-text'>VIEW</span>
                                            </>
                                        ) : (
                                            <>
                                                <EditOutlined />
                                                <span className='img-btn-text'>REVIEW</span>
                                            </>
                                        )}
                                    </Button>
                                    {!pendingType ? (
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                            }}
                                            onClick={() => {
                                                history.push(`/reporting/audittraillog/${serviceId}/${rowData.id}`);
                                            }}
                                        >
                                            <ReadOutlined />
                                            AUDIT TRAIL LOG
                                        </Button>
                                    ) : null}

                                    {
                                        pendingType && (showEditButton == true || rowData.failCode !== "")?
                                            (
                                                <Button
                                                    style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                                    onClick={() => {
                                                        if (validateTransactionVersion(rowData)) {
                                                            history.push(`/transfer/out/vehicleinfo/${rowData.id}`)
                                                        }
                                                    }}
                                                >
                                                    <>
                                                        <EditOutlined />
                                                        <span className='img-btn-text'>EDIT</span>
                                                    </>
                                                </Button>
                                            ) : null
                                    }
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        style={{
                                            border: "1px solid #2B61C4",
                                            color: "#2B61C4",
                                            borderRadius: "4px",
                                            marginBottom: 10,
                                            marginRight: 10,
                                        }}
                                        onClick={async () => {
                                            if (!pendingType) {
                                                history.push(url, {
                                                    isReport: pendingType ? false : true,
                                                    oldIsReport: true,
                                                    fromPendingPage: pendingType ? true : false,
                                                    jpjErrorMsg: rowData.failCode
                                                });
                                            }
                                        }}
                                    >
                                        {!pendingType ? (
                                            <>
                                                <ReadOutlined />
                                                <span className='img-btn-text'>VIEW</span>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </Button>
                                    {!pendingType ? (
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                            }}
                                            onClick={() => {
                                                history.push(`/reporting/audittraillog/${serviceId}/${rowData.id}`);
                                            }}
                                        >
                                            <ReadOutlined />
                                            AUDIT TRAIL LOG
                                        </Button>
                                    ) : null}
                                </div>
                            )
                        ) :
                        (  
                            <>
                            <Button
                                    style={{
                                        border: "1px solid #2B61C4",
                                        color: "#2B61C4",
                                        borderRadius: "4px",
                                        marginBottom: 10,
                                        marginRight: 10,
                                    }}
                                    onClick={async () => {
                                        if (pendingType && validateTransactionVersion(rowData)) {
                                            history.push("/transfer/m2m", {
                                                rowData: rowData,
                                                type: REPORT_TYPE_LABELS.OUT,
                                                // isJpjSubmitted: rowData.failCode !== "",
                                                isJpjSubmitted : true,
                                                sellerInquirySuccess: rowData.sellerInquirySuccess != null
                                            });
                                        }
                                    }}
                                    disabled={pendingType && validateRolePermission(loggedInUserRole,"M2M", rowData.sellerInquirySuccess !== null)}
                                >
                                    {!pendingType ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            <EditOutlined />
                                            <span className='img-btn-text'>REVIEW</span>
                                        </>
                                    )}
                            </Button>
                            <Button
                                style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                onClick={() => {
                                    if (pendingType && validateTransactionVersion(rowData)) {
                                        history.push("/transfer/m2m", {
                                            rowData: rowData,
                                            type: REPORT_TYPE_LABELS.OUT,
                                            isVehicleEdit: true
                                        });
                                    }
                                }}
                            >
                                <>
                                    <EditOutlined />
                                    <span className='img-btn-text'>EDIT</span>
                                </>
                            </Button>
                            </>
                        )
                    }
                }
            ]
        },
        [REPORT_TYPE_LABELS.I2I]: {
            label: REPORT_TYPE_LABELS.I2I,
            api: async (dealerCompanyROC, currentPage, isSearch) => {

                // if (isSearch) {

                const {
                    searchType,
                    searchTerm,
                    nricType,
                    createdDate,
                    transactionStatus
                } = searchForm.getFieldsValue();

                let search = isSearch === true ? searchType : ''
                let searchParam = ''
                let status = pendingType ? transactionStatus : 4//status success

                if (search === SEARCH_TYPES.NRIC.key) search = nricType

                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key
                    || search === SEARCH_TYPES.ROC_NUMBER.key
                    || search === NRIC_TYPES.BUYER.key
                    || search === NRIC_TYPES.SELLER.key) {

                    // if (!searchTerm.trim()) {
                    //     setPagination(prevPagination => ({
                    //         ...prevPagination,
                    //         total: 0,
                    //     }));
                    //     return
                    // }

                    searchParam = searchTerm

                }

                if (search === SEARCH_TYPES.TRANSACTION_DATE.key) {

                    if (!createdDate) {
                        setPagination(prevPagination => ({
                            ...prevPagination,
                            total: 0,
                        }));
                        return
                    }

                    searchParam = moment(createdDate).format('YYYY-MM-DD')
                }

                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                    searchParam = validateAndTrimSearchQuery(searchParam);
                }

                try {
                    let res = await searchI2iTransactions(
                        branch.id,
                        dealerCompanyROC,
                        pagination.pageSize,
                        currentPage,
                        search,
                        searchParam,
                        sortBy,
                        status
                    );

                    if (Array.isArray(res.data)) {
                        setIsSearchDataExist(isSearch)
                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));
                    }
                    return res.data;

                } catch (err) {
                    throw err;
                }

                // } else {

                //     const transactionCountFunc = pendingType === REPORT_TYPE_LABELS.I2I ? getPendingi2iTransactionCount : geti2iTransactionCount;
                //     const transactionListFunc = pendingType === REPORT_TYPE_LABELS.I2I ? getPendingi2iTransactionList : geti2iTransactionList;

                //     const count = await transactionCountFunc(branch.id, dealerCompanyROC, sortBy);
                //     setPagination(prevPagination => ({
                //         ...prevPagination,
                //         total: count.count,
                //         current: currentPage
                //     }));

                //     const list = await transactionListFunc(branch.id, dealerCompanyROC, pagination.pageSize, currentPage, sortBy, status);

                //     setIsSearchDataExist(false)

                //     return list.map(result => ({
                //         ...result,
                //         transactionTypeLabel: OUT_TRANSACTION_TYPES[result.transactionType]?.label || 'Unknown transaction type',
                //     }));
                // }

            },
            columns: [
                {
                    title: 'Created Date',
                    dataIndex: 'createdDate',
                    render: createdDate => parseTime(moment(createdDate).valueOf()),
                    sorter: isSearchDataExist ? (a, b) => moment(a.createdDate).valueOf() - moment(b.createdDate).valueOf() : (a, b) => { },
                    defaultSortOrder: 'descend',
                },
                {
                    title: 'Vehicle Number',
                    dataIndex: 'carRegistrationNo',
                    sorter: isSearchDataExist ? (a, b) => a.carRegistrationNo.localeCompare(b.carRegistrationNo) : (a, b) => { },
                },
                {
                    title: 'Seller Name',
                    dataIndex: 'sellerName',
                    // sorter: (a, b) => a.buyerName.localeCompare(b.buyerName),
                },
                {
                    title: 'Buyer Name',
                    dataIndex: 'buyerName',
                },
                {
                    title: 'Engine Number',
                    dataIndex: 'engineNo',
                    // sorter: (a, b) => a.buyerNRIC.localeCompare(b.buyerNRIC),
                    render: (engineNo) => {
                        return engineNo ? engineNo : "-"
                    }
                },
                {
                    title: 'Chasis Number',
                    dataIndex: 'chassisNo',
                    // sorter: (a, b) => a.buyerNRIC.localeCompare(b.buyerNRIC),
                    render: (chassisNo) => {
                        return chassisNo ? chassisNo : "-"
                    }
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    width: 300,
                    render: status => <Tag
                        color={OUT_TRANSACTION_STATUSES_COLORS[status]}
                    >{OUT_TRANSACTION_STATUSES_LABELS[status]}</Tag>,
                },
                {
                    title: 'JPJ Response Message',
                    dataIndex: 'failCode',
                    width: 300,
                    // sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
                    render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
                },
                {
                    title: 'Day Remaining',
                    dataIndex: 'dayRemaining',
                    render: (dayRemaining, rowData) => {
                        if (rowData.status === 4) {
                            return "";
                        }

                        if (dayRemaining > 1) {
                            return `${dayRemaining} days`;
                        } else if (dayRemaining > 0) {
                            return `${dayRemaining} day`;
                        } else {
                            return "Expired";
                        }
                    }
                },
                // {
                //     title: 'JPJ Response Message',
                //     dataIndex: 'failCode',
                //     // sorter: (a, b) => a.failCode.localeCompare(b.failCode),
                //     render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
                // },
                {
                    title: 'Stage',
                    dataIndex: 'stage',
                },
                {
                    title: 'Action',
                    fixed: 'right',
                    render: (rowData) => {

                        let allowedStatusToEdit = []

                        Object.values(OUT_TRANSACTION_STATUSES).filter(value => {
                            // Exclude values 1, 3, 4, 6, 7, 8
                            if (![
                                OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS,
                                OUT_TRANSACTION_STATUSES.PENDING_PAYMENT,
                                OUT_TRANSACTION_STATUSES.SUCCESS,
                                OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED,
                                OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED,
                                OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL
                            ].includes(value)) {
                                allowedStatusToEdit.push(value);
                            }
                        })

                        if (rowData.transactionTypeLabel
                            &&
                            rowData.transactionTypeLabel == OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.D2I].label
                            ||
                            rowData.transactionTypeLabel == OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.D2C].label) {
                            allowedStatusToEdit.push(
                                // OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED,
                                OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED
                            )
                        }

                        if (rowData.transactionType
                            &&
                            rowData.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I
                            ||
                            rowData.transactionType == OUT_TRANSACTION_TYPES_IDS.D2C) {
                            allowedStatusToEdit.push(
                                // OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED,
                                OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED
                            )
                        }

                        let showEditButton = false

                        if (rowData.transactionTypeLabel && rowData.transactionTypeLabel != OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.M2M].label) {

                            if (allowedStatusToEdit.includes(rowData.status)) showEditButton = true
                        }

                        if (rowData.transactionType && rowData.transactionType != OUT_TRANSACTION_TYPES_IDS.M2M) {

                            if (allowedStatusToEdit.includes(rowData.status)) showEditButton = true
                        }

                        let url = ''
                        switch (rowData.transactionTypeLabel) {
                            case OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.I2I].label:
                                url += `/transfer/i2i`
                                break
                            default:
                                url += `/transfer/i2i`

                        }

                        if (rowData.dayRemaining > 0) {
                            switch (rowData.status) {
                                case OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO:
                                case OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED:
                                case OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL:
                                case OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP:
                                case OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP:
                                case OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP:
                                case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED:
                                case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS:
                                    if (pendingType)
                                        url += `/create/${rowData.id}?`
                                    else
                                        url += `/payment/${rowData.id}`
                                    break
                                case OUT_TRANSACTION_STATUSES.SUCCESS:
                                    url += `/printslip/${rowData.id}?`
                                    break
                                case OUT_TRANSACTION_STATUSES.PENDING_PAYMENT:
                                    url += `/payment/${rowData.id}`
                                    break
                                default:
                                    url += `/payment/${rowData.id}`
                                    break
                            }
                        } else {
                            if (rowData.status == OUT_TRANSACTION_STATUSES.SUCCESS)
                                url += `/printslip/${rowData.id}?`
                            else
                                url += `/payment/${rowData.id}`
                        }

                        return (
                            rowData.dayRemaining > 0 || rowData.status == OUT_TRANSACTION_STATUSES.SUCCESS ?
                                <div>
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={async () => {
                                            if (!pendingType || (pendingType && validateTransactionVersion(rowData))) {
                                                history.push(url, {
                                                    isReport: pendingType ? false : true,
                                                    oldIsReport: true,
                                                    fromPendingPage: pendingType ? true : false,
                                                    jpjErrorMsg: rowData.failCode
                                                });
                                            }
                                        }}
                                        disabled={pendingType && validateRolePermission(loggedInUserRole,"I2I", rowData.status === OUT_TRANSACTION_STATUSES.PENDING_PAYMENT)}
                                    >
                                        {!pendingType ? (
                                            <>
                                                <ReadOutlined />
                                                <span className='img-btn-text'>VIEW</span>
                                            </>
                                        ) : (
                                            <>
                                                <EditOutlined />
                                                <span className='img-btn-text'>REVIEW</span>
                                            </>
                                        )}
                                    </Button>
                                    {!pendingType ? (
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                            }}
                                            onClick={() => {
                                                history.push(`/reporting/audittraillog/i2i/${rowData.id}`);
                                            }}
                                        >
                                            <ReadOutlined />
                                            AUDIT TRAIL LOG
                                        </Button>
                                    ) : null}

                                    {
                                        pendingType && (showEditButton == true || rowData.failCode !== "") ?
                                            (
                                                <Button
                                                    style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                                    onClick={() => {
                                                        if (validateTransactionVersion(rowData)) {
                                                            history.push(`/transfer/i2i/vehicleinfo/${rowData.id}`)
                                                        }
                                                    }}
                                                >
                                                    <>
                                                        <EditOutlined />
                                                        <span className='img-btn-text'>EDIT</span>
                                                    </>
                                                </Button>
                                            ) : null
                                    }
                                </div>
                                : <></>
                        );
                    }
                }
            ]
        },
        [REPORT_TYPE_LABELS.APT]: {
            label: REPORT_TYPE_LABELS.APT,
            api: async (dealerCompanyROC, currentPage, isSearch) => {

                // if (isSearch) {

                const {
                    searchType,
                    searchTerm,
                    nricType,
                    createdDate,
                    transactionStatus
                } = searchForm.getFieldsValue();

                let search = isSearch === true ? searchType : ''
                let searchParam = ''
                let status = pendingType ? transactionStatus : 2//status success

                if (search === SEARCH_TYPES.NRIC.key) search = nricType

                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key
                    || search === SEARCH_TYPES.ROC_NUMBER.key
                    || search === NRIC_TYPES.BUYER.key
                    || search === NRIC_TYPES.SELLER.key) {

                    // if (!searchTerm.trim()) {
                    //     setPagination(prevPagination => ({
                    //         ...prevPagination,
                    //         total: 0,
                    //     }));
                    //     return
                    // }

                    searchParam = searchTerm

                }

                if (search === SEARCH_TYPES.TRANSACTION_DATE.key) {

                    if (!createdDate) {
                        setPagination(prevPagination => ({
                            ...prevPagination,
                            total: 0,
                        }));
                        return
                    }

                    searchParam = moment(createdDate).format('YYYY-MM-DD')
                }

                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                    searchParam = validateAndTrimSearchQuery(searchParam);
                }

                try {
                    let res = await searchAptTransactions(
                        branch.id,
                        dealerCompanyROC,
                        pagination.pageSize,
                        currentPage,
                        search,
                        searchParam,
                        sortBy,
                        status
                    );

                    if (Array.isArray(res.data)) {
                        setIsSearchDataExist(isSearch)
                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));
                    }
                    return res.data;

                } catch (err) {
                    throw err;
                }

                // } else {

                //     const results = await getAptTransactionCount(dealerCompanyROC);
                //     setPagination((prevPagination) => ({
                //         ...prevPagination,
                //         total: results.count,
                //         current: currentPage
                //     }));

                //     setIsSearchDataExist(false)

                //     return await getAptTransactionList(dealerCompanyROC, pagination.pageSize, currentPage, sortBy, status);
                // }
            },
            columns: [
                {
                    title: 'Vehicle Number',
                    dataIndex: 'carRegistrationNo',
                    sorter: isSearchDataExist ? false : (a, b) => { },
                },
                {
                    title: 'Make',
                    dataIndex: 'make',
                    // sorter: (a, b) => a.make.localeCompare(b.make),
                },
                {
                    title: 'Model',
                    dataIndex: 'model',
                    // sorter: (a, b) => a.model.localeCompare(b.model),
                },
                {
                    title: 'APT status',
                    dataIndex: 'aptStatus',
                    render: status => <Tag
                        color={APT_TRANSACTION_STATUSES_COLORS[status]}
                    >{APT_TRANSACTION_STATUSES_LABELS[status]}</Tag>,
                    // sorter: (a, b) => a.aptStatus - b.aptStatus,
                },
                {
                    title: 'Transfer status',
                    dataIndex: 'status',
                    render: status => <Tag
                        color={IN_TRANSACTION_STATUSES_COLORS[status]}
                    >{IN_TRANSACTION_STATUSES_LABELS[status]}</Tag>,
                    // sorter: (a, b) => a.status - b.status,
                },
                {
                    title: 'Action',
                    fixed: 'right',
                    render: (rowData) => {
                        return (

                            rowData.aptStatus == APT_TRANSACTION_STATUSES.SUCCESS
                            && rowData.status == IN_TRANSACTION_STATUSES.TRANSACTION_SUCCESS
                            && (
                                rowData.isNewAptPrice ?
                                <div>
                                    <Tooltip title="Vehicle Dealer Copy">
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                                marginBottom: 10,
                                                marginRight: 10
                                            }}
                                            onClick={() => {
                                                getV2ReceiptAPT(company.id, rowData.id, rowData.carRegistrationNo, ReceiptType.vehicleDealerCopy)
                                            }}
                                        >
                                            <PrinterOutlined />
                                            Vehicle Dealer Copy
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Confirmation Slip">
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                                marginBottom: 10,
                                                marginRight: 10
                                            }}
                                            onClick={() => {
                                                getV2ReceiptAPT(company.id, rowData.id, rowData.carRegistrationNo, ReceiptType.confirmationSlip)
                                            }}
                                        >
                                            <PrinterOutlined />
                                            Confirmation Slip
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Dealer Copy">
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                                marginBottom: 10,
                                                marginRight: 10
                                            }}
                                            onClick={() => {
                                                getV2ReceiptAPT(company.id, rowData.id, rowData.carRegistrationNo, ReceiptType.dealerCopy)
                                            }}
                                        >
                                            <PrinterOutlined />
                                            Dealer Copy
                                        </Button>
                                    </Tooltip>
                                </div>
                                :
                                <div>
                                    <Tooltip title="APT Receipt">
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                                marginBottom: 10,
                                                marginRight: 10
                                            }}
                                            onClick={() => {
                                                getReceiptAPT(company.id, rowData.id, rowData.carRegistrationNo)
                                            }}
                                        >
                                            <PrinterOutlined />
                                            APT Receipt
                                        </Button>
                                    </Tooltip>
                                </div>
                            )
                            
                        );


                    }
                }
            ]

        },
        [REPORT_TYPE_LABELS.M2M]: {
            label: REPORT_TYPE_LABELS.M2M,
            api: async (dealerCompanyROC, currentPage, isSearch) => {
                const {
                    searchType,
                    searchTerm,
                    nricType,
                    createdDate,
                    transactionStatus,
                    outTransactionType
                } = searchForm.getFieldsValue();

                let search = isSearch === true ? searchType : ''
                let searchParam = ''
                let status = pendingType ? transactionStatus : 4//status success

                if (search === SEARCH_TYPES.NRIC.key) search = nricType

                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key
                    || search === SEARCH_TYPES.ROC_NUMBER.key
                    || search === NRIC_TYPES.BUYER.key
                    || search === NRIC_TYPES.SELLER.key) {
                    searchParam = searchTerm

                }

                if (search === SEARCH_TYPES.TRANSACTION_DATE.key) {

                    if (!createdDate) {
                        setPagination(prevPagination => ({
                            ...prevPagination,
                            total: 0,
                        }));
                        return
                    }

                    searchParam = moment(createdDate).format('YYYY-MM-DD')
                }

                if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                    searchParam = validateAndTrimSearchQuery(searchParam);
                }

                onApprovalM2mBulkSubmit(company, setIsM2mAdminUser)

                try {
                    let res = await searchM2mTransactions(
                            branch.id,
                            dealerCompanyROC,
                            pagination.pageSize,
                            currentPage,
                            search,
                            searchParam,
                            sortBy,
                            status
                        );

                    if (Array.isArray(res.data)) {
                        setIsSearchDataExist(isSearch)
                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));
                    }

                    if(isM2mBulkInitial) {
                        selectAll({target: {checked: true}}, res.count)
                        setIsM2mBulkInitial(false)
                    }
                    return res.data;


                } catch (err) {
                    throw err;
                }
            },
            columns: [
                {
                    title: 'Created Date',
                    dataIndex: 'createdDate',
                    render: createdDate => parseTime(moment(createdDate).valueOf()),
                    sorter: isSearchDataExist ? (a, b) => moment(a.createdDate).valueOf() - moment(b.createdDate).valueOf() : (a, b) => { },
                    defaultSortOrder: 'descend',
                },
                {
                    title: 'Vehicle Number',
                    dataIndex: 'carRegistrationNo',
                    sorter: isSearchDataExist ? (a, b) => a.carRegistrationNo.localeCompare(b.carRegistrationNo) : (a, b) => { },
                },
                {
                    title: 'Seller Name',
                    dataIndex: 'sellerName',
                },
                {
                    title: 'Buyer Name',
                    dataIndex: 'buyerName',
                },
                {
                    title: 'Engine Number',
                    dataIndex: 'engineNo',
                    render: (engineNo) => {
                        return engineNo ? engineNo : "-"
                    }
                },
                {
                    title: 'Chasis Number',
                    dataIndex: 'chassisNo',
                    render: (chassisNo) => {
                        return chassisNo ? chassisNo : "-"
                    }
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    // width: 300,
                    render: (status, rowData) => {
                        if (rowData.isM2mTransaction && rowData.sellerInquirySuccess !== null) {
                        return <Tag
                            color={"blue"}
                        >{"Pending Seller Approval"}</Tag>
                        }
                        else if (rowData.isM2mTransaction && rowData.failCode !== "") {
                            return <Tag
                            color={"red"}
                        >{"Failed"}</Tag>
                        }
                        else if (rowData.isM2mTransaction) {
                            return <Tag
                            color={"blue"}
                        >{"Pending JPJ Submission"}</Tag>
                        }
                        else {
                            return <Tag
                        color={OUT_TRANSACTION_STATUSES_COLORS[status]}
                    >{OUT_TRANSACTION_STATUSES_LABELS[status]}</Tag>
                        }
                    }
                },
                {
                    title: 'JPJ Response Message',
                    dataIndex: 'failCode',
                    width: 300,
                    render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
                },
                {
                    title: 'Day Remaining',
                    dataIndex: 'dayRemaining',
                    render: (dayRemaining, rowData) => {
                        if (rowData.status === 4) {
                            return "";
                        }

                        if (dayRemaining > 1) {
                            return `${dayRemaining} days`;
                        } else if (dayRemaining > 0) {
                            return `${dayRemaining} day`;
                        } else if (rowData.isM2mTransaction){
                            return "N/A"
                        } else {
                            return "Expired";
                        }
                    }
                },
                {
                    title: 'Stage',
                    dataIndex: 'stage',
                    render: (stage, rowData) => {
                        // if (rowData.isM2mTransaction) {
                        //     return "Pending Approval"
                        // }
                        // else {
                            return stage
                        // }
                    }
                    // sorter: (a, b) => a.stage.localeCompare(b.stage),
                },
                {
                    title: 'Action',
                    fixed: 'right',
                    render: (rowData) => {
                        let url = ''
                        switch (rowData.status) {
                            /**
                             * 11/3/2022 daniel.kwok
                             * These are the status that allowed for re-submittion of transfer
                             */
                            case OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL:
                            case OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP:
                            case OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP:
                            case OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP:
                            case OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED:
                            case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED:
                            case OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO:
                            case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS:
                                /**
                                 * 11/3/2022 daniel.kwok
                                 * Disable user from re-submitting a record
                                 * if dayRemaining <= 0
                                 */
                                if (rowData.dayRemaining > 0) {
                                    // const state = {
                                    //     step: STEPS.JPJ_SUBMISSION.label,
                                    //     subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW
                                    // }
                                    // const cached = encodeURIComponent(JSON.stringify(state))
                                    if (pendingType) url += `/create/${rowData.id}`
                                    else url += `/payment/${rowData.id}`
                                } else {
                                    url += `/payment/${rowData.id}`
                                }
                                break
                            case OUT_TRANSACTION_STATUSES.PENDING_PAYMENT:
                                url += `/payment/${rowData.id}`;
                                break
                            case OUT_TRANSACTION_STATUSES.SUCCESS:
                                url += `/printslip/${rowData.id}`;
                                break
                            // case OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED:
                            //     url += `/printslip/${rowData.id}`;
                            //     break
                            default:
                                url += `/payment/${rowData.id}`
                                break
                        }
                        return (
                            <>
                                <Button
                                    style={{
                                        border: "1px solid #2B61C4",
                                        color: "#2B61C4",
                                        borderRadius: "4px",
                                        marginBottom: 10,
                                        marginRight: 10,
                                    }}
                                    onClick={async () => {
                                        if (pendingType && validateTransactionVersion(rowData)) {
                                            history.push("/transfer/m2m", {
                                                rowData: rowData,
                                                type: REPORT_TYPE_LABELS.OUT,
                                                // isJpjSubmitted: rowData.failCode !== "",
                                                isJpjSubmitted : true,
                                                sellerInquirySuccess: rowData.sellerInquirySuccess != null
                                            });
                                        }
                                    }}
                                    disabled={pendingType && validateRolePermission(loggedInUserRole,"M2M", rowData.sellerInquirySuccess !== null)}
                                >
                                    {!pendingType ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            <EditOutlined />
                                            <span className='img-btn-text'>REVIEW</span>
                                        </>
                                    )}
                            </Button>
                            </>
                        )
                    }
                },
                {
                    dataIndex: "checked",
                    fixed: 'right',
                    width: pendingType ? 45 : 1,
                    title: () => (
                        <>
                            {!pendingType ? (
                                <>
                                </>
                            ) : (<Checkbox
                            checked={allChecked}
                            onChange={(e) => selectAll(e, pagination.total)}
                            ></Checkbox>)}
                      </>
                    ),
                    render: (text, rec, index) => (
                        <>
                            {!pendingType ? (
                                <>
                                </>
                            ) : (<Checkbox
                            checked={selectedRowKeys.includes(rec.key)}
                            onChange={(e) => onChangeSelectedRowKeys(e, rec)}
                        ></Checkbox>)}
                      </>
                    )
                  },
            ]
        }
    }

    const selectAll = async (e, count) => {
        const checked = e.target.checked;
        setAllChecked(checked);
        if (checked) {
            setIsBulkLoading(true)
            try {
                let res = await searchM2mTransactions(
                        branch.id,
                        company.coRegNo,
                        count,
                        1,
                        "",
                        "",
                        "1D",
                        "Pending"
                    );

                    setSelectedRowKeys(res.data.map((item) => item.id));
                //     setSelectedRowKeys(dataSource.map((item) => item.key));
            } catch (err) {
                throw err;
            }
            finally {
                setIsBulkLoading(false)
            }
        } else {
          setSelectedRowKeys([]);
        }
      };

    const onChangeSelectedRowKeys = (e, rec) => {
      const checked = e.target.checked;
      if (checked) {
        setSelectedRowKeys((prevKeys) => [...prevKeys, rec.key]);
      } else {
        setSelectedRowKeys((prevKeys) => prevKeys.filter((item) => item !== rec.key));
      }
    };

    const report = REPORT_TYPES[reportType]

    const columns = REPORT_TYPES[prevReportType].columns

    return (
        <>
            <div className="page-content">
                {pendingType == null ? (
                    <>
                        <Breadcrumb separator=">" style={{ marginTop: "60px" }}>
                            <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item href="/reporting">Reporting</Breadcrumb.Item>
                            <Breadcrumb.Item href="/reporting/transferreport">Re-Print Slip</Breadcrumb.Item>
                        </Breadcrumb>
                        <Row>
                            <Col span={12}>
                                <PageHeader
                                    style={{ paddingLeft: "0px" }}
                                    className="site-page-header"
                                    title="Re-Print Slip"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}><TransactionTypeDropdown /></Col>
                            <InputSearchBy style={{ display: "flex", justifyContent: "flex-end", marginBottom: "24px" }} />
                        </Row>
                    </>
                ) : pendingType == REPORT_TYPE_LABELS.I2I ? (
                    <>
                        <PageHeader
                            style={{ paddingLeft: "0px", marginTop: "40px" }}
                            className="site-page-header"
                            title={"I2I Pending List"}
                        />
                        <Row>
                            <Col span={12}></Col>
                            <InputSearchBy style={{ display: "flex", justifyContent: "flex-end", marginBottom: "24px" }} />
                        </Row>
                    </>
                )
                    : (
                        <>
                            <PageHeader
                                style={{ paddingLeft: "0px", marginTop: "40px" }}
                                className="site-page-header"
                                title={pendingType === REPORT_TYPE_LABELS.IN ? "IN Pending List" : 
                                pendingType === REPORT_TYPE_LABELS.M2M ?  "OUT M2M Pending List" : "OUT Pending List"}
                            />
                            <Row>
                                <Col span={12}></Col>
                                <InputSearchBy style={{ display: "flex", justifyContent: "flex-end", marginBottom: "24px" }} />
                            </Row>
                        </>
                    )}

                {/* {
                REPORT_TYPES[prevReportType].label === REPORT_TYPE_LABELS.BULK_M2M &&
                    onApprovalM2mBulkSubmit(company, setIsM2mAdminUser)
                } */}
                {
                    REPORT_TYPES[prevReportType].label === REPORT_TYPE_LABELS.M2M ? (
                        <Table
                            className="report-table"
                            size="small"
                            columns={columns}
                            dataSource={dataSource}
                            loading={isLoading || isBulkLoading}
                            scroll={{
                                x: columns.length * 150,
                            }}
                            onChange={handleTableChange}
                            // pagination={{ showSizeChanger: false, showQuickJumper: false }}
                            pagination={pagination}
                            /* rowSelection={{
                                type: "checkbox",
                                onChange: (selectedRowKeys) => {
                                    setSelectedRowKeys(selectedRowKeys)
                                }
                            }} */
                            footer={() => {
                                return (
                                    selectedRowKeys.length > 0 && (
                                        bulkSaveButtons()
                                    )
                                );
                            }}
                        />
                    ) : (
                        <Table
                        className="report-table"
                        size="small"
                        columns={columns}
                        dataSource={dataSource}
                        loading={isLoading}
                        scroll={{
                            x: columns.length * 150,
                        }}
                        onChange={handleTableChange}
                        // pagination={{ showSizeChanger: false, showQuickJumper: false }}
                        pagination={pagination}
                        // rowSelection={{
                        //     type: "checkbox",
                        //     onChange: (selectedRowKeys) => {
                        //         setSelectedRowKeys(selectedRowKeys)
                        //     }
                        // }}
                        // footer={() => {
                        //     return (
                        //         selectedRowKeys.length > 0 && (
                        //             footerButtons()
                        //         )
                        //     );
                        // }}
                        />
                    )
                }

                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                    }}
                >
                    <Button
                        className="back-button buttons"
                        shape="round"
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        <Image src={arrow_back} preview={false} />
                        Back
                    </Button>
                </div>
            </div>
            <>
                <ApproverAuthModal
                    isIncorrectOtp={isIncorrectOtp}
                    isModalVisible={isModalVisible}
                    isApprover={true}
                    isM2M={true}
                    transferId={''}
                    transferIdList={selectedRowKeys}
                    isFromPending={true}
                    onCancel={() => setIsModalVisible(false)}
                    onOk={(approverIc, authCode, isPush, appApprovalResponse) => {

                        if (isPush) {
                            setIsModalVisible(false)
                            if (!appApprovalResponse.isRejected) {
                                proceedM2mBulk(appApprovalResponse)
                            }
                        }
                        else {
                            verifyApproverSmsOtp(approverIc, authCode)
                                .then(res => {
                                    if(!res.encryptedString) {
                                        let errorMsg = res.message ? res.message : "ERROR"
                                        throw errorMsg
                                    }
                                    const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)
                                    if (decryptedResponse.status == 'false') {
                                        setIsIncorrectOtp(true)
                                        throw decryptedResponse.message
                                    }
                                    setIsIncorrectOtp(false)
                                    setIsModalVisible(false)
                                    // Next step: JPJ Verification
                                    proceedM2mBulk()
                                })
                                .catch((err) => {
                                    setIsIncorrectOtp(true)
                                    err && message.error(err)
                                })
                                .finally(() => {
                                    setIsIncorrectOtp(false)
                                })
                        }

                    }}
                />
            </>
            <style>
                {`
                 .ant-select  {
                    max-width: 100% !important;
                }
                
                .ant-select > .ant-select-selector {
                    height: 48px !important;
                    display: flex !important;
                    align-items: center !important;
                }
                .footer-button {
                    border: 1px solid #2B61C4;
                    color: #2B61C4;
                    margin-right: 20px;
                    margin-left: 0px;
                }
            `}
            </style>
        </>
    );
}