// @ts-nocheck
import { LoadingOutlined } from '@ant-design/icons'
import {
    Button, Card, Image,
    Col, message, Row, Skeleton, Space
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { SessionContext } from '../../App'
import { JPJ_INQUIRY_STATUS, STATES, TRANSACTION_RESPONSE_CODES } from '../../constants'
import {
    getInTransferByTransferId,
    inSaveSellerProceed,
    InTransferInquiryWithJPJ,
    isAccountExist,
    create1MIDAccount,
    getDistrictList
} from '../../services/api'
import {
    GetInTransferDetailRequest,
    GetInTransferDetailResponse,
    InSaveSellerProceedRequest,
    InSaveSellerProceedResponse
} from '../../services/api/types'
import {
    breakdownIc, getDistrictName, parseAddress, parseCorrespondenceAddress, parseTime
} from '../../util'
import './index.less'
import {
    Dealer, HistoryState,
    Params,
    Seller, STEPS, Vehicle
} from './types'
import failedIcon from "../../assets/icon-failed.svg";
import moment from 'moment'
import parse from 'html-react-parser';
import { ReactComponent as WarningIcon } from '../../assets/icon-warning-red-28.svg';

type JPJSubmissionProps = {
    seller: Seller,
    dealer: Dealer,
    vehicle: Vehicle,
    isReport: boolean
}

function JPJSubmission(props: JPJSubmissionProps) {
    
    const { isReport } = props

    const history = useHistory()
    const { state: locationState } = useLocation<HistoryState>()
    const { user, company, branch } = useContext(SessionContext)

    const params = useParams<Params>()
    const [currentSubStep, setCurrentSubStep] = useState(STEPS.JPJ_SUBMISSION.subSteps.REVIEW)
    const [isLoading, setIsLoading] = useState(false)
    const [jpjInquiryError, setJpjInquiryError] = useState<InSaveSellerProceedResponse>()
    const [transferDetails, setTransferDetails] = useState<GetInTransferDetailResponse>()
    const [transferId, setTransferId] = useState<number | null>(null)

    const { formattedDob: sellerFormattedDob, isMale: isSellerMale } = breakdownIc(props.seller.identificationNo || transferDetails?.sellerInfo.identificationNo)

    const [sellerDistricts, setSellerDistricts] = useState([])
    const [sellerCorrespondenceDistricts, setsellerCorrespondenceDistricts] = useState([])
    const [sellerDistrictName, setSellerDistrictName] = useState('')
    const [sellerCorrespondenceDistrictName, setSellerCorrespondenceDistrictName] = useState('')
    const [isTransactionDetailFailed, setIsTransactionDetailFailed] = useState(false)

    useEffect(() => {
        setCurrentSubStep(locationState?.subStep || '')

        if (params.transferId) {
            setTransferId(Number(params.transferId))
            getAndSetTransfer(Number(params.transferId))
        } else {
            if (props.seller?.district) {
                setSellerDistrictName(props.seller?.district)
            }

            if (props.seller?.correspondentDistrict) {
                setSellerCorrespondenceDistrictName(props.seller?.correspondentDistrict)
            }
        }
    }, [history.location.state, locationState?.subStep, params.transferId]);

    useEffect(() => {

        // if (company.companyAddress?.stateCode) {
        //     getDistrictList(company.companyAddress?.stateCode)
        //         .then(res => {
        //             setBuyerCorrespondenceDistricts(res)
        //         })
        // }

        if (props?.seller?.state) {
            setIsLoading(true)
            getDistrictList(props?.seller?.state)
                .then(res => {
                    setSellerDistricts(res)
                }).finally(() => setIsLoading(false))
        }

        if (props?.seller?.correspondentState) {
            setIsLoading(true)
            getDistrictList(props?.seller?.correspondentState)
                .then(res => {
                    setsellerCorrespondenceDistricts(res)
                }).finally(() => setIsLoading(false))
        }

    }, [props?.seller?.state, props?.seller?.correspondentState])

    const COMPONENT_STEPS = {
        [STEPS.JPJ_SUBMISSION.subSteps.REVIEW]: {
            component: (
                <div style={{ width: '100%', }}>
                    <Card
                        style={{
                            textAlign: 'left',
                            marginBottom: 10,
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 4
                        }}
                    >
                        <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Vehicle Information</h2>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Vehicle Number</p>
                                <p style={styles.value}>{props.vehicle.vehicleRegistrationNo || transferDetails?.carRegistrationNo}</p>
                            </Col>
                            {/* <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Make</p>
                                <p style={styles.value}>{props.vehicle.carMake || transferDetails?.make}</p>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Model</p>
                                <p style={styles.value}>{props.vehicle.carModel || transferDetails?.model}</p>
                            </Col> */}
                        {/* </Row>
                        <Row gutter={16}> */}
                            {/* <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Manufacture Year</p>
                                <p style={styles.value}>{props.vehicle.manufactureYear || transferDetails?.manufactureYear}</p>
                            </Col> */}
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Engine Number</p>
                                <p style={styles.value}>{props.vehicle.engineNo || transferDetails?.engineNo}</p>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Chassis Number</p>
                                <p style={styles.value}>{props.vehicle.chassisNo || transferDetails?.chassisNo}</p>
                            </Col>
                        </Row>
                    </Card>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 10,
                        }}
                    >

                        <Card
                            style={{
                                width: '50%',
                                textAlign: 'left',
                                marginTop: 10, boxShadow: '0px 3px 6px #00000029',
                                borderRadius: 4
                            }}
                        >
                            <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Owner's Details</h2>
                            {
                                isLoading ? (
                                    <Skeleton active />
                                ) : (
                                    <>
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.seller.name || transferDetails?.sellerInfo.name}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.seller.identificationNo || transferDetails?.sellerInfo.identificationNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{sellerFormattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isSellerMale ? 'Male' : 'Female'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>
                                            {transferId == null ? (
                                                parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label, districtName: sellerDistrictName })
                                            ) : (
                                                parseAddress({ ...transferDetails?.sellerInfo, state: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo.state)?.label, districtName: sellerDistrictName })
                                            )}
                                        </p>
                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>
                                            {transferId == null ? (
                                                props.seller.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.seller, correspondentState: Object.values(STATES).find(s => s.code === props.seller.correspondentState)?.label, districtName: sellerCorrespondenceDistrictName })
                                                    : parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label, districtName: sellerDistrictName })
                                            ) : (
                                                transferDetails?.sellerInfo.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...transferDetails?.sellerInfo, correspondentState: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo.correspondentState)?.label, districtName: sellerCorrespondenceDistrictName })
                                                    : parseAddress({ ...transferDetails?.sellerInfo, state: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo.state)?.label, districtName: sellerDistrictName })
                                            )}
                                        </p> */}
                                        <p style={styles.label}>Email</p>
                                        <p style={styles.value}>{props.seller.correspondentEmail || transferDetails?.sellerInfo.correspondentEmail}</p>
                                        <p style={styles.label}>Mobile Number</p>
                                        <p style={styles.value}>{props.seller.correspondentMobileNo || transferDetails?.sellerInfo.correspondentMobileNo}</p>
                                    </>
                                )
                            }
                        </Card>
                        <Card
                            style={{
                                width: '50%',
                                textAlign: 'left',
                                marginTop: 10,
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: 4
                            }}
                        >
                            <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Dealer's Details</h2>
                            <p style={styles.label}>Company Name</p>
                            <p style={styles.value}>{company.coName}</p>
                            <p style={styles.label}>Company ROC number</p>
                            <p style={styles.value}>{company.coRegNo}</p>
                            <p style={styles.label}>Company Address</p>
                            <p style={styles.value}>{parseAddress({ ...company.companyAddress, state: Object.values(STATES).find(s => s.code === company?.companyAddress?.stateCode)?.label })}</p>
                            <p style={styles.label}>Company e-mail</p>
                            <p style={styles.value}>{company.email}</p>
                            <p style={styles.label}>Company Phone Number</p>
                            <p style={styles.value}>{company.contactNo}</p>
                            <p style={styles.label}>Dealer Representative</p>
                            <p style={styles.value}>{user.name || transferDetails?.buyerInfo.repName}</p>
                            <p style={styles.label}>Representative's NRIC</p>
                            <p style={styles.value}>{user.nric || transferDetails?.buyerInfo.repIc}</p>
                            <p style={styles.label}>Representative's Mobile Number</p>
                            <p style={styles.value}>{user.mobileno || transferDetails?.buyerInfo.phoneNo}</p>
                        </Card>
                    </div>

                    <div
                        style={{ marginTop: 20 }}
                    >
                        {
                            <Row
                                style={{
                                    width: '100%', justifyContent: 'space-between', marginBottom: '10px'
                                }}
                            >
                                <Button
                                    className='back-button buttons'
                                    shape='round'
                                    onClick={() => {
                                        if (locationState?.oldIsReport) {
                                            if (locationState?.fromPendingPage === true) {
                                                history.push('/reporting/transferreport/In')
                                            } else {
                                                history.push('/reporting/transferreport')
                                            }
                                        } else {

                                            if (locationState.transferId) {
                                                history.push('/')
                                            } else if (params.transferId == null) {
                                                history.push(history.location.pathname, {
                                                    ...locationState,
                                                    step: STEPS.VEHICLE_INFORMATION.label,
                                                    subStep: STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO,
                                                })
                                            } else {
                                                history.push('/')
                                            }
                                        }
                                    }}
                                >
                                    {locationState.transferId ? 'Home' : 'Back'}
                                </Button>
                                <Col>
                                    {isReport == false &&
                                        <Button
                                            type='primary'
                                            className='rectangle-button'
                                            onClick={() => onJPJSubmit()}
                                        >
                                            JPJ Submission
                                        </Button>
                                    }
                                </Col>
                            </Row>

                        }
                    </div>
                </div>
            )
        },

        [STEPS.JPJ_SUBMISSION.subSteps.JPJ_CHECKING]: {
            component: (
                <Card
                    style={{
                        height: '50%',
                        width: '100%',
                        boxShadow: '0px 3px 6px #00000029',
                    }}
                >
                    <div>
                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            JPJ Verification
                        </h1>
                        <p>Checking in process. Retrieving data...</p>
                        <LoadingOutlined />
                    </div>
                </Card>
            )
        },

        [STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED]: {
            component: (
                <div style={{ width: '100%', }}>
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={failedIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <Space direction='vertical' size={0}>
                                <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ verification failed</h2>
                                <p style={{ font: 'normal normal normal 16px Open Sans', margin: 0 }} dangerouslySetInnerHTML={{ __html: jpjInquiryError?.inquiryMSG }}></p>
                            </Space>
                        </div>
                    </Card >
                    <Card
                        style={{
                            textAlign: 'left',
                            marginBottom: 10,
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 4
                        }}
                    >
                        <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Vehicle Information</h2>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Vehicle Number</p>
                                <p style={styles.value}>{props.vehicle.vehicleRegistrationNo || transferDetails?.carRegistrationNo}</p>
                            </Col>
                            {/* <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Make</p>
                                <p style={styles.value}>{props.vehicle.carMake || transferDetails?.make}</p>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Model</p>
                                <p style={styles.value}>{props.vehicle.carModel || transferDetails?.model}</p>
                            </Col> */}
                        {/* </Row>
                        <Row gutter={16}> */}
                            {/* <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Manufacture Year</p>
                                <p style={styles.value}>{props.vehicle.manufactureYear || transferDetails?.manufactureYear}</p>
                            </Col> */}
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Engine Number</p>
                                <p style={styles.value}>{props.vehicle.engineNo || transferDetails?.engineNo}</p>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Chassis Number</p>
                                <p style={styles.value}>{props.vehicle.chassisNo || transferDetails?.chassisNo}</p>
                            </Col>
                        </Row>
                    </Card>
                    <Card className='section-card section-card-left'>
                        <h2 className='section-header'>JPJ Enquiry Status</h2>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Blacklisted</p>
                                <p style={styles.value}>
                                    {jpjInquiryError?.inquiryStatus === JPJ_INQUIRY_STATUS.BLACKLISTED
                                        ? jpjInquiryError.inquiryMSG
                                        : 'No'
                                    }
                                </p>

                            </Col>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Ownership Claim</p>
                                <p style={styles.value}>
                                    {jpjInquiryError?.inquiryStatus === JPJ_INQUIRY_STATUS.OWNERSHIP_CLAIM
                                        ? jpjInquiryError.inquiryMSG
                                        : 'No'
                                    }
                                </p>

                            </Col>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Condition Code</p>
                                <p style={styles.value}>
                                    {jpjInquiryError?.inquiryStatus === JPJ_INQUIRY_STATUS.CONDITION
                                        ? jpjInquiryError.inquiryMSG
                                        : 'No'
                                    }
                                </p>
                            </Col>
                        </Row>
                        {/* <Row gutter={16}>
                            <div className='detail-wrapper'>
                                <span>Enquiry History</span>
                                <p>
                                    {jpjInquiryError?.inquiryStatus === JPJ_INQUIRY_STATUS.USAGE
                                        ? jpjInquiryError.inquiryMSG
                                        : 'None'
                                    }
                                </p>
                            </div>
                        </Row> */}
                        <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                                <p style={styles.label}>Valid as at</p>
                                <p style={styles.value}> {parseTime(transferDetails?.createdDate) ?? 'N/A'}</p>
                            </Col>
                        </Row>
                    </Card>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 10,
                        }}
                    >

                        <Card
                            style={{
                                width: '50%',
                                textAlign: 'left',
                                marginTop: 10, boxShadow: '0px 3px 6px #00000029',
                                borderRadius: 4
                            }}
                        >
                            <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Owner's Details</h2>
                            {
                                isLoading ? (
                                    <Skeleton active />
                                ) : (
                                    <>
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.seller.name || transferDetails?.sellerInfo.name}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.seller.identificationNo || transferDetails?.sellerInfo.identificationNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{sellerFormattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isSellerMale ? 'Male' : 'Female'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>
                                            {transferId == null ? (
                                                parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label, districtName: sellerDistrictName })
                                            ) : (
                                                parseAddress({ ...transferDetails?.sellerInfo, state: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo.state)?.label, districtName: sellerDistrictName })
                                            )}
                                        </p>
                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>
                                            {transferId == null ? (
                                                props.seller.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.seller, correspondentState: Object.values(STATES).find(s => s.code === props.seller.correspondentState)?.label, districtName: sellerCorrespondenceDistrictName })
                                                    : parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label, districtName: sellerDistrictName })
                                            ) : (
                                                transferDetails?.sellerInfo.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...transferDetails?.sellerInfo, correspondentState: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo.correspondentState)?.label, districtName: sellerCorrespondenceDistrictName })
                                                    : parseAddress({ ...transferDetails?.sellerInfo, state: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo.state)?.label, districtName: sellerDistrictName })
                                            )}
                                        </p> */}
                                        <p style={styles.label}>Email</p>
                                        <p style={styles.value}>{props.seller.correspondentEmail || transferDetails?.sellerInfo.correspondentEmail}</p>
                                        <p style={styles.label}>Mobile Number</p>
                                        <p style={styles.value}>{props.seller.correspondentMobileNo || transferDetails?.sellerInfo.correspondentMobileNo}</p>
                                    </>
                                )
                            }
                        </Card>
                        <Card
                            style={{
                                width: '50%',
                                textAlign: 'left',
                                marginTop: 10,
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: 4
                            }}
                        >
                            <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Dealer's Details</h2>
                            <p style={styles.label}>Company Name</p>
                            <p style={styles.value}>{company.coName}</p>
                            <p style={styles.label}>Company ROC number</p>
                            <p style={styles.value}>{company.coRegNo}</p>
                            <p style={styles.label}>Company Address</p>
                            <p style={styles.value}>{parseAddress({ ...company.companyAddress, state: Object.values(STATES).find(s => s.code === company?.companyAddress?.stateCode)?.label })}</p>
                            <p style={styles.label}>Company e-mail</p>
                            <p style={styles.value}>{company.email}</p>
                            <p style={styles.label}>Company Phone Number</p>
                            <p style={styles.value}>{company.contactNo}</p>
                            <p style={styles.label}>Dealer Representative</p>
                            <p style={styles.value}>{user.name || transferDetails?.buyerInfo.repName}</p>
                            <p style={styles.label}>Representative's NRIC</p>
                            <p style={styles.value}>{user.nric || transferDetails?.buyerInfo.repIc}</p>
                            <p style={styles.label}>Representative's Mobile Number</p>
                            <p style={styles.value}>{user.mobileno || transferDetails?.buyerInfo.phoneNo}</p>
                        </Card>
                    </div>
                    {/* <Card
                style={{
                    backgroundColor: '#FFFFBB', // Set the background color
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: 8,
                    marginTop: 16,
                    textAlign: 'inherit',
                }}
            >
                <Space>
                    <WarningIcon style={{ color: '#2B61C4' }} width={24} />
                    <span style={{ font: 'normal normal normal 16px Open Sans', marginLeft: 8 }}>
                        <b>Road Tax will be cancelled.</b> Please be reminded to apply for a new insurance policy and road tax as soon as possible.
                    </span>
                </Space>
            </Card> */}
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginTop: 16,
                            marginBottom: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={failedIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <Space direction='vertical' size={0}>
                                <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ verification failed</h2>
                                <p style={{ font: 'normal normal normal 16px Open Sans', margin: 0 }} dangerouslySetInnerHTML={{ __html: jpjInquiryError?.inquiryMSG }}></p>
                            </Space>
                        </div>
                    </Card>


                    <div
                        style={{ marginTop: 20 }}
                    >
                        {
                            <Row
                                style={{
                                    width: '100%', justifyContent: 'space-between'
                                }}
                            >
                                <Button
                                    className='back-button buttons'
                                    shape='round'
                                    onClick={() => {
                                        if (locationState?.oldIsReport) {
                                            if (locationState?.fromPendingPage === true) {
                                                history.push('/reporting/transferreport/In')
                                            } else {
                                                history.push('/reporting/transferreport')
                                            }
                                        } else {
                                            history.push('/')
                                        }
                                    }}
                                >
                                    Back
                                </Button>

                                <Space>
                                    {isReport == false &&
                                        <Button
                                            size='large'
                                            style={{
                                                width: 200,
                                                background: " #2B61C4 0% 0% no-repeat padding-box",
                                                boxShadow: "0px 4px 0px #1E4489",
                                                borderRadius: "8px",
                                                borderColor: 'transparent'
                                            }}
                                            onClick={() => onJPJSubmit()}
                                            type='primary'
                                        >
                                            Resubmit
                                        </Button>
                                    }
                                </Space>
                            </Row>

                        }
                    </div>
                </div >
            )
        },
    }

    const currentStep = COMPONENT_STEPS[currentSubStep]

    function onJPJSubmit() {

        history.push(history.location.pathname, {
            ...locationState,
            step: STEPS.JPJ_SUBMISSION.label,
            subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_CHECKING,
        })

        setIsLoading(true)

        let trfId = locationState.transferId ? locationState.transferId : transferId

        if (trfId) {

            InTransferInquiryWithJPJ(trfId)
                .then((res) => {

                    if (res.inquiryStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) {

                        if (res.inquiryMSG == 'UNKNOW ERROR') {
                            let alteredRes = {
                                "saveSellerStatus": null,
                                "saveSellerMSG": null,
                                "transferId": null,
                                "inquiryStatus": "99",
                                "inquiryMSG": "Unable to process JPJ submission"
                            }
                            setJpjInquiryError(alteredRes)
                        } else {

                            setJpjInquiryError(res)

                        }

                        // Point to JPJ_FAILED substep if transaction is not successful
                        history.push(history.location.pathname, {
                            ...locationState,
                            step: STEPS.JPJ_SUBMISSION.label,
                            subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
                        })

                    } else {

                        history.push(`/transfer/in/payment/${trfId}`)

                    }

                }).catch((err) => {

                    err && message.error(err.toString())

                }).finally(() => setIsLoading(false))

        }
        //  else {
        //     /**
        //      * 31.12.2021 daniel.kwok
        //      * seller.sellerType === vehicle.ownerIdCat
        //      */
        //     // props.seller.sellerType = Number(props.vehicle.ownerIdCat)

        //     let districtCode

        //     if (props?.seller?.district) {
        //         sellerDistricts
        //             .filter(district => {

        //                 return district?.districtName.toUpperCase() === props.seller?.district.toUpperCase()

        //             })
        //             .map(district => {
        //                 districtCode = district.districtCode
        //             })
        //     }

        //     let correspondentDistrictCode

        //     if (props?.seller?.correspondentDistrict) {
        //         sellerCorrespondenceDistricts
        //             .filter(district => {

        //                 return district?.districtName.toUpperCase() === props.seller?.correspondentDistrict.toUpperCase()

        //             })
        //             .map(district => {
        //                 correspondentDistrictCode = district.districtCode
        //             })
        //     }

        //     // let buyerCorrespondentDistrictCode

        //     // if (company.companyAddress?.district || company.companyAddress?.city) {

        //     //     buyerCorrespondenceDistricts
        //     //         .filter(district => {

        //     //             if (company.companyAddress?.district) {

        //     //                 return district?.districtName.toUpperCase() === company.companyAddress?.district.toUpperCase()

        //     //             } else if (company.companyAddress?.city) {

        //     //                 return district?.districtName.toUpperCase() === company.companyAddress?.city.toUpperCase()
        //     //             }

        //     //         })
        //     //         .map(district => {
        //     //             buyerCorrespondentDistrictCode = district.districtCode
        //     //         })
        //     // }

        //     const inSaveSellerProceedRequest: InSaveSellerProceedRequest = {
        //         branchId: branch.id,
        //         buyerCorrespondentAddress1: "",
        //         buyerCorrespondentAddress2: "",
        //         buyerCorrespondentAddress3: "",
        //         // buyerCorrespondentDistrict: buyerCorrespondentDistrictCode,
        //         buyerCorrespondentDistrict: !company.companyAddress?.district ? company.companyAddress?.city : company.companyAddress?.district,
        //         buyerCorrespondentEmail: "",
        //         buyerCorrespondentMobileNo: "",
        //         buyerCorrespondentPostcode: "",
        //         buyerCorrespondentState: "",
        //         currentLoginUserName: user.username,
        //         dealerCompanyROC: company.coRegNo,
        //         sellerInfo: {
        //             address1: props?.seller?.address1,
        //             address2: props?.seller?.address2,
        //             address3: props?.seller?.address3,
        //             correspondentAddress1: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentAddress1 : props?.seller?.address1,
        //             correspondentAddress2: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentAddress2 : props?.seller?.address2,
        //             correspondentAddress3: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentAddress3 : props?.seller?.address3,
        //             correspondentDistrict: props?.seller?.isAddressDifferent === true ? correspondentDistrictCode : districtCode,
        //             correspondentEmail: props?.seller?.correspondentEmail,
        //             correspondentMobileNo: props?.seller?.correspondentMobileNo,
        //             correspondentPostcode: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentPostcode : props?.seller?.postcode,
        //             correspondentState: props?.seller?.isAddressDifferent === true ? props?.seller?.correspondentState : props?.seller?.state,
        //             dateOfBirth: props?.seller?.dateOfBirth,
        //             district: districtCode,
        //             email: props?.seller?.email,
        //             gender: props?.seller?.gender,
        //             identificationNo: props?.seller?.identificationNo,
        //             name: props?.seller?.name,
        //             phoneNo: props?.seller?.phoneNo,
        //             mobileNo: props?.seller?.mobileNo,
        //             postcode: props?.seller?.postcode,
        //             repIc: props?.seller?.repIc,
        //             repIc2: props?.seller?.repIc2,
        //             repName: props?.seller?.repName,
        //             sellerType: Number(props.vehicle.ownerIdCat),
        //             state: props?.seller?.state,
        //         },
        //         vehicleInfo: {
        //             carMake: props?.vehicle?.carMake,
        //             carModel: props?.vehicle?.carModel,
        //             chassisNo: props?.vehicle?.chassisNo,
        //             engineNo: props?.vehicle?.engineNo,
        //             isWhiteListVehicle: props?.vehicle?.isWhiteListVehicle,
        //             lkmoption: props?.vehicle?.lkmoption,
        //             manufactureYear: props?.vehicle?.manufactureYear,
        //             ownerIdCat: props?.vehicle?.ownerIdCat,
        //             vehicleRegistrationNo: props?.vehicle?.vehicleRegistrationNo,
        //             vehicleType: props?.vehicle?.vehicleType
        //         }
        //     }

        //     isAccountExist(inSaveSellerProceedRequest?.sellerInfo?.identificationNo)
        //         .then(res => {

        //             if (res.status === false) {

        //                 const { formattedDob, isMale } = breakdownIc(inSaveSellerProceedRequest?.sellerInfo?.identificationNo)
        //                 let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

        //                 return create1MIDAccount(
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentAddress1,
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentAddress2,
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentAddress3,
        //                     localFormattedDob,
        //                     '',
        //                     '',
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentDistrict,
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentEmail,
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentEmail,
        //                     isMale ? "MALE" : "FEMALE",
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentMobileNo,
        //                     inSaveSellerProceedRequest?.sellerInfo?.name,
        //                     inSaveSellerProceedRequest?.sellerInfo?.identificationNo,
        //                     false,
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentMobileNo,
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentPostcode,
        //                     '',
        //                     '',
        //                     inSaveSellerProceedRequest?.sellerInfo?.correspondentState
        //                 )

        //             } else if (res.status === true) {

        //                 return {
        //                     status: true
        //                 }

        //             } else {
        //                 throw res.message
        //             }

        //         }).then(res => {

        //             if (!res.status) {

        //                 let msg = res.message

        //                 if ('Sorry, an account already exists with email address - ' === res.message) {
        //                     msg += inSaveSellerProceedRequest?.sellerInfo?.correspondentEmail
        //                 }


        //                 let inSaveSellerProceedResponse: InSaveSellerProceedResponse = {
        //                     inquiryMSG: msg,
        //                     inquiryStatus: '',
        //                     saveSellerMSG: '',
        //                     saveSellerStatus: '',
        //                     transferId: 0,
        //                     message: ''
        //                 }

        //                 setJpjInquiryError(inSaveSellerProceedResponse)

        //                 throw msg
        //             }

        //             return inSaveSellerProceed(inSaveSellerProceedRequest)

        //         }).then(res => {

        //             if (res.saveSellerStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.saveSellerMSG ?? res.message

        //             if (res.inquiryStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) {

        //                 setJpjInquiryError(res)

        //                 if (res.transferId != null) {

        //                     setTransferId(res.transferId)
        //                     getAndSetTransfer(res.transferId)

        //                 }

        //                 history.push(history.location.pathname, {
        //                     ...locationState,
        //                     step: STEPS.JPJ_SUBMISSION.label,
        //                     subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
        //                 })

        //             } else {

        //                 history.push(`/transfer/in/payment/${res.transferId}`)

        //             }

        //         }).catch(err => {

        //             err && message.error(err?.toString())

        //             history.push(history.location.pathname, {
        //                 ...locationState,
        //                 step: STEPS.JPJ_SUBMISSION.label,
        //                 subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
        //             })

        //         }).finally(() => setIsLoading(false))

        // }
    }

    function getAndSetTransfer(transferId: number) {
        setIsLoading(true)

        const getInTransferDetailRequest: GetInTransferDetailRequest = {
            transferId, moduleType : "IN"
        }
        getInTransferByTransferId(getInTransferDetailRequest)
            .then(res => {
                if (res.id === undefined) throw res.message
                if(res.status === 1) {
                    message.warning("Redirect to printslip")
                    history.push(`/transfer/in/printslip/${res.id}`)
                }

                setTransferDetails(res)

                if (res.sellerInfo?.state && res.sellerInfo?.district) {

                    getDistrictName(res.sellerInfo?.state, res.sellerInfo?.district)
                        .then((res) => {
                            setSellerDistrictName(res)
                        })

                }

                if (res.sellerInfo?.correspondentState && res.sellerInfo?.correspondentDistrict) {

                    getDistrictName(res.sellerInfo.correspondentState, res.sellerInfo.correspondentDistrict)
                        .then((res) => {
                            setSellerCorrespondenceDistrictName(res)
                        })

                }
            })
            .catch(err => {
                err && message.error(err)
                setIsTransactionDetailFailed(true)
            })
            .finally(() => setIsLoading(false))
    }
    /**
     * If transfer store no longer loading,
     * and no transfer is found
     */
    if (
        !isLoading
        && isTransactionDetailFailed
    ) {
        return (
            <Card
                style={{
                    height: '50%',
                    width: '100%'
                }}
            >
                <h1>Unknown transfer</h1>
                <a href='/'>Go back to home</a>
            </Card>
        )
    }

    return (
        <>
            {!isLoading && currentStep.component}
        </>

    )
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0, fontFamily: 'Open Sans', fontSize: '16px'
    },
    value: {
        fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '16px'
    }
}

export default JPJSubmission