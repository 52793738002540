import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom"; import NotFoundPage from '../../pages/NotFoundPage'
import LoginPage from '../../pages/LoginPage'
import ForgotPasswordPage from '../../pages/ForgotPasswordPage'
import ResetPasswordPage from '../../pages/ResetPasswordPage'
import background from '../../assets/layered-waves-haikei.svg'
import ActivateAccountPage from "../../pages/ActivateAccountPage";

export default function UnauthenticatedRoutes() {
    return (
        <Switch>
            <Route
                exact
                path="/login"
                component={LoginPage}
            />

            <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
            />
            {/* 
            dev parameter is to force token to be valid. Should only be used locally
            */}
            <Route
                exact
                path="/forgot-password/:token/:dev?"
                component={ResetPasswordPage}
            />
            <Route
                exact
                path="/activate-account/:token/:dev?"
                component={ActivateAccountPage}
            />
            <Route
                path='*'
                component={NotFoundPage}
            />
        </Switch>
    );
}