import React, { useEffect, useContext, useState } from 'react'
import {
    Card,
    Col,
    Row,
    Button,
    message,
    Modal,
    Skeleton,
    Space,
    Image
} from 'antd'
import './index.less'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    CloseCircleOutlined,
} from '@ant-design/icons'
import {
    breakdownIc,
    parseAddress,
    parseTime,
    parseCorrespondenceAddress,
    getDistrictName,
    getDecryptedVerifyOtpResponse
} from '../../util'
import propTypes from 'prop-types'
import {
    getOutTransferAmountByTransferId,
    getOutTransferByTransferId,
    processPaymentOut,
    outUpdateVehicleDetailsToJPJ,
    verifyApproverOTP,
    verifyApproverSmsOtp,
    saveOutSellerBuyerInquiry,
} from '../../services/api'
import {
    TRANSACTION_RESPONSE_CODES,
    OUT_TRANSACTION_TYPES_IDS,
    STATES,
    APP_CONST,
    TRANSACTION_OUT_RESPONSE_CODES,
} from '../../constants'
import { SessionContext } from '../../App'
import PaymentSubmissionModal from './paymentSubmission_modal'
import successIcon from "../../assets/icon-success.svg";
import { ReactComponent as WarningIcon } from '../../assets/icon-warning-red-28.svg';
import {useShortcut, onApprovalSubmit, ModuleType} from '../../components/Utils'
import failedIcon from "../../assets/icon-failed.svg";
import {
    getCompanyId,
} from '../../services/local'

const TRANSFER_PAYMENT_STATUSES = {
    PENDING: 'P', //TODO daniel.k change to 'P' instead of empty string. Set as such to work around BE bug at time
    REFUNDED: 'R',
    SUCCESS: 'S'
}

function Payment(props) {

    const { isReport } = props

    const history = useHistory()
    const params = useParams()
    const { company, user } = useContext(SessionContext)
    const { state: locationState } = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [isPaymentLoading, setIsPaymentLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [transfer, setTransfer] = useState(null)
    const [payments, setPayments] = useState([])
    const transferId = Number(params.transferId)
    const [sellerDistrictName, setSellerDistrictName] = useState('')
    const [sellerCorrespondenceDistrictName, setSellerCorrespondenceDistrictName] = useState('')
    const [buyerDistrictName, setBuyerDistrictName] = useState('')
    const [buyerCorrespondenceDistrictName, setBuyerCorrespondenceDistrictName] = useState('')
    const [isIncorrectOtp, setIsIncorrectOtp] = useState(false)
    const [isApprovalRejected, setIsApprovalRejected] = useState(false)
    {useShortcut(setIsModalVisible)}

    useEffect(() => {
        getAndSetTransfer();

    }, [transferId])

    function getAndSetTransfer() {
        setIsLoading(true)

        Promise.all([
            getOutTransferAmountByTransferId(transferId),
            getOutTransferByTransferId(transferId, "OUT", getCompanyId()),
        ]).then(res => {

            if (!res) throw `Something went wrong`
            const [resAmount, resTransfer] = res
            if (resTransfer.id === undefined) throw res.message
            if(resTransfer.status === 4) {
                message.warning("Redirect to printslip")
                history.push(`/transfer/out/printslip/${transferId}`)
            }

            setTransfer({
                id: resTransfer.id,
                status: resTransfer.status,
                paymentStatus: resTransfer.paymentStatus,
                sellerNRIC: resTransfer.sellerNRIC,
                sellerName: resTransfer.sellerName,
                transferDate: resTransfer.transferDate,
                updatedDate: resTransfer.updatedDate,
                createdDate: resTransfer.createdDate,
                transactionType: resTransfer.transactionType,
                repName: resTransfer.sellerInfo?.repName,
                repIc: resTransfer.sellerInfo?.repIc,
                carRegistrationNo: resTransfer.carRegistrationNo,
                chassisNo: resTransfer.chassisNo,
                engineNo: resTransfer.engineNo,
                // carMake: resTransfer.make,
                // carModel: resTransfer.model,
                // manufactureYear: resTransfer.manufactureYear,

                ownerCatagory: resTransfer.ownerCatagory,
                vehicleTypeId: resTransfer.vehicleTypeId,
                sellerInfo: resTransfer.sellerInfo,
                buyerInfo: resTransfer.buyerInfo,
                fisStatus: resTransfer.fisStatus,

                "eserviceAmount": resAmount.eserviceAmount,
                "fisAmount": resAmount.fisAmount,
                "fisEserviceAmount": resAmount.fisEserviceAmount,
                "jpjAmount": resAmount.jpjAmount,
                "name": resAmount.name,
                "totalAmount": resAmount.totalAmount,
                "sstAmount": resAmount.sstAmount,
                "processingAmount": resAmount.processingAmount

            })

            if (resTransfer.buyerInfo?.state && resTransfer.buyerInfo?.district) {

                getDistrictName(
                    resTransfer.buyerInfo?.state,
                    resTransfer.buyerInfo?.district
                ).then((res) => {
                    setBuyerDistrictName(res)
                })

            }

            if (resTransfer.buyerInfo?.correspondentState && resTransfer.buyerInfo?.correspondentDistrict) {

                getDistrictName(
                    resTransfer.buyerInfo?.correspondentState,
                    resTransfer.buyerInfo?.correspondentDistrict
                ).then((res) => {
                    setBuyerCorrespondenceDistrictName(res)
                })

            }

            if (resTransfer.sellerInfo?.state && resTransfer.sellerInfo?.district) {

                getDistrictName(
                    resTransfer.sellerInfo?.state,
                    resTransfer.sellerInfo?.district
                ).then((res) => {
                    setSellerDistrictName(res)
                })

            }

            if (resTransfer.sellerInfo?.correspondentState && resTransfer.sellerInfo?.correspondentDistrict) {

                getDistrictName(
                    resTransfer.sellerInfo?.correspondentState,
                    resTransfer.sellerInfo?.correspondentDistrict
                ).then((res) => {
                    setSellerCorrespondenceDistrictName(res)
                })

            }


        })
            .catch(err => err && message.error(err))
            .finally(() => setIsLoading(false))
    }

    /* function permanentTransferInquiry (trfId) {
        saveOutSellerBuyerInquiry(user.nric, trfId).then(res => {

            if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) throw res.statusMSG

            message.success(res.statusMSG)
            return TRANSACTION_OUT_RESPONSE_CODES.SUCCESS
        }).catch(err => {
            if (err) {
                Modal.error({
                    content: err?.toString()
                })
            }
            return "99"
        }).finally(() => {})
    } */

    function onPaymentSubmit () {

        const processPayment = () => {
            processPaymentOut(company.coRegNo, transfer.id)
            .then(res => {
                if (res.status !== "true") throw res.message
                const updatedTransferDetails = {
                    ...transfer,
                    paymentStatus: TRANSFER_PAYMENT_STATUSES.SUCCESS,
                    status: 7,
                };

                setTransfer(updatedTransferDetails);

                return outUpdateVehicleDetailsToJPJ(transfer.id)
            })
            .then(res => {
                if (res.statusCode !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.statusMSG
                message.success(`Successfully updated to JPJ`)
                history.push(`/transfer/out/printslip/${transferId}`)

            })
            .catch(err => {
                if(err === "Fail to process prepaid payment.") {
                    Modal.error({
                        content: <div>You have insufficient prepaid balance. Please <a href={process.env.REACT_APP_RELOAD_URL} target="_blank">click here</a> to top up, and the amount will be automatically credited to your prepaid account.</div>
                    })
                }
                else {
                    Modal.error({
                        content: err?.toString()
                    })
                }
            })
            .finally(() => setIsPaymentLoading(false))
        }

        const inquiryResponse = async () => {
            saveOutSellerBuyerInquiry(user.nric, transfer.id).then(async res => {

                if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) {
                    throw res.statusMSG
                }
                await processPayment()
            }).catch(err => {
                if (err) {
                    Modal.error({
                        content: err?.toString()
                    })
                }
                setIsPaymentLoading(false)
            }).finally(() => {})
        };

        inquiryResponse()
    }

    const updateToJPJ = () => {
        setIsPaymentLoading(true)

        const processUpdateJpj = () => {
            message.loading('Proceeding update JPJ')
    
            outUpdateVehicleDetailsToJPJ(transfer.id).then(res => {
    
                if (res.statusCode !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.statusMSG
    
                message.success(`Successfully updated to JPJ`)
                history.push(`/transfer/out/printslip/${transferId}`)
    
            }).catch(err => {
                err && message.error(err?.toString())
            }).finally(() => setIsPaymentLoading(false))
        }

        const inquiryResponse = async () => {
            saveOutSellerBuyerInquiry(user.nric, transfer.id).then(async res => {

                if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) {
                    throw res.statusMSG
                }
                await processUpdateJpj()
            }).catch(err => {
                if (err) {
                    Modal.error({
                        content: err?.toString()
                    })
                }
                setIsPaymentLoading(false)
            }).finally(() => {})
        };

        inquiryResponse()
    }

    /**
     * If transfer store no longer loading,
     * and no transfer is found
     */
    if (
        !isLoading
        && !transfer
    ) {
        return <></>
        /* return (
            <Card
                style={{
                    height: '50%',
                    width: '100%',
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: 4
                }}
            >
                <h1>Unknown transfer</h1>
                <a
                    onClick={() => history.push('/')}
                >Go back to home</a>
            </Card>
        ) */
    }

    const { formattedDob: buyerFormattedDob, isMale: buyerIsMale } = breakdownIc(transfer?.buyerInfo?.identificationNo)
    const { formattedDob: sellerFormattedDob, isMale: sellerIsMale } = breakdownIc(transfer?.sellerInfo?.identificationNo)

    return (

        <div
            style={{
                width: '100%',
            }}
        >
            <PaymentSubmissionModal
                isIncorrectOtp={isIncorrectOtp}
                transactionType={transfer?.transactionType}
                isModalVisible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={(approverIc, authCode, isPush, appApprovalResponse) => {

                    if (isPush) {
                        setIsModalVisible(false)
                        if (appApprovalResponse.isRejected) {
                            setIsApprovalRejected(true)
                        }
                        else {
                            setIsApprovalRejected(false)
                            onPaymentSubmit()
                        }
                        // history.push(`/transfer/out/printslip/${transferId}`)
                    } else if (APP_CONST.USE_OTP === true) {
                        setIsPaymentLoading(true)
                        verifyApproverSmsOtp(approverIc, authCode)
                            .then(res => {
                                if(!res.encryptedString) {
                                    let errorMsg = res.message ? res.message : "ERROR"
                                    throw errorMsg
                                }
                                const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)
                                if (decryptedResponse.status == 'false') {
                                    setIsIncorrectOtp(true)
                                    throw decryptedResponse.message
                                }
                                setIsIncorrectOtp(false)
                                setIsModalVisible(false)
                                onPaymentSubmit()
                            })
                            .catch(err => {
                                setIsPaymentLoading(false)
                                setIsIncorrectOtp(true)
                                err && message.error(err)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })
                    } else {
                        setIsPaymentLoading(true)
                        verifyApproverOTP(approverIc, authCode)
                            .then(res => {
                                if (res.status !== true) {
                                    setIsIncorrectOtp(true)
                                    throw res.message
                                }
                                setIsIncorrectOtp(false)
                                setIsModalVisible(false)
                                onPaymentSubmit()
                            })
                            .catch(err => {
                                setIsPaymentLoading(false)
                                setIsIncorrectOtp(true)
                                err && message.error(err)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })
                    }

                }
                }
                paymentLoading={isPaymentLoading}
            />

            { 
                isApprovalRejected ? (
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={failedIcon} alt="failed icon" preview={false} style={{ transform: 'scale(0.6)' }} />
                            <Space direction='vertical' size={0}>
                                <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >Approval Unsuccessful</h2>
                            </Space>
                        </div>
                    </Card>
                ) : 
                transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.REFUNDED ? (
                    <div>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: 4

                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CloseCircleOutlined
                                    style={{
                                        color: 'red',
                                        fontSize: 70,
                                        marginRight: 50,
                                    }}
                                />
                                <div style={{ flex: 4 }}>
                                    <h2 style={{ color: 'red', display: 'inline' }} >Payment Failed. </h2>
                                    <h2 onClick={() => {
                                        // setIsModalVisible(true)
                                        onApprovalSubmit(setIsModalVisible,
                                            setIsPaymentLoading,
                                            transfer?.transactionType,
                                            onPaymentSubmit,
                                            company,
                                            ModuleType.OUT
                                        )
                                    }} style={{ color: 'red', display: 'inline', textDecoration: 'underline', cursor: 'pointer' }} >Try again?</h2>
                                    <p>{payments.message || `Payment failed`}</p>
                                </div>
                            </div>
                        </Card>
                    </div>
                ) : transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.PENDING || transfer?.paymentStatus == '' ? (
                    <div>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '4px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                                <div style={{ display: 'flex', flex: 4, alignItems: 'center' }}>
                                    <h2 style={{ color: '#40e327a8', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ checking completed</h2>
                                </div>
                            </div>
                        </Card>
                    </div>
                ) : null
            }

            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Payment Details</h2>

                    {
                        isLoading ? (
                            <Skeleton active />
                        ) : (

                            <Row gutter={16}>
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>JPJ Ownership Transfer Fee</p>
                                    <p style={styles.value}>RM {transfer && transfer.jpjAmount.toFixed(2)}</p>
                                </Col>
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>MYEG Service Charge</p>
                                    <p style={styles.value}>RM {transfer?.fisStatus == true ? (transfer && (transfer.eserviceAmount + transfer.fisEserviceAmount)) : (transfer && transfer.eserviceAmount)}</p>
                                </Col>
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>SST 8%</p>
                                    <p style={styles.value}>RM {transfer && transfer.sstAmount.toFixed(2)}</p>
                                </Col>
                                {
                                    transfer?.fisStatus == true &&
                                    <Col className="gutter-row" span={5}>
                                        <p style={styles.label}>Ownership Claimed Fee</p>
                                        <p style={styles.value}>RM {transfer && transfer.fisAmount.toFixed(2)}</p>
                                    </Col>
                                }
                                {transfer?.processingAmount && <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>Processing Fee</p>
                                    <p style={styles.value}>RM {transfer && transfer?.processingAmount.toFixed(2)}</p>
                                </Col>}
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>Total Payment</p>
                                    <p style={styles.value}>RM {transfer && transfer.totalAmount.toFixed(2)}</p>
                                </Col>
                            </Row>
                        )
                    }
                </Card>
            </div>
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Vehicle Information</h2>
                    {
                        isLoading ? (
                            <Skeleton active />

                        ) : (
                            <div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Vehicle Number</p>
                                        <p style={styles.value}>{transfer?.carRegistrationNo}</p>
                                    </Col>
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Make</p>
                                        <p style={styles.value}>{transfer?.carMake}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Model</p>
                                        <p style={styles.value}>{transfer?.carModel}</p>
                                    </Col> */}
                                {/* </Row>
                                <Row gutter={16}> */}
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Manufacture Year</p>
                                        <p style={styles.value}>{transfer?.manufactureYear}</p>
                                    </Col> */}
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Engine Number</p>
                                        <p style={styles.value}>{transfer?.engineNo}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Chassis Number</p>
                                        <p style={styles.value}>{transfer?.chassisNo}</p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Card>
            </div>
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>JPJ Enquiry Status</h2>
                    {
                        isLoading ? (
                            <Skeleton active />

                        ) : (
                            <div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Blacklisted</p>
                                        <p style={styles.value}>No</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Ownership Claim</p>
                                        <p style={styles.value}>No</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Condition Code</p>
                                        <p style={styles.value}>No</p>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Valid as at</p>
                                        <p style={styles.value}> {parseTime(transfer?.updatedDate)}</p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Card>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 10,
                }}
            >

                <>
                    {
                        /**
                         * 9/3/2022 daniel.kwok
                         * Show individual information if it's i2i or i2c
                         * Show company information if it's d2i or d2c
                         */
                        <Card
                            style={{
                                flex: 3,
                                textAlign: 'left',
                                marginTop: 10,
                                maxWidth: '50%',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: 4
                            }}
                        >

                            {

                                (
                                    transfer?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2C
                                    ||
                                    transfer?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2I
                                ) ? (
                                    <>
                                        <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Seller Details</h2>
                                        {
                                            isLoading ? (
                                                <Skeleton active />

                                            ) : (
                                                <>

                                                    <p style={styles.label}>Name</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.name}</p>
                                                    <p style={styles.label}>NRIC</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.identificationNo}</p>

                                                    <p style={styles.label}>Date of Birth</p>
                                                    <p style={styles.value}>{transfer?.sellerInfo?.dob || sellerFormattedDob}</p>
                                                    <p style={styles.label}>Gender</p>
                                                    <p style={styles.value}>{sellerIsMale ? 'Male' : 'Female'}</p>
                                                    <p style={styles.label}>Address</p>
                                                    <p style={styles.value}>{parseAddress({ ...transfer?.sellerInfo, state: Object.values(STATES).find(s => s.code === transfer?.sellerInfo?.state)?.label, districtName: sellerDistrictName })}</p>
                                                </>
                                            )
                                        }
                                    </>
                                ) :
                                    <>
                                        <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Dealer's Details</h2>
                                        {
                                            isLoading ? (
                                                <Skeleton active />

                                            ) : (
                                                <>
                                                    <p style={styles.label}>Company name</p>
                                                    <p style={styles.value}>{company.coName}</p>
                                                    <p style={styles.label}>Company ROC number</p>
                                                    <p style={styles.value}>{company.coRegNo}</p>
                                                    <p style={styles.label}>Company Address</p>
                                                    <p style={styles.value}>{parseAddress({ ...company?.companyAddress, state: Object.values(STATES).find(s => s.code === company?.companyAddress?.stateCode)?.label })}</p>
                                                    <p style={styles.label}>Company e-mail</p>
                                                    <p style={styles.value}>{company.email}</p>
                                                    <p style={styles.label}>Company Phone Number</p>
                                                    <p style={styles.value}>{company.contactNo}</p>
                                                    {/* <p style={styles.label}>Dealer Representative</p>
                                                    <p style={styles.value}>{branch.branchContactName}</p>
                                                    <p style={styles.label}>Representative's NRIC</p>
                                                    <p style={styles.value}>{user.nric}</p>
                                                    <p style={styles.label}>Representative's Mobile Number</p>
                                                    <p style={styles.value}>{branch.branchContactNo}</p> */}
                                                </>
                                            )
                                        }
                                    </>
                            }
                        </Card>
                    }
                    <Card
                        style={{
                            flex: 3,
                            textAlign: 'left',
                            marginTop: 10,
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 4
                        }}
                    >
                        <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Buyer's Details</h2>

                        {
                            isLoading ? (
                                <Skeleton active />

                            ) : (
                                <>

                                    <p style={styles.label}>Name</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.name}</p>
                                    <p style={styles.label}>NRIC</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.identificationNo}</p>

                                    <p style={styles.label}>Date of Birth</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.dob || buyerFormattedDob}</p>
                                    <p style={styles.label}>Gender</p>
                                    <p style={styles.value}>{buyerIsMale ? 'Male' : 'Female'}</p>
                                    <p style={styles.label}>Address</p>
                                    <p style={styles.value}>{parseAddress({ ...transfer?.buyerInfo, state: Object.values(STATES).find(s => s.code === transfer?.buyerInfo?.state)?.label, districtName: buyerDistrictName })}</p>
                                    {/* <p style={styles.label}>Current Address</p>
                                    <p style={styles.value}>{parseCorrespondenceAddress({ ...transfer?.buyerInfo, correspondentState: Object.values(STATES).find(s => s.code === transfer?.buyerInfo?.correspondentState)?.label, districtName: buyerCorrespondenceDistrictName })}</p> */}
                                    <p style={styles.label}>Email</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.email}</p>
                                    <p style={styles.label}>Mobile Number</p>
                                    <p style={styles.value}>{transfer?.buyerInfo?.phoneNo}</p>
                                </>
                            )
                        }
                    </Card>
                </>
            </div>
            <Card
                style={{
                    backgroundColor: '#FFFFBB', // Set the background color
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: 8,
                    marginTop: 16,
                    textAlign: 'inherit',
                }}
            >
                <Space>
                    <WarningIcon style={{ color: '#2B61C4' }} width={24} />
                    <span style={{ font: 'normal normal normal 16px Open Sans', marginLeft: 8 }}>
                        <b>Road Tax will be cancelled.</b> Please be reminded to apply for a new insurance policy and road tax as soon as possible.
                    </span>
                </Space>
            </Card>
            {
                transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.REFUNDED ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 20
                        }}
                    >
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                if (locationState?.oldIsReport) {
                                    if (locationState?.fromPendingPage === true) {
                                        history.push('/reporting/transferreport/Out')
                                    } else {
                                        history.push('/reporting/transferreport')
                                    }
                                } else {
                                    history.push('/')
                                }
                            }}
                        >
                            {locationState?.oldIsReport ? "Back" : "Home"}
                        </Button>
                        {isReport == false &&
                            <Button
                                type='primary'
                                shape={'round'}
                                onClick={() => {
                                    // setIsModalVisible(true)
                                    onApprovalSubmit(setIsModalVisible,
                                        setIsPaymentLoading,
                                        transfer?.transactionType,
                                        onPaymentSubmit,
                                        company,
                                        ModuleType.OUT
                                    )
                                }}
                            >
                                Try again
                            </Button>
                        }
                    </div>
                ) : transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.PENDING ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 20,
                        }}
                    >
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                if (locationState?.oldIsReport) {
                                    if (locationState?.fromPendingPage === true) {
                                        history.push('/reporting/transferreport/Out')
                                    } else {
                                        history.push('/reporting/transferreport')
                                    }
                                } else {
                                    history.push('/')
                                }
                            }}
                        >
                            {locationState?.oldIsReport ? "Back" : "Home"}
                        </Button>

                        {isReport == false &&
                            <Button
                                type='primary'
                                className='rectangle-button'
                                onClick={() => {
                                    // setIsModalVisible(true)
                                    onApprovalSubmit(setIsModalVisible,
                                        setIsPaymentLoading,
                                        transfer?.transactionType,
                                        onPaymentSubmit,
                                        company,
                                        ModuleType.OUT
                                    )
                                }}
                                loading={isPaymentLoading}
                            >
                                Proceed to Payment
                            </Button>
                        }
                    </div>
                ) : transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.SUCCESS ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 20,
                        }}
                    >
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                if (locationState?.oldIsReport) {
                                    if (locationState?.fromPendingPage === true) {
                                        history.push('/reporting/transferreport/Out')
                                    } else {
                                        history.push('/reporting/transferreport')
                                    }
                                } else {
                                    history.push('/')
                                }
                            }}
                        >
                            {locationState?.oldIsReport ? "Back" : "Home"}
                        </Button>

                        {isReport === false ? transfer?.status === 7 ?
                            <Button
                                type='primary'
                                className='rectangle-button'
                                onClick={() => { updateToJPJ() }}
                                loading={isPaymentLoading}
                            >
                                Update JPJ
                            </Button> :
                            <Button
                                type='primary'
                                className='rectangle-button'
                                onClick={() => {
                                    // setIsModalVisible(true)
                                    onApprovalSubmit(setIsModalVisible,
                                        setIsPaymentLoading,
                                        transfer?.transactionType,
                                        onPaymentSubmit,
                                        company,
                                        ModuleType.OUT
                                    )
                                }}
                                loading={isPaymentLoading}
                            >
                                Proceed to Payment
                            </Button> : <></>
                        }
                    </div>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 20,
                        }}
                    >
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                if (locationState?.oldIsReport) {
                                    if (locationState?.fromPendingPage === true) {
                                        history.push('/reporting/transferreport/Out')
                                    } else {
                                        history.push('/reporting/transferreport')
                                    }
                                } else {
                                    history.push('/')
                                }
                            }}
                        >
                            {locationState?.oldIsReport ? "Back" : "Home"}
                        </Button>

                        {isReport === false && transfer?.status === 3 ?
                            <Button
                                type='primary'
                                className='rectangle-button'
                                onClick={() => {
                                    // setIsModalVisible(true)
                                    onApprovalSubmit(setIsModalVisible,
                                        setIsPaymentLoading,
                                        transfer?.transactionType,
                                        onPaymentSubmit,
                                        company,
                                        ModuleType.OUT
                                    )
                                }}
                                loading={isPaymentLoading}
                            >
                                Proceed to Payment
                            </Button> :
                            <></>
                        }
                    </div>
                )
            }
        </div>
    )
}

Payment.propTypes = {
    seller: propTypes.object,
    dealer: propTypes.object,
    vehicle: propTypes.object,
    approverNric: propTypes.string,
}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
        marginBottom: 8
    },
    value: {
        font: 'normal normal 600 16px Open Sans',
        color: '#333333'
    }
}

export default Payment