import {
    LoadingOutlined
} from '@ant-design/icons'
import {
    Button,
    Card,
    Col, Divider, Form,
    Image,
    Input, message,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Checkbox
} from 'antd'
import propTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { SessionContext } from '../../App'
import red_thumbprint from '../../assets/illus-thumb-fail.svg'
import green_thumbprint from '../../assets/illus-thumb-success.svg'
import thumbprint from '../../assets/thumbprint.jpg'
import other_branch from '../../assets/illus-other-branch.png'
import same_branch from '../../assets/illus-same-branch.png'
import DeviceReader from '../../components/DeviceReader'
import {
    addBranchUser,
    create1MIDAccount,
    forgotPassword,
    getAccountDetails,
    getRoles,
    getRolesByUsername,
    isAccountExist,
    logout,
    sendSmsOtp,
    updateBranchUser,
    updateEmail,
    updatePhoneNumber,
    verifySmsOtp,
    verifyApproverSmsOtp
} from '../../services/api'
import {
    breakdownIc, parseAddress, getState, getDecryptedVerifyOtpResponse
} from '../../util'
import './index.less'
import {
    STEPS
} from './steps'
import arrow_back from '../../assets/arrow_back.svg'
import { HQ_BRANCH, STATES, STATES_FULLNAME } from '../../constants'
import { useForm } from 'antd/lib/form/Form'
import {
    removeCachedStates,
    removeUserRole,
    getUserRole,
    setCachedAddStaff,
    getCachedAddStaff,
    removeCachedAddStaff
} from '../../services/local'
import moment from 'moment'

function AuthorizeBranchUser(props) {

    const { branch, branchs, company, user } = useContext(SessionContext)
    const history = useHistory()

    const [step, setStep] = useState(STEPS.AUTHORIZE_BRANCH_USER.label)
    const [subStep, setSubStep] = useState(STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF)
    const [roles, setRoles] = useState([])
    const [branchList, setBranchList] = useState([])
    const { formattedDob, isMale } = breakdownIc(props.branchUser?.identificationNo)
    const [isEditable, setIsEditable] = useState(props?.isEdit)
    const [isEditableAccountDetail, setIsEditableAccountDetail] = useState(props?.isEdit)
    const [disableEmailField, setDisableEmailField] = useState(false)
    const [deviceReaderForKey, setDeviceReaderForKey] = useState()
    const [showChangeEmailModal, setShowChangeEmailModal] = useState(false)
    const [showChangeNoModal, setShowChangeNoModal] = useState(false)
    const [emailPhoneForm] = useForm()
    const [selectBranchForm] = useForm()
    const [otherBranchFormPersonalDetails] = useForm()
    const [otherBranchFormAccountDetails] = useForm()
    const [changeEmailForm] = Form.useForm()
    const [changeNoForm] = Form.useForm()
    const [userRoles, setUserRoles] = useState('')
    const [jpjUserRoles, setJPJUserRoles] = useState('')
    const [branchId, setBranchId] = useState(props.branchUser?.branchId)
    const [showBranchSelection, setShowBranchSelection] = useState(false)
    const [addUserLoading, setAddUserLoading] = useState(false)
    const [editUserLoading, setEditUserLoading] = useState(false)
    const [changeEmailLoading, setChangeEmailLoading] = useState(false)
    const [changeNoLoading, setChangeNoLoading] = useState(false)
    const [smsProvider, setSmsProvider] = useState(1)
    const [loggedInUserRole, setLoggedInUserRole] = useState('')
    const [nameInputValue, setNameInputValue] = useState('')

    useEffect(() => {
        getUserRole()
            .then(res => {
                setLoggedInUserRole(res)
            })
    }, [])

    useEffect(() => {

        if (props) {

            if (!props?.branchUser?.email || props?.branchUser?.email === '') setDisableEmailField(false)
            else setDisableEmailField(true)
        }

    }, [props])

    useEffect(() => {

        if (props.branchUser.isEditable) {

            if (props?.branchUser?.isEditable == true) {

                if (user.nric == props.branchUser?.identificationNo) {

                    setIsEditable(false)
                    setIsEditableAccountDetail(true)
                } else {

                    setIsEditable(true)
                    setIsEditableAccountDetail(true)
                }
            } else {

                if (user.nric == props.branchUser?.identificationNo) {

                    setIsEditable(false)
                    setIsEditableAccountDetail(true)
                } else {

                    setIsEditable(false)
                    setIsEditableAccountDetail(false)
                }

            }
        } else {

            if (user.nric == props.branchUser?.identificationNo) {

                setIsEditable(false)
                setIsEditableAccountDetail(true)

            } else {

                if (!props?.isEdit) {

                    setIsEditable(true)
                } else {

                    setIsEditable(false)
                    setIsEditableAccountDetail(false)
                }

            }
        }

    }, [props])
    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    // The state for our timer
    const [minutes, setMinutes] = useState('00')
    const [seconds, setSeconds] = useState('00')
    const [otpTimeout, setOtpTimeout] = useState(0)

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        // const minstosecs = Math.floor((total / 1000));
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setMinutes((minutes > 9 ? minutes : '0' + minutes))
            setSeconds((seconds > 9 ? seconds : '0' + seconds))
        } else if (total < 0) {
            if (otpTimeout !== 0) {
                setOtpTimeout(0)
            }
        }
    }

    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        setMinutes('0' + otpTimeout)
        setSeconds('00')

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        // deadline.setSeconds(deadline.getSeconds() + 10)
        deadline.setMinutes(deadline.getMinutes() + otpTimeout)
        return deadline;
    }

    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    const sendOtp = () => {
        const { newPhoneNo } = changeNoForm.getFieldsValue()

        const pattern = /^(01)[0-46-9][0-9]{7,8}$/

        if (!pattern.test(newPhoneNo)) {
            message.error('Please enter a valid phone number')
            return
        }

        if (props.branchUser.mobileNo === newPhoneNo) {

            message.error('The new phone number cannot be the same as the current phone number.')
            return
        }

        sendSmsOtp(props.branchUser.identificationNo, smsProvider).then(res => {
            if (!res.status) throw res.message
            // if (res.status !== true) throw res.message

            message.success(res.message)
            // setOtpTimeout(3)

        }).catch(err => err && message.error(err.toString()))
    }

    const resetChangeNoForm = () => {
        changeNoForm.resetFields()
        setOtpTimeout(0)
        setSmsProvider(1)
    }

    useEffect(() => {
        onClickReset()
    }, [otpTimeout])

    const onChangeNo = () => {

        setChangeNoLoading(true)

        const { authCode, newPhoneNo } = changeNoForm.getFieldsValue()

        verifyApproverSmsOtp(
            props.branchUser.identificationNo,
            authCode)
            .then(res => {

                const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)
                if (decryptedResponse.status == 'false') throw decryptedResponse.message

                return updatePhoneNumber(props.branchUser.identificationNo, newPhoneNo)

            })
            .then(res => {

                if (!res.status) throw res.message

                message.success("Phone number changed successfully.")
                history.push("/usermanagement")

            })
            .catch(err => {

                err && message.error(err.toString())

            })
            .finally(() => {
                setOtpTimeout(0)
                setShowChangeNoModal(false)
                setChangeNoLoading(false)

                changeNoForm.resetFields()

            })
    }

    // update email
    // get roles, then update branch user email also
    const onChangeEmail = () => {

        setChangeEmailLoading(true);

        const { currentEmail, newEmail, password, confirmNewEmail } = changeEmailForm.getFieldsValue();

        let _newEmail = newEmail.trim()
        let _comfirmEmail = confirmNewEmail.trim()

        if (_newEmail != _comfirmEmail) {

            message.error('The new email and confirmation email do not match.')

            setChangeEmailLoading(false)
            return
        }

        const shouldLogout = currentEmail == user.username;

        updateEmail(currentEmail, password, 'false', _newEmail)
            .then((res) => {
                if (res.status !== true) {
                    message.error(res.message);
                    return;
                }
                getRolesByUsername(props.branchUser.identificationNo)
                    .then((rolesResponse) => {
                        const userRoles = rolesResponse.roles.map((role) => role.name)
                        updateBranchUser(
                            branch.id,
                            company.id,
                            props.branchUser.mobileno,
                            _newEmail,
                            props.branchUser.identificationNo,
                            props.branchUser.id,
                            props.branchUser.personName,
                            userRoles
                        )
                            .then((res) => {
                                message.success("Email changed successfully.");
                                setShowChangeEmailModal(false);
                                changeEmailForm.resetFields();

                                history.push("/usermanagement");

                                shouldLogout &&
                                    logout()
                                        .then(() => {
                                            removeUserRole();
                                            window.location.pathname = "/login";
                                        })
                                        .catch((err) => err && message.error(err));
                            })
                            .catch((err) => err && message.error(err));
                    })
                    .catch((err) => err && message.error(err));
            })
            .catch((err) => err && message.error(err))
            .finally(() => setChangeEmailLoading(false));
    };

    const sendPasswordResetLink = () => {
        forgotPassword(props.branchUser?.email)
            .then(res => {
                if (res.status !== true) {
                    message.error(res.message)
                    return
                }
                message.success(res.message)
            })
            .catch((err) => err && message.error(err))
    }

    const handleSelectBranchForm = () => {
        const { targetBranchId } = selectBranchForm.getFieldsValue()
        let branchId = Array.isArray(targetBranchId) ? targetBranchId[0] : targetBranchId
        setShowBranchSelection(false)
        setBranchId(branchId)
    }

    const onChangeNric = () => {

        const { newUserNric } = otherBranchFormPersonalDetails.getFieldsValue()

        if (newUserNric.length === 12 || newUserNric.length === 13) {

            getAccountDetails(newUserNric).then(res => {

                const { mobileno, name, username } = res

                if (name) {

                    otherBranchFormPersonalDetails.setFieldsValue({
                        newUserName: name
                    })
                } else {
                    otherBranchFormPersonalDetails.resetFields([
                        'newUserName',
                    ])
                }

                if (mobileno) {

                    otherBranchFormAccountDetails.setFieldsValue({
                        newUserPhoneNo: mobileno
                    })
                } else {

                    otherBranchFormAccountDetails.resetFields([
                        'newUserPhoneNo'
                    ])
                }

                if (username) {

                    otherBranchFormAccountDetails.setFieldsValue({
                        newUserEmail: username
                    })

                    setDisableEmailField(true)

                } else {

                    otherBranchFormAccountDetails.resetFields([
                        'newUserEmail'
                    ])

                    setDisableEmailField(false)
                }

            })
            // .catch(err => err && message.error(err.toString()))

            const { formattedDob, isMale } = breakdownIc(newUserNric)

            otherBranchFormPersonalDetails.setFieldsValue({
                newUserGender: isMale ? 'MALE' : 'FEMALE',
                newUserDobDay: moment(formattedDob).format('DD'),
                newUserDobMonth: moment(formattedDob).format('MM'),
                newUserDobYear: moment(formattedDob).format('YYYY')
            })

        } else {

            otherBranchFormPersonalDetails.resetFields([
                'newUserName',
                'newUserGender',
                'newUserDobDay',
                'newUserDobMonth',
                'newUserDobYear'
            ])

            otherBranchFormAccountDetails.resetFields([
                'newUserEmail',
                'newUserPhoneNo'
            ])

            setDisableEmailField(false)

        }
    }

    const handleAddNewUserRemote = () => {

        const { newUserName,
            newUserNric,
            newUserGender,
            newUserDobDay,
            newUserDobMonth,
            newUserDobYear } = otherBranchFormPersonalDetails.getFieldsValue()

        const {
            newUserEmail,
            newUserPhoneNo,
            newUserBranchId } = otherBranchFormAccountDetails.getFieldsValue()

        let _newUserEmail = newUserEmail.trim()
        let roles = []
        userRoles && roles.push(userRoles)

        if (jpjUserRoles.length > 0) {

            for (let jpjUserRole of jpjUserRoles) {
                roles.push(jpjUserRole)
            }
        }

        let localFormattedDob = newUserDobDay + "-" + newUserDobMonth + "-" + newUserDobYear

        if (user.nric == newUserNric) {

            message.error('You are not allowed to add yourself as a staff member.')

            // setTimeout(() => {
            //     history.push('/usermanagement')
            // }, 1000)
            setAddUserLoading(false)
            return

        }

        if (userRoles == 'GENERAL_MANAGER') {

            setAddUserLoading(false)

            setDeviceReaderForKey('ownerUser')

            let cachedAddStaff = {
                newUserName: newUserName,
                newUserNric: newUserNric,
                newUserGender: newUserGender,
                newUserEmail: _newUserEmail,
                newUserPhoneNo: newUserPhoneNo,
                newUserBranchId: newUserBranchId,
                roles: roles,
                localFormattedDob: localFormattedDob,
                companyId: company.id,
                companyRegNo: company.coRegNo,
                otherBranchAddUser: true
            }

            setCachedAddStaff(JSON.stringify(cachedAddStaff))

            history.push(history.location.pathname, {
                ...history.location.state,
                step: STEPS.AUTHORIZE_BRANCH_USER.label,
                subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
            })

        } else {

            isAccountExist(newUserNric)
                .then((res) => {

                    if (res.status === false) {

                        return create1MIDAccount(//create 1mid account with dummy
                            'dummyAddres1',
                            '',
                            '',
                            localFormattedDob,
                            '',
                            '',
                            'dummyCity',
                            _newUserEmail,
                            _newUserEmail,
                            newUserGender,
                            newUserPhoneNo,
                            newUserName,
                            newUserNric,
                            true,
                            newUserPhoneNo,
                            '99999',
                            '',
                            '',
                            '12')

                    } else if (res.status === true) {

                        return {
                            status: true
                        }

                    } else {
                        throw res.message
                    }

                })
                .then(res => {

                    if (!res.status) {

                        let msg = res.message

                        if ('Sorry, an account already exists with email address - ' === res.message) {
                            msg += _newUserEmail
                        }

                        throw msg
                    }

                    return getAccountDetails(newUserNric)//get 1mid account details

                })
                .then(res => {

                    const { nric, name } = res

                    return addBranchUser(//create new etukar user
                        newUserBranchId,
                        company.id,
                        newUserPhoneNo,
                        _newUserEmail,
                        nric.replace(/[\s*]/g, ""),
                        name,
                        roles)

                })
                .then(res => {
                    if (!res.status) throw (!res.details || res.details.length === 0) ? res.message : res.details.join(', ');
                    message.success(res.message);
                    history.push("/usermanagement");
                })
                .catch((err) => {

                    err && message.error(err)

                })
                .finally(() => {
                    setAddUserLoading(false)
                })

        }
    }

    const handleNameChange = (event) => {
        setNameInputValue(event.target.value.toUpperCase())
        otherBranchFormPersonalDetails.setFieldsValue({
            newUserName: event.target.value.toUpperCase()
        })
    };

    const handleAddNewUser = (props) => {

        const {
            address1,
            address2,
            address3,
            dob,
            birthplace,
            birthPlace,
            citizenship,
            city,
            district,
            gender,
            personName,
            name,
            identificationNo,
            postcode,
            race,
            religion,
            state,
        } = props.branchUser

        const { newUserEmail,
            newUserPhoneNo,
            newUserBranchId
        } = emailPhoneForm.getFieldsValue()

        let _newUserEmail = newUserEmail.trim()
        let roles = []
        userRoles && roles.push(userRoles)

        if (jpjUserRoles.length > 0) {
            for (let jpjUserRole of jpjUserRoles) {
                roles.push(jpjUserRole)
            }
        }

        let stateCode

        stateCode = parseInt(state) ? state : Object.values(STATES).find(s => s.label.replaceAll(" ", "").toLowerCase() == state.replaceAll(" ", "").toLowerCase())?.code

        let localFormattedDob
        localFormattedDob = moment(new Date(formattedDob)).format("DD-MM-YYYY")

        if (userRoles == 'GENERAL_MANAGER') {

            setAddUserLoading(false)

            setDeviceReaderForKey('ownerUser')

            let cachedAddStaff = {
                address1: address1,
                address2: address2,
                address3: address3,
                birthplace: birthplace,
                citizenship: citizenship,
                city: city ?? district,
                newUserName: personName ?? name,
                newUserNric: identificationNo,
                newUserGender: isMale ? "MALE" : "FEMALE",
                newUserEmail: _newUserEmail,
                newUserPhoneNo: newUserPhoneNo,
                newUserBranchId: newUserBranchId,
                roles: roles,
                localFormattedDob: localFormattedDob,
                companyId: company.id,
                companyRegNo: company.coRegNo,
                otherBranchAddUser: false,
                stateCode: stateCode,
                postcode: postcode,
                race: race,
                religion: religion,
            }

            setCachedAddStaff(JSON.stringify(cachedAddStaff))

            history.push(history.location.pathname, {
                ...history.location.state,
                step: STEPS.AUTHORIZE_BRANCH_USER.label,
                subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
            })

        } else {

            isAccountExist(identificationNo)
                .then((res) => {

                    if (res.status === false) {

                        return create1MIDAccount(//create 1mid account with dummy
                            address1,
                            address2,
                            address3,
                            localFormattedDob, // done this way to ensure dob is correct, as it is reading from NRIC
                            birthplace ?? birthPlace, // birthplace for sagem, birthPlace for dermalog
                            citizenship,
                            city ?? district, // city for sagem, district for dermalog
                            _newUserEmail,
                            _newUserEmail,
                            isMale ? "MALE" : "FEMALE", // done this way because dermalog returns "L" / "P"
                            newUserPhoneNo,
                            personName ?? name, // personName for sagem, name for dermalog
                            identificationNo,
                            false,
                            newUserPhoneNo,
                            postcode,
                            race,
                            religion,
                            stateCode)

                    } else if (res.status === true) {

                        return {
                            status: true
                        }

                    } else {
                        throw res.message
                    }

                })
                .then(res => {

                    if (!res.status) {

                        let msg = res.message

                        if ('Sorry, an account already exists with email address - ' === res.message) {
                            msg += _newUserEmail
                        }

                        throw msg
                    }

                    return getAccountDetails(identificationNo)//get 1mid account details

                })
                .then(res => {

                    const { nric, name } = res

                    return addBranchUser(//create new etukar user
                        newUserBranchId,
                        company.id,
                        newUserPhoneNo,
                        _newUserEmail,
                        nric.replace(/[\s*]/g, ""),
                        name,
                        roles)

                })
                .then(res => {
                    if (!res.status) throw (!res.details || res.details.length === 0) ? res.message : res.details.join(', ');
                    message.success(res.message);
                    history.push("/usermanagement");
                })
                .catch((err) => {

                    err && message.error(err)

                })
                .finally(() => {
                    setAddUserLoading(false)
                })

        }
    }

    // if from index is to index, return same roles array
    // else 
    // remove item from index "from", and add it to index "to"
    const rearrangeRoles = (from, to, ...allRoles) =>
        from === to ? allRoles : (allRoles.splice(to, 0, ...allRoles.splice(from, 1)), allRoles);


    const rolesMap = {
        OWNER: { title: "Owner", description: ["Can do everything"], required: "" },
        VALUER: {
            title: "Level 1 (Valuer)",
            description: ["Perform IN transactions only", "No payment allowed"],
            required: "",
        },
        SALES: {
            title: "Level 2 (Sales)",
            description: ["Perform IN, I2I, OUT & M2M Transaction until JPJ Submission / Inquiry Only", "Able to make IN payment only"],
            required: "",
        },
        ADMIN: {
            title: "Level 3 (Admin)",
            description: [
                "Perform IN, I2I, OUT & M2M Transaction until JPJ Submission / Inquiry only",
                "⁠Able to make IN & I2I payment only",
                "⁠Able to perform Inter-Branch transfer",
            ],
            required: "",
        },
        SALES_MANAGER: {
            title: "Level 4 (Sales Manager)",
            description: [
                "Perform IN, I2I, OUT & M2M Transaction until JPJ Submission / inquiry",
                "⁠Able to make IN & I2I payment only",
                "Able to Submit / Approve M2M to buyer",
                "⁠Able to Add Users",
                "Able to perform Inter-Branch transfer",
            ],
            required: "",
        },
        GENERAL_MANAGER: {
            title: "Level 5 (General Manager)",
            description: ["Perform IN, i2i, OUT & M2M transactions", "Able to make ALL payment*"],
            required: "",
        },
        JPJ_APPROVER: {
            title: "JPJ Approver",
            description: "",
            required: "",
        },
        JPJ_REPRESENTATIVE: {
            title: "JPJ Representative",
            description: "",
            required: "",
        },
        MYEG_RUNNER_2: {
            title: "Partner (Runner)",
            description: ["Perform i2i transactions", "Able to make i2i payment*"],
            required: "",
        },
        MYEG_RUNNER_1: {
            title: "Assistant (Runner)",
            description: ["Perform i2i transactions"],
            required: "",
        }
    };

    const ADD_STAFF_IDS = {
        SAME_BRANCH: 1,
        OTHER_BRANCH: 2
    }

    const ADD_STAFF_TYPES = {
        [ADD_STAFF_IDS.SAME_BRANCH]: {
            id: ADD_STAFF_IDS.SAME_BRANCH,
            label: 'On Site',
            desc: 'Verify thumbprint now',
            image: same_branch,
            disabled: false,
        },
        [ADD_STAFF_IDS.OTHER_BRANCH]: {
            id: ADD_STAFF_IDS.OTHER_BRANCH,
            label: 'Remote',
            desc: 'Verify thumbprint later',
            image: other_branch,
            disabled: false,
        }
    }

    const COMPONENT_STEPS = {
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF]: {
            component: (

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}
                >
                    <h2 style={{ fontWeight: 'bold', font: 'normal normal normal 24px/33px Open Sans', color: '#FFFFFF', }}>Please select your application type</h2>

                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        {
                            [
                                ADD_STAFF_IDS.SAME_BRANCH,
                                ADD_STAFF_IDS.OTHER_BRANCH
                            ].map(id => {
                                const type = ADD_STAFF_TYPES[id]
                                return (
                                    <Card
                                        className={'ant-card-hover'}
                                        style={{
                                            cursor: 'pointer',
                                            margin: 5,
                                            background: ' #FFFFFF 0% 0% no-repeat padding-box',
                                            boxShadow: "0px 3px 6px #00000029",
                                            borderRadius: '8px',
                                            minWidth: '388px'
                                        }}
                                        onClick={() => {

                                            setDeviceReaderForKey('branchUser')

                                            if (type.id === ADD_STAFF_IDS.SAME_BRANCH) {
                                                history.push(history.location.pathname, {
                                                    ...history.location.state,
                                                    step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                                    subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
                                                })
                                            } else {
                                                history.push(history.location.pathname, {
                                                    ...history.location.state,
                                                    step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                                    subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.OTHER_BRANCH_FORM
                                                })
                                            }
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                height: 150
                                            }}
                                        >
                                            <div
                                                style={{ flex: 3 }}
                                            >
                                                <img alt='' src={type.image} style={{ maxHeight: 100, mixBlendMode: '' }} />
                                            </div>

                                            <div
                                                style={{ flex: 3 }}
                                            >
                                                <h1
                                                    style={{
                                                        font: 'normal normal 600 24px Raleway',
                                                        color: '#16467D',
                                                        textAlign: 'left'
                                                    }}
                                                >{type.label}</h1>
                                                <h1
                                                    style={{
                                                        font: 'normal normal 600 16px Open Sans',
                                                        color: '#16467D',
                                                        textAlign: 'left'
                                                    }}
                                                >{type.desc}</h1>
                                            </div>
                                        </div>
                                    </Card>
                                )
                            })
                        }

                    </div>

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '5%'
                        }}
                    >
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                history.push('/usermanagement')
                            }}
                        >
                            <Image src={arrow_back} preview={false} />
                            BACK
                        </Button>
                    </div>

                </div>


            )
        },
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC]: {
            component: (
                <div>

                    {
                        deviceReaderForKey == 'branchUser' &&
                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify new user identity
                        </h1>}
                    {
                        deviceReaderForKey == 'ownerUser' &&
                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify owner identity
                        </h1>
                    }


                    <p>Please insert identity card</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERTING_IC]: {
            component: (
                <div>
                    {
                        deviceReaderForKey == 'branchUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify new user identity
                        </h1>

                    }

                    {
                        deviceReaderForKey == 'ownerUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify Owner identity
                        </h1>

                    }

                    <p>retrieving data...</p>
                    <LoadingOutlined />
                </div>
            )
        },
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_BEFORE]: {
            component: (
                <div>

                    {
                        deviceReaderForKey == 'branchUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify new user identity
                        </h1>
                    }

                    {
                        deviceReaderForKey == 'ownerUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify Owner identity
                        </h1>
                    }

                    <p>Thumbprint Verification</p>
                    <img src={thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Please wait for the red light to disappear
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINTING]: {
            component: (
                <div>
                    {
                        deviceReaderForKey == 'branchUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify new user identity
                        </h1>
                    }

                    {
                        deviceReaderForKey == 'ownerUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify Owner identity
                        </h1>
                    }

                    <p>Thumbprint Verification</p>
                    <LoadingOutlined
                        style={{ marginBottom: 20 }}
                    />
                    <p style={{ color: 'red' }}>
                        Please wait for the red light to disappear
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_SUCCESS]: {
            component: (
                <div>

                    {
                        deviceReaderForKey == 'branchUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify new user identity
                        </h1>
                    }

                    {
                        deviceReaderForKey == 'ownerUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify Owner identity
                        </h1>
                    }


                    <p>Thumbprint Verification</p>
                    <img src={green_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'green' }}>
                        Verified!
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_FAILED]: {
            component: (
                <div>

                    {
                        deviceReaderForKey == 'branchUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify new user identity
                        </h1>
                    }

                    {
                        deviceReaderForKey == 'ownerUser' &&

                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            Verify Owner identity
                        </h1>
                    }

                    <p>Thumbprint Verification</p>
                    <img src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Failed. Try again?
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.SUMMARY]: {
            component: (
                <div>
                    <Card
                        style={{
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px'
                        }}
                    >
                        <h2 style={{ textAlign: 'left', font: 'normal normal 600 24px Raleway' }}>Identity Card Details</h2>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%'
                            }}
                        >
                            <img src={`data:image/png;base64, ${props.branchUser?.photo}`} height='200px' style={{ marginRight: '10%' }} />
                            <div style={{ flex: 1, textAlign: 'left' }}>
                                <Space direction="vertical">
                                    <p style={styles.label}>Name</p>
                                    <p style={styles.label}>NRIC</p>
                                    <p style={styles.label}>Date of Birth</p>
                                    <p style={styles.label}>Gender</p>
                                    <p style={styles.label}>Address</p>
                                </Space>
                            </div>
                            <div style={{ flex: 4, textAlign: 'left' }}>
                                <Space direction='vertical'>
                                    <p style={styles.value}>{props.branchUser?.name || props.branchUser?.personName}</p>
                                    <p style={styles.value}>{props.branchUser?.identificationNo}</p>
                                    <p style={styles.value}>{formattedDob}</p>
                                    <p style={styles.value}>{isMale ? 'MALE' : 'FEMALE'}</p>
                                    <p style={styles.value}>{parseAddress({ ...props.branchUser, state: parseInt(props.branchUser?.state) ? Object.values(STATES).find(s => s.code === props.branchUser?.state)?.label : props.branchUser?.state })}</p>
                                </Space>
                            </div>
                        </div>
                    </Card>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between', marginTop: 40
                        }}
                    >
                        <Button
                            shape='round'
                            className='back-button buttons'
                            style={{ backgroundColor: "#F0F2F5" }}
                            onClick={() => {
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                    subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
                                })
                            }}
                        >
                            <Image src={arrow_back} preview={false} />
                            Back
                        </Button>
                        <Button
                            size='large'
                            style={{
                                width: 200,
                                background: " #2B61C4 0% 0% no-repeat padding-box",
                                boxShadow: "0px 4px 0px #1E4489",
                                borderRadius: "8px",
                                borderColor: 'transparent'
                            }}
                            onClick={() => {
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                    subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM
                                })
                            }}
                            type='primary'
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM]: {
            component: (
                <>
                    <Row gutter={[16, 16]} style={{ alignItems: "stretch" }}>
                        <Col span={12}>
                            <Card className='form-background' style={{ height: '100%' }} >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <h2 style={{ fontWeight: 'bold' }}>Personal Details</h2>
                                    <div
                                        style={{ textAlign: 'left' }}
                                    >
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.branchUser?.name || props.branchUser?.personName}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.branchUser?.identificationNo || props.branchUser?.nricNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{formattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isMale ? 'MALE' : 'FEMALE'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.branchUser, state: parseInt(props.branchUser?.state) ? Object.values(STATES).find(s => s.code === props.branchUser?.state)?.label : props.branchUser?.state })}</p>
                                    </div>
                                </div >
                            </Card>
                        </Col>
                        <Col span={12}>
                            {props?.isEdit ? (
                                <Card className="form-background" style={{ textAlign: "left", height: "100%" }}>
                                    <h2>Account Detail</h2>
                                    <Row style={{ display: "flex", alignItems: "center" }}>
                                        <Col span={12}>
                                            <p style={styles.label}>Email as Login ID</p>
                                            <p style={{ marginBottom: 0, fontWeight: "bold", wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                                {props.branchUser?.username}
                                            </p>
                                        </Col>

                                        {
                                            isEditable ||
                                                isEditableAccountDetail ?

                                                <Col span={12} style={{ textAlign: "right" }}>
                                                    <Button type="link" onClick={() => setShowChangeEmailModal(true)}>CHANGE USERNAME/E-MAIL</Button>
                                                </Col>
                                                : <></>
                                        }

                                    </Row>
                                    <Divider style={{ borderTop: "1px solid #00000050" }} />
                                    <Row style={{ display: "flex", alignItems: "center" }}>
                                        <Col span={12}>
                                            <p style={styles.label}>Phone number</p>
                                            <p style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                {props.branchUser?.mobileno}
                                            </p>
                                        </Col>
                                        {
                                            isEditable ||
                                                isEditableAccountDetail ?

                                                <Col span={12} style={{ textAlign: "right" }}>
                                                    <Button type="link" onClick={() => setShowChangeNoModal(true)}>CHANGE NUMBER</Button>
                                                </Col>
                                                : <></>
                                        }
                                    </Row>
                                    {
                                        isEditable ?
                                            <>
                                                <Divider style={{ borderTop: "1px solid #00000050" }} />
                                                <Row style={{ display: "flex", alignItems: "center" }}>
                                                    <Col span={12}>
                                                        <span style={styles.label}>Password</span>
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: "right" }}>
                                                        <Button type="link" onClick={sendPasswordResetLink}>SEND PASSWORD RESET LINK</Button>
                                                    </Col>
                                                </Row>
                                            </> : <></>
                                    }
                                    <Divider style={{ borderTop: "1px solid #00000050" }} />
                                    <Row style={{ display: "flex", alignItems: "center" }}>
                                        <Col span={12} key={branch.id}>
                                            <p style={styles.label}>Branch</p>
                                            {branchList && branchList
                                                .filter((branch) => branch.id === branchId)
                                                .map((branch) => {
                                                    return (
                                                        <p style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                            {branch.branchName}
                                                        </p>
                                                    );
                                                })}

                                        </Col>
                                        {
                                            isEditable ?

                                                <Col span={12} style={{ textAlign: "right" }}>
                                                    <Button type="link" onClick={() => setShowBranchSelection(true)}>CHANGE BRANCH</Button>
                                                </Col>
                                                : <></>
                                        }
                                    </Row>
                                </Card>
                            ) : (
                                <Card className='form-background' style={{ textAlign: "left", height: "100%" }}>
                                    <Form form={emailPhoneForm} layout="vertical" scrollToFirstError requiredMark="optional" id='emailPhoneForm' initialValues={{
                                        newUserEmail: props.branchUser.username,
                                        newUserPhoneNo: props.branchUser.mobileNo,
                                        newUserBranchId: props.branchUser.branchId
                                    }}>
                                        <h2>Account Detail</h2>
                                        <Form.Item label="Email" style={{ marginBottom: 0, font: "italic normal normal 16px Open Sans" }} name="newUserEmail" rules={[{ required: true, message: "Please provide an email address." },
                                        { max: 50, message: 'Email must be maximum 50 characters.' }]}>
                                            <Input disabled={disableEmailField} className='form-input-field' style={{ marginBottom: "15px", width: "60%" }} type="email" />
                                        </Form.Item>
                                        <p style={styles.disclaimer}>User need to use this email to login.</p>
                                        <p style={styles.disclaimer}>Once an account is created, a link will be emailed to this user's email address above to set a password for his/her account.</p>
                                        <Form.Item label="Phone Number" style={styles.label} name="newUserPhoneNo" rules={[{ required: true, message: "Please provide a phone number" }, { pattern: /^(01)[0-46-9][0-9]{7,8}$/, message: "Please enter a valid phone number" }]}>
                                            <Input className='form-input-field' style={{ width: "60%" }} type="tel" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Branch"
                                            style={styles.label}
                                            name={"newUserBranchId"}
                                            rules={[{ required: true, message: "Please select a branch" }]}
                                        >
                                            <Select placeholder="Choose a branch"
                                                className='custom-select'
                                                style={{ width: "60%" }}>
                                                {branchList && branchList.filter(branch => branch.is_active ? branch.is_active === true : branch.is_hq === true).map((branch) => {
                                                    return (
                                                        <Select.Option key={branch.id} value={branch.id}>
                                                            {branch.branchName}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            )}
                        </Col>
                    </Row>

                    <Card className='form-background' style={{ textAlign: "left", margin: "16px 0px 25px 0px" }}>
                        <Row>
                            <Col span={12}><h2 style={{ fontWeight: 'bold' }}>{loggedInUserRole === 'MYEG_OWNER_RUNNER' ? 'eTukar Runner Role Permission' : 'eTukar Role Permission'}</h2></Col>
                            {/* <Col span={12}><h2 style={{ fontWeight: 'bold' }}>{loggedInUserRole === 'MYEG_OWNER_RUNNER' ? '' : 'MySikap Role (Not Mandatory)'}</h2></Col> */}
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Radio.Group
                                    value={userRoles || ''}
                                    onChange={e => {
                                        setUserRoles(e.target.value)
                                        props.onChange({
                                            ...props.branchUser,
                                            roles: Array(e.target.value, jpjUserRoles)
                                        })
                                    }}

                                    disabled={isEditable == true ? false : true}
                                    style={{ width: "100%" }}
                                >
                                    {roles.length > 0 && roles
                                        .filter((role) => !role.toLowerCase().includes('jpj') && !role.toLowerCase().includes('owner'))
                                        .map((role) => {
                                            return (
                                                <Radio className='form-input-field' style={{ width: "100%", marginBottom: "16px" }} key={role} value={role}>
                                                    <span style={{ font: 'normal normal 600 14px/24px Open Sans', color: '#333333' }}>{rolesMap[role].title}</span>
                                                    <ul style={{ paddingLeft: 14, marginBottom: 0, marginTop: 10 }}>
                                                        {rolesMap[role].description.map((description, index) => (
                                                            <li key={`${rolesMap[role].title}${index}`}>
                                                                {description}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <p style={{ fontStyle: "italic" }}>{rolesMap[role].required}</p>
                                                </Radio>
                                            );
                                        })}

                                </Radio.Group>
                            </Col>
                            <Col span={12}>

                                {/* <Checkbox.Group
                                    value={jpjUserRoles || ''}
                                    onChange={e => {
                                        setJPJUserRoles(e)
                                        props.onChange({
                                            ...props.branchUser,
                                            roles: Array(userRoles, e)
                                        })
                                    }}
                                    disabled={isEditable == true ? false : true}
                                    style={{ width: "100%" }}
                                >
                                    {roles
                                        .filter((role) => role.toLowerCase().includes("jpj"))
                                        .map((role) => (
                                            <Checkbox
                                                className='form-input-field'
                                                style={{ width: "100%", marginBottom: "16px", marginLeft: "0px" }}
                                                key={role}
                                                value={role}>
                                                <span style={{ font: 'normal normal 600 14px/24px Open Sans', color: '#333333' }}>{rolesMap[role].title}</span>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                            </Checkbox>
                                        ))}
                                </Checkbox.Group> */}

                            </Col>
                        </Row>
                    </Card>

                    {props?.isEdit && isEditable && isEditableAccountDetail ? <Button
                        className='add-new-user-button'
                        style={{ display: "flex", justifyContent: "start", marginBottom: 50, width: "auto" }}
                        type='primary'
                        loading={editUserLoading}
                        onClick={() => {

                            const { targetBranchId } = selectBranchForm.getFieldsValue()

                            let branchId

                            if (targetBranchId === undefined) {
                                branchId = props.branchUser?.branchId
                            } else {
                                branchId = Array.isArray(targetBranchId) ? targetBranchId[0] : targetBranchId
                            }

                            setEditUserLoading(true)

                            let compiledRoles = []
                            userRoles && compiledRoles.push(userRoles)

                            if (jpjUserRoles.length > 0) {
                                for (let jpjUserRole of jpjUserRoles) {
                                    compiledRoles.push(jpjUserRole)
                                }
                            }

                            if (userRoles == 'GENERAL_MANAGER') {

                                setEditUserLoading(false)

                                setDeviceReaderForKey('ownerUser')

                                //for UPDATE staff
                                let cachedUpdateStaff = {
                                    id: props.branchUser.id,
                                    personName: props.branchUser.personName,
                                    username: props.branchUser.username,
                                    nric: props.branchUser.identificationNo,
                                    email: props.branchUser.username,
                                    mobileno: props.branchUser.mobileno,
                                    branchId: branchId,
                                    roles: compiledRoles,
                                    companyId: props.branchUser.companyId,
                                    isEdit: true
                                }

                                //this cache storage apply for update staff also
                                setCachedAddStaff(JSON.stringify(cachedUpdateStaff))

                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                    subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
                                })

                            } else {

                                // Update existing phone number or role
                                updateBranchUser(
                                    branchId,
                                    props.branchUser.companyId,
                                    props.branchUser.mobileno,
                                    props.branchUser.username,
                                    props.branchUser.identificationNo,
                                    props.branchUser.id,
                                    props.branchUser.personName,
                                    compiledRoles
                                )
                                    .then((res) => {
                                        if (!res.status) throw (!res.details || res.details.length === 0) ? res.message : res.details.join(', ');
                                        message.success(res.message);
                                        history.push("/usermanagement");
                                    })
                                    .catch((err) => {
                                        err && message.error(err)
                                    })
                                    .finally(() => setEditUserLoading(false));

                            }

                        }}>
                        Save
                    </Button> : <></>}
                    <Row style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                if (props?.isEdit) {
                                    history.push('/usermanagement')
                                } else {
                                    history.push(history.location.pathname, {
                                        ...history.location.state,
                                        step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                        subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
                                    })
                                }
                            }}
                        >
                            <Image src={arrow_back} preview={false} />
                            BACK
                        </Button>
                        <Button
                            loading={addUserLoading}
                            className='add-new-user-button'
                            size='large'
                            style={{
                                visibility: props?.isEdit ? "hidden" : "visible",
                                width: 200,
                                marginBottom: 15,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            type='primary'
                            onClick={() => {
                                setAddUserLoading(true)
                                emailPhoneForm
                                    .validateFields(["newUserEmail", "newUserPhoneNo", "newUserBranchId"])
                                    .then(() => handleAddNewUser(props))
                                    .catch((err) => {
                                        setAddUserLoading(false)
                                        window.document.getElementById("emailPhoneForm").scrollIntoView()
                                        throw err;
                                    });
                            }}
                        >
                            Create Account
                        </Button>
                    </Row>
                    <Modal
                        visible={showBranchSelection}
                        footer={null}
                        onCancel={() => setShowBranchSelection(false)}
                        width={400}
                    >
                        <h2 style={{ fontWeight: "bold", textAlign: "center", margin: "30px 0px 20px 0px" }}>Change Branch</h2>
                        <Form
                            className='transfer-form'
                            form={selectBranchForm}
                            onFinish={() => handleSelectBranchForm()}
                            layout="vertical"
                            requiredMark={false}
                            id='selectBranchForm'
                            initialValues={{
                                targetBranchId: branchList && branchList
                                    .filter(branch => branch.is_active ? branch.is_active === true && branch.id === branchId : branch.is_hq === true && branch.id === branchId)
                                    .map(branch => branch.id)
                            }}
                        >
                            <Form.Item
                                label={"Branch"}
                                name={"targetBranchId"}
                                rules={[{ required: true, message: "Please select a branch" }]}
                            >
                                <Select placeholder="-- Select a branch --">
                                    {branchList && branchList.filter(branch => branch.is_active ? branch.is_active === true : branch.is_hq === true).map((branch) => {
                                        return (
                                            <Select.Option key={branch.id} value={branch.id}>
                                                {branch.branchName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    loading={addUserLoading}
                                    htmlType="submit"
                                    type="primary"
                                    className="add-new-user-button"
                                    style={{ width: "100%" }}
                                >
                                    {'Change Branch'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </>
            )
        },
        [STEPS.AUTHORIZE_BRANCH_USER.subSteps.OTHER_BRANCH_FORM]: {
            component: (
                <>
                    <Row gutter={[16, 16]} style={{ alignItems: "stretch" }}>
                        <Col span={12}>
                            <Card className='form-background' style={{ textAlign: "left", height: '100%' }} >
                                <Form
                                    layout={'vertical'}
                                    form={otherBranchFormPersonalDetails}
                                    scrollToFirstError
                                    requiredMark="optional"
                                    id='otherBranchFormPersonalDetails'
                                    initialValues={{
                                        newUserName: '',
                                        newUserNric: '',
                                        newUserGender: '',
                                        newUserDobDay: '',
                                        newUserDobMonth: '',
                                        newUserDobYear: ''
                                    }}>
                                    <h2>Personal Details</h2>

                                    <Form.Item
                                        label= {<div><b>Full Name</b> (as per MyKad)</div>}
                                        style={{ marginBottom: 0, font: "italic normal normal 16px Open Sans" }}
                                        name="newUserName"
                                        rules={[{ required: true, message: "Please provide user Name." }]}>
                                        <Input
                                            className='form-input-field'
                                            style={{ marginBottom: "15px", width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="text" 
                                            onChange={handleNameChange}
                                            value={nameInputValue}
                                            />
                                    </Form.Item>

                                    <Form.Item
                                        label="NRIC"
                                        style={{ marginBottom: 0, font: "italic normal normal 16px Open Sans" }}
                                        name="newUserNric"
                                        rules={[{ required: true, message: "Please provide user NRIC." }, { pattern: /^[+]?\d+([.]\d+)?[.,/]?$/, message: "Please enter a valid NRIC" }]}>
                                        <Input
                                            onChange={onChangeNric}
                                            className='form-input-field'
                                            style={{ marginBottom: "15px", width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="text" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Gender"
                                        style={{ font: "italic normal normal 16px Open Sans" }}
                                        name={"newUserGender"}
                                        rules={[{ required: true, message: "Please select Gender" }]}
                                    >
                                        <Radio.Group
                                            buttonStyle={'solid'}
                                            style={{ width: '100%' }}
                                        >
                                            <Radio
                                                className='custom-radio'
                                                style={{ marginRight: 0, width: '30%', font: "normal normal bold 14px Open Sans" }}
                                                value={'MALE'}>MALE</Radio>
                                            <Radio
                                                className='custom-radio'
                                                style={{ width: '30%', font: "normal normal bold 14px Open Sans" }}
                                                value={'FEMALE'} defaultChecked>FEMALE</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Row gutter={[16, 16]}>
                                        <Col span={5}>
                                            <Form.Item
                                                label="Day"
                                                style={styles.label}
                                                name={"newUserDobDay"}
                                                rules={[{ required: true, message: "Please provide a Day" }, { pattern: /(0[1-9]|[12]\d|3[01])/, message: "Please enter a valid Day" }]}>
                                                <Input
                                                    className='form-input-field'
                                                    style={{ font: "normal normal bold 14px Open Sans" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                label="Month"
                                                style={styles.label}
                                                name={"newUserDobMonth"}
                                                rules={[{ required: true, message: "Please provide a Month" }, { pattern: /^(0?[1-9]|1[012])$/, message: "Please enter a valid Month" }]}>
                                                <Input
                                                    className='form-input-field'
                                                    style={{ font: "normal normal bold 14px Open Sans" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                label="Year"
                                                style={styles.label}
                                                name={"newUserDobYear"}
                                                rules={[{ required: true, message: "Please provide a Year" }, { pattern: /^\d{4}$/, message: "Please enter a valid Year" }]}>
                                                <Input
                                                    className='form-input-field'
                                                    style={{ font: "normal normal bold 14px Open Sans" }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card className='form-background' style={{ textAlign: "left", height: "100%" }}>
                                <Form
                                    form={otherBranchFormAccountDetails}
                                    layout="vertical"
                                    scrollToFirstError
                                    requiredMark="optional"
                                    id='emailPhoneForm'
                                    initialValues={{
                                        newUserEmail: '',
                                        newUserPhoneNo: '',
                                        newUserBranchId: ''
                                    }}>
                                    <h2>Account Detail</h2>
                                    <Form.Item
                                        label="Email"
                                        style={{ marginBottom: 0, font: "italic normal normal 16px Open Sans" }}
                                        name="newUserEmail"
                                        rules={[{ required: true, message: "Please provide an email address." },
                                        { max: 50, message: 'Email must be maximum 50 characters.' }]}>
                                        <Input
                                            disabled={disableEmailField}
                                            className='form-input-field'
                                            style={{ marginBottom: "15px", width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="email" />
                                    </Form.Item>
                                    <p style={styles.disclaimer}>User need to use this email to login.</p>
                                    <p style={styles.disclaimer}>Once an account is created, a link will be emailed to this user's email address above to set a password for his/her account.</p>
                                    <Form.Item
                                        label="Phone Number"
                                        style={styles.label}
                                        name="newUserPhoneNo"
                                        rules={[{ required: true, message: "Please provide a phone number" }, { pattern: /^(01)[0-46-9][0-9]{7,8}$/, message: "Please enter a valid phone number" }]}>
                                        <Input
                                            className='form-input-field'
                                            style={{ width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="tel" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Branch"
                                        style={styles.label}
                                        name={"newUserBranchId"}
                                        rules={[{ required: true, message: "Please select a branch" }]}
                                    >
                                        <Select placeholder="Choose a branch"
                                            className='custom-select'
                                            style={{ width: "60%", font: "normal normal normal 14px Open Sans" }}>
                                            {branchList && branchList.filter(branch => branch.is_active ? branch.is_active === true : branch.is_hq === true).map((branch) => {
                                                return (
                                                    <Select.Option key={branch.id} value={branch.id}>
                                                        {branch.branchName}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>

                    <Card className='form-background' style={{ textAlign: "left", margin: "16px 0px 25px 0px" }}>
                        <Row>
                            <Col span={12}><h2 style={{ fontWeight: 'bold' }}>{loggedInUserRole === 'MYEG_OWNER_RUNNER' ? 'eTukar Runner Role Permission' : 'eTukar Role Permission'}</h2></Col>
                            {/* <Col span={12}><h2 style={{ fontWeight: 'bold' }}>{loggedInUserRole === 'MYEG_OWNER_RUNNER' ? '' : 'MySikap Role (Not Mandatory)'}</h2></Col> */}
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Radio.Group
                                    value={userRoles || ''}
                                    onChange={e => {
                                        setUserRoles(e.target.value)
                                        props.onChange({
                                            ...props.branchUser,
                                            roles: Array(e.target.value, jpjUserRoles)
                                        })
                                    }}

                                    style={{ width: "100%" }}
                                >
                                    {roles.length > 0 && roles
                                        .filter((role) => !role.toLowerCase().includes('jpj') && !role.toLowerCase().includes('owner'))
                                        .map((role) => (
                                            <Radio className='form-input-field' style={{ width: "100%", marginBottom: "16px" }} key={role} value={role}>
                                                <span style={{ font: 'normal normal 600 14px/24px Open Sans', color: '#333333' }}>{rolesMap[role].title}</span>
                                                <ul style={{ paddingLeft: 14, marginBottom: 0, marginTop: 10 }}>
                                                    {rolesMap[role].description.map((description, index) => (
                                                        <li key={`${rolesMap[role].title}${index}`}>
                                                            {description}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p style={{ fontStyle: "italic" }}>{rolesMap[role].required}</p>
                                            </Radio>
                                        ))}
                                </Radio.Group>
                            </Col>
                            <Col span={12}>
                                {/* <Checkbox.Group
                                    value={jpjUserRoles || ''}
                                    onChange={e => {

                                        setJPJUserRoles(e)

                                        props.onChange({
                                            ...props.branchUser,
                                            roles: Array(userRoles, e)
                                        })

                                    }}
                                    style={{ width: "100%" }}
                                >
                                    {roles
                                        .filter((role) => role.toLowerCase().includes("jpj"))
                                        .map((role) => (
                                            <Checkbox className='form-input-field' style={{ width: "100%", marginBottom: "16px" }} key={role} value={role}>
                                                <span style={{ font: 'normal normal 600 14px/24px Open Sans', color: '#333333' }}>{rolesMap[role].title}</span>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                            </Checkbox>
                                        ))}
                                </Checkbox.Group> */}
                            </Col>
                        </Row>
                    </Card>
                    <Row style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                    subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF
                                })
                            }}
                        >
                            <Image src={arrow_back} preview={false} />
                            BACK
                        </Button>
                        <Button
                            loading={addUserLoading}
                            className='add-new-user-button'
                            size='large'
                            style={{
                                visibility: "visible",
                                width: 200,
                                marginBottom: 15,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            type='primary'
                            onClick={() => {

                                setAddUserLoading(true)
                                otherBranchFormPersonalDetails
                                    .validateFields([
                                        "newUserName",
                                        "newUserNric",
                                        "newUserGender",
                                        "newUserDobDay",
                                        "newUserDobMonth",
                                        "newUserDobYear"])
                                    .then(() => {

                                        return otherBranchFormAccountDetails
                                            .validateFields([
                                                "newUserEmail",
                                                "newUserPhoneNo",
                                                "newUserBranchId"])

                                    })
                                    .then(() => handleAddNewUserRemote())
                                    .catch((err) => {
                                        setAddUserLoading(false)
                                        window.document.getElementById("otherBranchFormAccountDetails")?.scrollIntoView()
                                        window.document.getElementById("otherBranchFormPersonalDetails").scrollIntoView()
                                        throw err;
                                    })
                            }}
                        >Create Account
                        </Button>
                    </Row>
                </>
            )
        }
    }

    useEffect(() => {
        if (!history.location.state) return null
        if (!history.location.state.subStep) return null

        // on refresh, branchUser details will disappear, therefore, go back to INSERT_IC substep
        setSubStep(
            props.branchUser.identificationNo
                || history.location.state.subStep === STEPS.AUTHORIZE_BRANCH_USER.subSteps.OTHER_BRANCH_FORM
                || history.location.state.subStep === STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF
                ? history.location.state.subStep : STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC)
    }, [history.location.state])

    useEffect(() => {

        if (!loggedInUserRole) return

        let alteredRoles;

        getRoles()
            .then(res => {
                if (!res.status) throw res.message;

                let filteredRoles
                if (loggedInUserRole === 'MYEG_OWNER_RUNNER') {
                    filteredRoles = res.roles.filter(role => role.name.toLowerCase().includes('myeg'));
                } else {

                    filteredRoles = res.roles.filter(role => !role.name.toLowerCase().includes('myeg'));
                }


                // First, remove JPJ roles to ensure normal user roles are properly arranged
                // Then move "VALUER" to index 1
                alteredRoles = rearrangeRoles(2, 0, ...filteredRoles.filter(role => !role.name.toLowerCase().includes('jpj')));

                // Move "SALES" to index 2
                alteredRoles = rearrangeRoles(3, 1, ...alteredRoles);
                alteredRoles = rearrangeRoles(3, 2, ...alteredRoles);
                alteredRoles = rearrangeRoles(3, 5, ...alteredRoles);

                // Add back JPJ roles to the roles array
                alteredRoles.push(...filteredRoles.filter(role => role.name.toLowerCase().includes('jpj')));
                setRoles(alteredRoles.map(r => r.name));
            })
            .catch(err => err && message.error(err?.toString()));

        window.onbeforeunload = () => {
            removeCachedStates();
            history.push(history.location.pathname);
        };
    }, [loggedInUserRole]);


    useEffect(() => {

        if (props?.branchUser?.roles?.length > 0) {

            setUserRoles(props?.branchUser?.roles[0])

            if (Array.isArray(props?.branchUser?.roles[1])) {

                setJPJUserRoles(props?.branchUser?.roles[1])

            } else {

                setJPJUserRoles(props?.branchUser?.roles?.filter(r => r && r.toLowerCase().includes('jpj')))

            }

        }

    }, [props.branchUser.roles])

    useEffect(() => {
        if (branchs) {

            let activeBranches = []
            activeBranches = branchs.filter(branch => branch.is_active === true)

            if (activeBranches.length > 0) {
                activeBranches.push(HQ_BRANCH)
                activeBranches.sort(
                    (a, b) => {
                        let fa = a.branchName.toLowerCase(),
                            fb = b.branchName.toLowerCase();

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                )
                setBranchList(activeBranches)
            } else {
                setBranchList([HQ_BRANCH])
            }
        } else {
            setBranchList([HQ_BRANCH])
        }
    }, [branchs])

    const currentStep = COMPONENT_STEPS[subStep]

    return (
        <>
            <div
                className={
                    subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.SUMMARY
                        && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM
                        && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF
                        && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.OTHER_BRANCH_FORM ? 'form-background' : ""
                }
                style={{
                    height: '50%',
                    width: '100%',
                    textAlign: subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.SUMMARY
                        && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM ? "center" : "left",
                    padding: subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.SUMMARY
                        && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM
                        && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF
                        && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.OTHER_BRANCH_FORM ? "20px" : "0px",
                    marginBottom: subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.SUMMARY
                        && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM ? "10px" : "0px"
                }}
            >
                {
                    currentStep && currentStep.component
                }
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >

                    {
                        (
                            subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.SUMMARY
                            && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM
                            && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF
                            && subStep !== STEPS.AUTHORIZE_BRANCH_USER.subSteps.OTHER_BRANCH_FORM
                        ) ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <DeviceReader
                                    forKey={deviceReaderForKey}
                                    nextStep={STEPS.AUTHORIZE_BRANCH_USER.label}
                                    nextSubStep={STEPS.AUTHORIZE_BRANCH_USER.subSteps.SUMMARY}
                                    onIdentityCardSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_BRANCH_USER.label)
                                        setSubStep(STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERTING_IC)
                                    }}
                                    onWaitingThumbprint={() => {
                                        setStep(STEPS.AUTHORIZE_BRANCH_USER.label)
                                        setSubStep(STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_BEFORE)
                                    }}
                                    onThumbprintDetected={() => {
                                        setStep(STEPS.AUTHORIZE_BRANCH_USER.label)
                                        setSubStep(STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINTING)
                                    }}
                                    onThumbprintFailed={() => {
                                        setStep(STEPS.AUTHORIZE_BRANCH_USER.label)
                                        setSubStep(STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_FAILED)
                                    }}
                                    onThumbprintSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_BRANCH_USER.label)
                                        setSubStep(STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_SUCCESS)
                                    }}
                                    onTryAgain={() => {
                                        setStep(STEPS.AUTHORIZE_BRANCH_USER.label)
                                        setSubStep(STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC)
                                    }}
                                    onFinish={data => {

                                        let errorMessages = [];

                                        if (!data.name) {
                                            errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + data.name + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        if (!data.district) {
                                            errorMessages.push("Failed to read the District from MyKad. The District read as '" + data.district + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        if (!data.identificationNo || !/^[+]?\d+([.]\d+)?[*]?$/.test(data.identificationNo)) {
                                            errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + data.identificationNo + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        if (errorMessages.length > 0) {
                                            for (let errorMessage of errorMessages) {
                                                message.error(errorMessage);
                                            }
                                            history.push(history.location.pathname, {
                                                ...history.location.state,
                                                step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                                subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC
                                            })
                                            return;
                                        }
                                        data.identificationNo = data.identificationNo.replace(/[\s*]/g, "");

                                        const formattedState = data?.state?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                                        let state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                                        if (!state) state = getState(data.identificationNo)

                                        props.onChange({
                                            ...data,
                                            state: state?.code,
                                            name: data.name.replace(/\*/g, ''),
                                            district: data.district.replace(/[^a-zA-Z\s]/g, '')
                                        })

                                        otherBranchFormPersonalDetails.resetFields()
                                        otherBranchFormAccountDetails.resetFields()
                                        setUserRoles('')
                                        setJPJUserRoles('')

                                        if (deviceReaderForKey == 'branchUser') {

                                            if (user.nric == data.identificationNo) {

                                                message.error('You are not allowed to add yourself as a staff member.')

                                                setTimeout(() => {
                                                    history.push('/usermanagement')
                                                }, 1000)

                                            }

                                            setStep(STEPS.AUTHORIZE_BRANCH_USER.label)
                                            setSubStep(STEPS.AUTHORIZE_BRANCH_USER.subSteps.SUMMARY)

                                        } else if (deviceReaderForKey == 'ownerUser') {

                                            let staffInfo = JSON.parse(getCachedAddStaff())
                                            removeCachedAddStaff()

                                            if (staffInfo.isEdit == true) {

                                                updateBranchUser(
                                                    staffInfo.branchId,
                                                    staffInfo.companyId,
                                                    staffInfo.mobileno,
                                                    staffInfo.email,
                                                    staffInfo.nric,
                                                    staffInfo.id,
                                                    staffInfo.personName,
                                                    staffInfo.roles
                                                )
                                                    .then((res) => {

                                                        if (!res.status) throw (!res.details || res.details.length === 0) ? res.message : res.details.join(', ');

                                                        message.success(res.message)
                                                        history.push("/usermanagement")

                                                    })
                                                    .catch((err) => {

                                                        err && message.error(err)

                                                        history.push("/usermanagement")

                                                    })
                                                    .finally(() => { })

                                                return

                                            }

                                            if (user.nric == staffInfo.newUserNric) {

                                                message.error('You are not allowed to add yourself as a staff member.')

                                                setTimeout(() => {
                                                    history.push('/usermanagement')
                                                }, 1000)

                                            }

                                            if (user.nric != data.identificationNo) {

                                                message.error('The authorised user nric does not correspond to the logged-in user nric. ')

                                                history.push(history.location.pathname, {
                                                    step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                                    subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF
                                                })

                                                return

                                            } else {

                                                getUserRole()
                                                    .then(loggedInUserRole => {

                                                        if (loggedInUserRole != 'OWNER') {

                                                            message.error('A level 5 user can only be added by the user with the role of "Owner." ')

                                                            history.push(history.location.pathname, {
                                                                step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                                                subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF
                                                            })

                                                            return
                                                        }


                                                        if (user.nric == data.identificationNo && loggedInUserRole == 'OWNER') {

                                                            isAccountExist(staffInfo.newUserNric)
                                                                .then((res) => {

                                                                    if (res.status === false) {

                                                                        return create1MIDAccount(//create 1mid account with dummy
                                                                            'dummyAddres1',
                                                                            '',
                                                                            '',
                                                                            staffInfo.localFormattedDob,
                                                                            '',
                                                                            '',
                                                                            'dummyCity',
                                                                            staffInfo.newUserEmail,
                                                                            staffInfo.newUserEmail,
                                                                            staffInfo.newUserGender,
                                                                            staffInfo.newUserPhoneNo,
                                                                            staffInfo.newUserName,
                                                                            staffInfo.newUserNric,
                                                                            staffInfo.otherBranchAddUser,
                                                                            staffInfo.newUserPhoneNo,
                                                                            '99999',
                                                                            '',
                                                                            '',
                                                                            '12')

                                                                    } else if (res.status === true) {

                                                                        return {
                                                                            status: true
                                                                        }

                                                                    } else {
                                                                        throw res.message
                                                                    }

                                                                })
                                                                .then(res => {

                                                                    if (!res.status) {

                                                                        let msg = res.message

                                                                        if ('Sorry, an account already exists with email address - ' === res.message) {
                                                                            msg += staffInfo.newUserEmail
                                                                        }

                                                                        throw msg
                                                                    }

                                                                    return getAccountDetails(staffInfo.newUserNric)//get 1mid account details

                                                                })
                                                                .then(res => {

                                                                    const { nric, name } = res

                                                                    return addBranchUser(//create new etukar user
                                                                        staffInfo.newUserBranchId,
                                                                        staffInfo.companyId,
                                                                        staffInfo.newUserPhoneNo,
                                                                        staffInfo.newUserEmail,
                                                                        nric.replace(/[\s*]/g, ""),
                                                                        name,
                                                                        staffInfo.roles)

                                                                })
                                                                .then(res => {
                                                                    if (!res.status) throw (!res.details || res.details.length === 0) ? res.message : res.details.join(', ');
                                                                    message.success(res.message);
                                                                    history.push("/usermanagement");
                                                                })
                                                                .catch((err) => {

                                                                    err && message.error(err)

                                                                    history.push(history.location.pathname, {
                                                                        step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                                                        subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF
                                                                    })

                                                                })
                                                                .finally(() => {

                                                                })

                                                        }

                                                    })
                                                    .catch(err => {

                                                        err && message.err(err)

                                                        history.push(history.location.pathname, {
                                                            step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                                            subStep: STEPS.AUTHORIZE_BRANCH_USER.subSteps.ADD_STAFF
                                                        })
                                                    })

                                            }
                                        }
                                    }}
                                    onDataChanged={data => { }}
                                    onError={data => {
                                        setStep(STEPS.AUTHORIZE_BRANCH_USER.label)
                                        setSubStep(STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC)
                                    }}
                                />
                            </div>
                        ) : null
                    }
                </div>
            </div>
            {subStep === STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERTING_IC ||
                subStep === STEPS.AUTHORIZE_BRANCH_USER.subSteps.INSERT_IC ||
                subStep === STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINTING ||
                subStep === STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_BEFORE ||
                subStep === STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_FAILED ||
                subStep === STEPS.AUTHORIZE_BRANCH_USER.subSteps.THUMBPRINT_SUCCESS
                ? (<div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '2%'
                    }}
                >
                    <Button
                        className='back-button buttons'
                        shape='round'
                        onClick={() => {
                            history.push('/usermanagement')
                        }}
                    >
                        <Image src={arrow_back} preview={false} />
                        BACK
                    </Button>
                </div>) : null}
            <Modal visible={showChangeEmailModal} footer={null} onCancel={() => { setShowChangeEmailModal(false) }} destroyOnClose afterClose={() => changeEmailForm.resetFields()}>
                <h2 style={{ fontWeight: "bold", textAlign: "center", marginTop: 30 }}>Change Username/E-mail</h2>
                <Form
                    layout="vertical"
                    style={{ padding: "0px 20px 0px 20px" }}
                    width={200}
                    requiredMark={"optional"}
                    form={changeEmailForm}
                    onFinish={onChangeEmail}
                    initialValues={{
                        currentEmail: props.branchUser.username
                    }}
                >
                    <Form.Item
                        label="Current Username/E-mail"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="currentEmail"
                        rules={[
                            {
                                required: true,
                                message: "Please input your current e-mail",
                            },
                        ]}
                    >
                        <Input disabled={true} type={"email"} className="form-input-field" />
                    </Form.Item>
                    <Form.Item
                        label="New Username/E-mail"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="newEmail"
                        rules={[
                            { required: true, message: "Please input your new e-mail" },
                            { max: 50, message: 'Username/E-mail must be maximum 50 characters.' }]}
                    >
                        <Input type={"email"} className="form-input-field" />
                    </Form.Item>
                    <Form.Item
                        label="Confirm Username/New E-mail"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="confirmNewEmail"
                        rules={[
                            { required: true, message: "Please confirm your new e-mail" },
                            { max: 50, message: 'Username/E-mail must be maximum 50 characters.' }
                        ]}
                    >
                        <Input type={"email"} className="form-input-field" />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="password"
                        rules={[{ required: true, message: "Please input your password" }]}
                    >
                        <Input.Password className="form-input-field" visibilityToggle />
                    </Form.Item>
                    <Form.Item>
                        <Button className="add-new-user-button" type="primary" style={{ width: "100%" }} htmlType="submit" loading={changeEmailLoading}>
                            Change E-mail
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                width={450}
                visible={showChangeNoModal}
                footer={null}
                onCancel={() => {
                    setShowChangeNoModal(false)
                }}
                destroyOnClose
                afterClose={() => resetChangeNoForm()}>
                <h2 style={{ fontWeight: "bold", textAlign: "center", marginTop: 30 }}>Change Phone Number</h2>
                <Form
                    layout="vertical"
                    style={{ padding: "0px 20px 0px 20px" }}
                    width={200}
                    requiredMark={"optional"}
                    form={changeNoForm}
                    onFinish={onChangeNo}
                >
                    <Form.Item
                        label="New phone number"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="newPhoneNo"
                        rules={[
                            {
                                required: true,
                                pattern: /^(01)[0-46-9][0-9]{7,8}$/,
                                message: "Please enter a valid phone number",
                            },
                        ]}
                    >
                        <Input type={"tel"} className="form-input-field" />
                    </Form.Item>

                    <Form.Item
                        label="Enter the code received from your current phone number"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="authCode"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your OTP",
                            },
                        ]}
                    >
                        <Row gutter={[8, 8]}>
                            <Col span={16}>
                                <Input.Password className="form-input-field" placeholder={'000 000'} visibilityToggle />
                            </Col>
                            <Col span={8}>
                                {
                                    minutes === '00' && seconds === '00' &&
                                    <Button
                                        id={'show-resend-otp'}
                                        style={{
                                            width: '100%',
                                            background: 'white',
                                            border: 'none',
                                            color: '#2B61C4',
                                            textTransform: 'uppercase'
                                        }}
                                        type="secondary" onClick={() => {
                                            // setSmsProvider((smsProvider < 2 ? smsProvider + 1 : 1))
                                            setSmsProvider(1)
                                            setOtpTimeout(3)
                                            sendOtp()
                                        }}>
                                        SEND CODE
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    {
                        minutes === '00' && seconds === '00' ? <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}></p> :
                            <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}>Code will expire in {minutes} min {seconds} sec</p>
                    }
                    <Form.Item>
                        <Button className="add-new-user-button" type="primary" style={{ width: "100%" }} htmlType="submit" loading={changeNoLoading}>
                            Change phone number
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <style>
                {`
					.ant-modal-content { 
						border-radius: 8px;
					}
				`}
            </style>
        </>
    )
}

AuthorizeBranchUser.propTypes = {
    branchUser: propTypes.object,
    onChange: propTypes.func,
}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
    },
    value: {
        color: '#333333',
        font: 'normal normal 600 16px Open Sans'
    },

    disclaimer: {
        color: 'grey', fontStyle: 'italic', marginBottom: '15px', width: "60%"
    }
}

export default AuthorizeBranchUser