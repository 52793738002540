import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Image, message, Row, Skeleton, Space } from 'antd'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { PropsFromChildren } from '.';
import { SessionContext } from '../../App';
import failIcon from "../../assets/icon-fail.svg";
import successIcon from "../../assets/icon-success.svg";
import { JPJ_INQUIRY_STATUS,
    ERROR_MESSAGE_M2M,
    HQ_BRANCH,
    APP_CONST,
} from '../../constants';
import { getInTransferByTransferId,
    m2mProceed,
    getAdminUsersForOut,
    verifyApproverSmsOtp,
    verifyApproverOTP,
} from '../../services/api';
import { GetInTransferDetailRequest, GetInTransferDetailResponse } from '../../services/api/types';
import { parseAddress, parseTime } from '../../util';
import { Buyer, CompanyResponse, M2MProceedResponse, SaveBuyerRequest, Vehicle, VehicleResponse, SaveProceedRequest } from './types';
import { getCompanyId,
    getAuthorizedNric,
    getUserRole,
} from '../../services/local'
import ApproverAuthModal from './jpjSubmission_modal';
import { getDecryptedVerifyOtpResponse } from '../../util/index'
import {
    validateRolePermission,
} from '../../components/Utils'

type JPJVerificationProps = {
    company: CompanyResponse
    vehicle: VehicleResponse
    transferId: number,
    isJpjSubmitted: boolean,
    isReport: boolean,
    isFromAdminPage: boolean,
    sellerInquirySuccess: boolean,
    next: (data?: PropsFromChildren) => void
}

export default function JPJVerification(props: JPJVerificationProps) {
    const { user, branch, company } = useContext(SessionContext)
    const history = useHistory()
    const [isJPJLoading, setIsJPJLoading] = useState(true)
    const [verificationSuccess, setVerificationSuccess] = useState(false)
    const [isTransferDetailsLoading, setIsTransferDetailsLoading] = useState(false)
    const [transferDetails, setTransferDetails] = useState<GetInTransferDetailResponse | null>(null)
    const [m2mJPJError, setM2mJPJError] = useState<M2MProceedResponse>()
    const [transferId, setTransferId] = useState(0)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isIncorrectOtp, setIsIncorrectOtp] = useState(false)
    const [isApprovalRejected, setIsApprovalRejected] = useState(false)
    const [loggedInUserRole, setLoggedInUserRole] = useState('')

    useEffect(() => {
        getUserRole()
            .then(res => {
                setLoggedInUserRole(res)
            })
    }, [])

    useEffect(() => {
        if(props.isJpjSubmitted) {
            getAndSetTransfer(props.transferId)
            setTransferId(props.transferId)
            if (props.sellerInquirySuccess) setVerificationSuccess(true)
            setIsJPJLoading(false)
        }
        else {
            jpjSubmit()
            setIsJPJLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const jpjSubmit = useCallback(async () => {

        const saveBuyerPayload = {
            transferId: props.transferId,
            uId: "",
            moduleType: ""
        }

        const m2mProceedRes = await m2mProceed(saveBuyerPayload)
        if (m2mProceedRes.inquiryStatus === '00') {
            message.success("APPROVED")
            await getAndSetTransfer(saveBuyerPayload.transferId)
            setVerificationSuccess(true)
            setTransferId(saveBuyerPayload.transferId)
            // props.next({ transferId: saveBuyerPayload.transferId })
        } else {
            setM2mJPJError(m2mProceedRes)
            await getAndSetTransfer(saveBuyerPayload.transferId)
            setVerificationSuccess(false)
            setTransferId(saveBuyerPayload.transferId)
        }
    }, [])

    const jpjApproval = useCallback(async () => {

        const saveBuyerPayload = {
            transferId: props.transferId,
            uId: "",
            moduleType: "APPROVAL"
        }

        const m2mProceedRes = await m2mProceed(saveBuyerPayload)
        if (m2mProceedRes.inquiryStatus === '00') {
            message.success("SUCCESS")
            props.next({ transferId: saveBuyerPayload.transferId })
        } else {
            setM2mJPJError(m2mProceedRes)
            await getAndSetTransfer(saveBuyerPayload.transferId)
            setVerificationSuccess(false)
            setTransferId(saveBuyerPayload.transferId)
        }
        setIsJPJLoading(false)
    }, [])

    async function getAndSetTransfer(transferId: number) {
        setIsTransferDetailsLoading(true)
        const getInTransferDetailRequest: GetInTransferDetailRequest = {
            transferId, "uId" : "", companyId : 0, moduleType : "M2M"
        }
        await getInTransferByTransferId(getInTransferDetailRequest)
            .then(res => {
                if (res.id === undefined) throw res.message

                setTransferDetails(res)
                // if (res.status === 2) {
                //     setVerificationSuccess(false)
                // }

            })
            .catch(err => err && message.error(err))
            .finally(() => setIsTransferDetailsLoading(false))
    }

    const LoadingCard = () => {
        return (
            <Card>
                <LoadingOutlined />
            </Card>
        )
    }

    const Verification = () => {
        return (
            <>
            {!isTransferDetailsLoading && transferDetails ?
                <Card className='section-card section-card-left'>
                    <div className="header-icon-container">
                    {
                        !verificationSuccess ? 
                        <>
                            <Image src={failIcon} alt="Fail icon" preview={false} style={{ paddingRight: '20px' }} />
                            <div>
                                <div className="header-danger">JPJ verification failed</div>
                                {m2mJPJError?.inquiryStatus === "99" ? <div>{ERROR_MESSAGE_M2M(transferId)}</div> :
                                <div>{m2mJPJError?.inquiryMSG}</div>}
                                
                            </div>
                        </>
                        : 
                        <>
                        {isApprovalRejected ? 
                            <div 
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}>
                                    <Image src={failIcon} alt="failed icon" preview={false} style={{ transform: 'scale(0.6)' }} />
                                    <Space direction='vertical' size={0}>
                                        <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >Approval Unsuccessful</h2>
                                    </Space>
                            </div>
                            :
                            <>
                                <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                                <div>
                                    <div style={{ color: '#40e327a8', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ verification success</div>
                                </div>
                            </>
                        }
                        </>
                    }
                    </div>
                </Card> : <></>}
                {(isTransferDetailsLoading === false && !transferDetails) ? <BottomButtons /> : null}

                {isTransferDetailsLoading
                    ? <Skeleton active />
                    : (transferDetails &&
                        <>
                            <Card className='section-card section-card-left'>
                                <h2 className='section-header'>Vehicle Information</h2>
                                <Row>
                                    <Col span={8}>
                                        <div className='detail-wrapper'>
                                            <span>Vehicle Number</span>
                                            <p>{transferDetails.carRegistrationNo}</p>
                                        </div>
                                        {/* <div className='detail-wrapper'>
                                            <span>Manufacture Year</span>
                                            <p>{transferDetails.manufactureYear}</p>
                                        </div> */}
                                    </Col>
                                    <Col span={8}>
                                        {/* <div className='detail-wrapper'>
                                            <span>Make</span>
                                            <p>{transferDetails.make}</p>
                                        </div> */}
                                        <div className='detail-wrapper'>
                                            <span>Engine Number</span>
                                            <p>{transferDetails.engineNo}</p>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        {/* <div className='detail-wrapper'>
                                            <span>Model</span>
                                            <p>{transferDetails.model}</p>
                                        </div> */}
                                        <div className='detail-wrapper'>
                                            <span>Chassis Number</span>
                                            <p>{transferDetails.chassisNo}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>

                            <Card className='section-card section-card-left'>
                                <h2 className='section-header'>JPJ Enquiry</h2>
                                <Row>
                                    <Col span={8}>
                                        <div className='detail-wrapper'>
                                            <span>Blacklisted</span>
                                            <p>
                                                {m2mJPJError?.inquiryStatus === JPJ_INQUIRY_STATUS.BLACKLISTED
                                                    ? m2mJPJError.inquiryMSG
                                                    : 'No'
                                                }
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className='detail-wrapper'>
                                            <span>Ownership Claim</span>
                                            <p>
                                                {m2mJPJError?.inquiryStatus === JPJ_INQUIRY_STATUS.OWNERSHIP_CLAIM
                                                    ? m2mJPJError.inquiryMSG
                                                    : 'No'
                                                }
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className='detail-wrapper'>
                                            <span>Condition Code</span>
                                            <p>
                                                {m2mJPJError?.inquiryStatus === JPJ_INQUIRY_STATUS.CONDITION
                                                    ? m2mJPJError.inquiryMSG
                                                    : '-'
                                                }
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <div className='detail-wrapper'>
                                        <span>Enquiry History</span>
                                        <p>
                                            {m2mJPJError?.inquiryStatus === JPJ_INQUIRY_STATUS.USAGE
                                                ? m2mJPJError.inquiryMSG
                                                : 'None'
                                            }
                                        </p>
                                    </div>
                                </Row> */}
                                <div className='italic'>
                                    Valid as at {parseTime(transferDetails.createdDate)}
                                </div>
                            </Card>

                            <Row gutter={[20, 0]} style={{ marginTop: '-20px' }}>
                                <Col span={12}>
                                    <Card className='section-card section-card-left'>
                                        <h2 className='section-header'>Dealer's Detail</h2>
                                        <div className='detail-wrapper'>
                                            <span>Company Name</span>
                                            <p>{transferDetails.sellerInfo.name}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Company ROC Number</span>
                                            <p>{transferDetails.sellerInfo.identificationNo}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Company Address</span>
                                            <p>{parseAddress(transferDetails.sellerInfo)}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Company E-mail</span>
                                            <p>{transferDetails.sellerInfo.email}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Company Phone Number</span>
                                            <p>{transferDetails.sellerInfo.phoneNo}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Dealer Representative</span>
                                            <p>{transferDetails.sellerInfo.repName ?? '-'}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Dealer Representative's NRIC</span>
                                            <p>{transferDetails.sellerInfo.repIc ?? '-'}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Dealer Representative's Mobile Number</span>
                                            <p>-</p>
                                            {/* <p>{transferDetails.sellerInfo.repPhoneNo ?? '-'}</p> */}
                                        </div>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card className='section-card section-card-left'>
                                        <h2 className='section-header'>Buyer's Detail</h2>
                                        <div className='detail-wrapper'>
                                            <span>Company Name</span>
                                            <p>{transferDetails.buyerInfo.name}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Company ROC Number</span>
                                            <p>{transferDetails.buyerInfo.identificationNo}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Company Address</span>
                                            <p>{parseAddress(transferDetails.buyerInfo)}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Company E-mail</span>
                                            <p>{transferDetails.buyerInfo.email}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Company Phone Number</span>
                                            <p>{transferDetails.buyerInfo.phoneNo}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Dealer Representative</span>
                                            <p>{transferDetails.buyerInfo.repName ?? '-'}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Dealer Representative's NRIC</span>
                                            <p>{transferDetails.buyerInfo.repIc ?? '-'}</p>
                                        </div>
                                        <div className='detail-wrapper'>
                                            <span>Dealer Representative's Mobile Number</span>
                                            <p>-</p>
                                            {/* <p>{transferDetails.buyerInfo.repPhoneNo}</p> */}
                                        </div>
                                    </Card>
                                </Col>
                            </Row>

                            <Card className='section-card section-card-left'>
                                <div className="header-icon-container">
                                {
                                    !verificationSuccess ? 
                                    <>
                                        <Image src={failIcon} alt="Fail icon" preview={false} style={{ paddingRight: '20px' }} />
                                        <div>
                                            <div className="header-danger">JPJ verification failed</div>
                                            {m2mJPJError?.inquiryStatus === "99" ? <div>{ERROR_MESSAGE_M2M(transferId)}</div> :
                                            <div>{m2mJPJError?.inquiryMSG}</div>}
                                            
                                        </div>
                                    </>
                                    : 
                                    <>
                                    {isApprovalRejected ? 
                                        <div 
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}>
                                                <Image src={failIcon} alt="failed icon" preview={false} style={{ transform: 'scale(0.6)' }} />
                                                <Space direction='vertical' size={0}>
                                                    <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >Approval Unsuccessful</h2>
                                                </Space>
                                        </div>
                                        :
                                        <>
                                            <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                                            <div>
                                                <div style={{ color: '#40e327a8', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ verification success</div>
                                            </div>
                                        </>
                                    }
                                    </>
                                }
                                </div>
                            </Card>
                            <BottomButtons />
                        </>
                    )
                }
            </>
        )
    }

    const onApprovalSubmit = async () => {
        let isAdminUser = false
        await getAdminUsersForOut(company.id, HQ_BRANCH.id)
            .then(res => {
                if (res.status !== true) throw res.message
                res.users.map(user => {
                    if (user.nric_no === getAuthorizedNric()) {
                        isAdminUser = true
                        // message.success("APPROVED")
                    }
                })
            })
            .catch(err => err && message.error(err.toString()))
            .finally(async ()=> {
                if (!isAdminUser) {
                    setIsModalVisible(true)
                }
                else {
                    jpjApproval()
                }
            })
    }

    const BottomButtons = () => {
        return (
            <div className='section-bottom-button'>
                {
                    props.isFromAdminPage ? <Button className='rounded-button-primary' onClick={() => history.push('/adminSearchVehicleInfo')}>Back</Button> :
                    props.isReport ?  <Button className='rounded-button-primary' onClick={() => history.push('/reporting/transferreport/')}>Back</Button> :
                    <Button className='rounded-button-primary' onClick={() => history.push('/')}>Home</Button>
                }
                {!props.isFromAdminPage && !verificationSuccess ? <Button type='primary' className='rectangle-button to-the-right' onClick={() => jpjSubmit()}>Resubmit</Button>
                :<></>}
                { verificationSuccess ? 
                !validateRolePermission(loggedInUserRole, "M2M", true) ? <Button type='primary' className='rectangle-button to-the-right' onClick={() => onApprovalSubmit()}>Seller Approval</Button>
                :
                <Button
                    className='rectangle-button to-the-right'
                    onClick={() => {
                        // setIsModalVisible(true)
                        history.push('/transfer/out/create')
                    }}
                    type='primary'
                >
                    OK
                </Button> : <></>}
            </div>
        )
    }

    const approvalAuthModal = () => {
        return <ApproverAuthModal
                isIncorrectOtp={isIncorrectOtp}
                isModalVisible={isModalVisible}
                isApprover={true}
                isM2M={true}
                transferId={transferId}
                onCancel={() => setIsModalVisible(false)}
                onOk={(approverIc, authCode, isPush, appApprovalResponse) => {

                    if (isPush) {
                        setIsModalVisible(false)
                        if (appApprovalResponse.isRejected) {
                            setIsApprovalRejected(true)
                        }
                        else {
                            setIsApprovalRejected(false)
                            // props.next({ vehicleProps: vehicle, transferId: m2MBuyerResponse?.transferId})
                            jpjApproval()
                        }
                    }
                    else if (APP_CONST.USE_OTP === true) {

                        verifyApproverSmsOtp(approverIc, authCode)
                            .then(res => {
                                if(!res.encryptedString) {
                                    let errorMsg = res.message ? res.message : "ERROR"
                                    throw errorMsg
                                }
                                const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)
                                if (decryptedResponse.status == 'false') {
                                    setIsIncorrectOtp(true)
                                    throw decryptedResponse.message
                                }
                                setIsIncorrectOtp(false)
                                setIsModalVisible(false)
                                jpjApproval()
                                // Next step: JPJ Verification
                                // props.next({ vehicleProps: vehicle, transferId: m2MBuyerResponse?.transferId})
                            })
                            .catch((err: any) => {
                                setIsIncorrectOtp(true)
                                err && message.error(err)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })
                    } else {

                        verifyApproverOTP(approverIc, authCode)
                            .then(res => {
                                if (res.status !== true) {
                                    setIsIncorrectOtp(true)
                                    throw res.message
                                }
                                setIsIncorrectOtp(false)
                                setIsModalVisible(false)
                                jpjApproval()
                                // Next step: JPJ Verification
                                // props.next({ vehicleProps: vehicle, transferId: m2MBuyerResponse?.transferId })
                            })
                            .catch((err: any) => {
                                setIsIncorrectOtp(true)
                                err && message.error(err)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })

                    }

                }}
            />
    }

    return (
        <>
        {approvalAuthModal()}
        {isJPJLoading
            ? <LoadingCard />
            : (
                // verificationSuccess ? null : <Verification />
                <Verification />
            )}
        </>
    )
}
